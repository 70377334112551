import { LayerKey } from "map-controller";
import {
    WarehouseEntityLayerBinding,
    IWarehouseEntitiesService,
    WarehouseEntityLayerBindingDeps,
} from "./warehouse-entity-layer-binding";
import { IAppWindowManager } from "../../store/app-windows";

export type SpeedCamerasLayerBindingDeps = Omit<WarehouseEntityLayerBindingDeps, "service"> & {
    speedCameras: IWarehouseEntitiesService;
    windowManager: IAppWindowManager;
};

export class SpeedCamerasLayerBinding extends WarehouseEntityLayerBinding {
    private windowManager: IAppWindowManager;

    constructor(deps: SpeedCamerasLayerBindingDeps) {
        super(LayerKey.SpeedCameras, {
            service: deps.speedCameras,
            mapController: deps.mapController,
        });
        this.windowManager = deps.windowManager;
    }

    protected handleClick = (id: number) => {
        this.mapController.setActive(id, this.layerKey);
        this.windowManager.spawnSpeedCameraCard(id);
    };
}
