import { makePath2DDrawer } from "./drawing.common";
import { RectLikeProps } from "./drawing.types";
import { drawRoundedRect } from "./common";
import { drawGridColumns } from "./common";

interface CommonProps extends RectLikeProps {
    pxPerSecond: number;
    color: string;
    grid?: {
        lineWidth?: number;
        lineColor?: string;
    };
    border?: {
        width: number;
        color: string;
    } | null;
}

export interface DrawCyclePhaseProps extends CommonProps {
    phaseNumber: number;
    seconds: number;
    showText?: boolean;
}

const applyFontSettings = (ctx: CanvasRenderingContext2D) => {
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.font = `500 12px Inter`;
    ctx.strokeStyle = "#03284C4F";
    ctx.fillStyle = "#fff";
    ctx.lineWidth = 2;
};

const createCyclePhasePath = (ctx: CanvasRenderingContext2D, props: CommonProps) => {
    const { x, y, width, height, border, pxPerSecond, color, grid } = props;
    const borderRadius = border ? 2 : 0;
    const path = new Path2D();
    if (border) {
        const offset = border.width;
        const borderPath = drawRoundedRect(ctx, {
            x: x - offset,
            y: y - offset,
            width: width + offset * 2,
            height: height + offset * 2,
            border,
            radius: borderRadius,
        });
        path.addPath(borderPath);
    }
    const mainPath = drawRoundedRect(ctx, {
        x,
        y,
        width,
        height,
        fillColor: color,
        radius: borderRadius,
    });
    path.addPath(mainPath);
    drawGridColumns(ctx, {
        x: x + pxPerSecond,
        y,
        width: width - pxPerSecond,
        height,
        step: pxPerSecond,
        lineColor: grid?.lineColor,
        lineWidth: grid?.lineWidth,
    });
    return mainPath;
};

const getFormattedText = (ctx: CanvasRenderingContext2D, width: number, phaseNumber: number, seconds: number) => {
    const text = seconds ? `Ф${phaseNumber} \u{2022} ${seconds}с` : `Ф${phaseNumber}`;
    const measure = ctx.measureText(text);
    const scale = width / measure.width;
    if (scale >= 1) return text;
    if (scale >= 0.5) return `Ф${phaseNumber}`;
    return "";
};

export const drawCyclePhase = makePath2DDrawer<DrawCyclePhaseProps>((ctx, props) => {
    const { x, y, phaseNumber, seconds, width, height, showText = true } = props;
    const path = createCyclePhasePath(ctx, props);
    if (!showText) return path;
    applyFontSettings(ctx);
    const text = getFormattedText(ctx, width, phaseNumber, seconds);
    ctx.strokeText(text, x + width * 0.5, y + height * 0.5, width);
    ctx.fillText(text, x + width * 0.5, y + height * 0.5, width);
    return path;
});
