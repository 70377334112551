import { memo, useEffect, useRef } from "react";
import { useAppState } from "app/store";
import { GlobalBroker } from "./global-broker";

/** Регистрирует глобальный брокер */
export const RegisterGlobalBroker = memo(() => {
    const store = useAppState();
    const brokerRef = useRef(new GlobalBroker(store.mapController, store));

    useEffect(() => {
        const broker = brokerRef.current;
        return () => broker.destroy();
    }, []);

    return null;
});
