import { ChangeEvent, useEffect, useState } from "react";
import { Props } from "./cycle-speed-input.types";
import { validateInputValue } from "./cycle-speed-input.utils";

export const useCycleSpeedInput = (props: Props) => {
    const { value, onChange, kind } = props;
    const [currentValue, setCurrentValue] = useState<string>(String(value));

    useEffect(() => {
        setCurrentValue(String(value));
    }, [value]);

    const handleOnChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (!Number.isNaN(Number(event.target.value))) {
            setCurrentValue(event.target.value);
        }
    };

    const handleOnBlur = (e: ChangeEvent<HTMLInputElement>) => {
        const nextValue = e.target.value;
        if (validateInputValue(nextValue)) {
            onChange?.(kind, +nextValue);
        } else {
            setCurrentValue(String(value ?? ""));
        }
    };

    return { handleOnChange, handleOnBlur, currentValue };
};
