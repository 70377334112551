import { MapController } from "map-controller";

export type MapBindingDeps = {
    mapController: MapController;
};

export abstract class LayerBinding {
    protected reactions: VoidFunction[] = [];
    protected mapController: MapController;

    constructor(deps: MapBindingDeps) {
        this.mapController = deps.mapController;
    }

    public abstract bind(): void;

    public destroy() {
        this.clearReactions();
    }

    protected addReaction(effect: VoidFunction) {
        this.reactions.push(effect);
    }

    protected clearReactions() {
        for (const reaction of this.reactions) {
            reaction();
        }
    }
}
