import { ScenariosAPI } from "api/endpoints/scenarios";
import { AddScenario, BackActionType, ScenariosData } from "api/endpoints/types";
import { ErrorsType, ActionType, Scenario, EntityType, StateType, LaunchData } from "app-domain/scenario";
import { getPropAction } from "./scenario.utils";
import { ManageAPI } from "api/endpoints/manage";

export class ScenarioService {
    private api = new ScenariosAPI();
    private manageApi = new ManageAPI();
    /** Возвращает словарь сценариев */
    public async getScenariosMap(): Promise<Map<number, Scenario>> {
        const map = new Map();

        const response = await this.api.getScenarios();

        if (Array.isArray(response)) {
            response.forEach((item) => {
                const scenario = { ...item, actions: item.actions.map(getPropAction) };
                map.set(item.id, scenario);
            });
        }

        return map;
    }

    public async getScenario(id: number): Promise<Scenario> {
        const response = await this.api.getScenario(id);
        return { ...response, actions: response.actions.map(getPropAction) };
    }

    /** Добавить/редактировать сценарий */
    public async editScenario(scenario: AddScenario): Promise<ScenariosData | string[]> {
        const getPropAction = (action: ActionType): BackActionType => {
            let propAction: BackActionType = {
                $type: action.type,
                targetId: action.id,
            };

            if (action.type === EntityType.TrafficLight) {
                if (typeof action.cycle === "number") propAction.desiredCycleId = action.cycle;
                if (typeof action.phase === "number") propAction.desiredPhase = action.phase;
                if (typeof action.status === "number") propAction.desiredStatus = action.status;
            } else if (action.type === EntityType.CooGroup) {
                if (typeof action.cycle === "number") propAction.desiredCycleId = action.cycle;
            }
            return propAction;
        };

        const data = {
            ...scenario,
            name: scenario.name.trim(),
            description: scenario.description.trim(),
            actions: scenario.actions.map(getPropAction),
        };

        if (!data.id) delete data.id;

        return !!data.id ? await this.api.putScenarios(data) : await this.api.postScenarios(data);
    }

    /** Удалить сценарий */
    public async deleteScenario(id: number): Promise<void> {
        await this.api.deleteScenarios(id);
    }

    /** Завершает выполнение сценария */
    public async finishScenario(id: number): Promise<void> {
        await this.manageApi.finishScenario(id);
    }

    /** Запустить сценарий */
    public async launchScenario(id: number, data: LaunchData): Promise<StateType> {
        return await this.manageApi.launchScenarios(id, data);
    }

    /** Включить/выключить сценарий */
    public async enabledScenario(id: number, enabled: boolean): Promise<void> {
        return await this.manageApi.enabledScenario(id, enabled);
    }

    /** Увеличивает время работы сценария */
    public async extendScenario(id: number, time: number): Promise<void> {
        return await this.manageApi.prolongScenario(id, time);
    }

    public async getErrorsMap(): Promise<Map<number, ErrorsType>> {
        return new Map();
    }

    public async getEventsMap(): Promise<Map<number, ActionType>> {
        return new Map();
    }
}
