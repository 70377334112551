import { memo } from "react";
import { Component as BlockHeader } from "@megapolis/uikit/block-header/component";
import { Component as PopupOverlay } from "@megapolis/uikit/popup/component";
import { Typography } from "@megapolis/uikit/typography";
import { Button } from "@megapolis/uikit/button";

import * as Markup from "./confirmation.styles";

type Props = {
    text: string;
    applyText?: string;
    cancelText?: string;
    onApply?: VoidFunction;
    onCancel?: VoidFunction;
    /**
     *  Флаг, указывающий на отображение поверх всего контента
     *  @default - true
     */
    isModal?: boolean;
};

export const Confirmation = memo((props: Props) => {
    const cancelText = props.cancelText ?? "Отменить";
    const applyText = props.applyText ?? "Подтвердить";
    const { isModal = true } = props;
    const parentNode = isModal ? void 0 : null;

    return (
        <PopupOverlay
            parentNode={parentNode}
            body={
                <Markup.Modal
                    parentNode={parentNode}
                    body={
                        <Markup.Container>
                            <BlockHeader title={<Typography.H5>{props.text}</Typography.H5>} />
                            <Markup.Controls>
                                {props.onCancel && (
                                    <Button
                                        content={Button.Content.Text}
                                        priority="tertiary"
                                        text={cancelText}
                                        onClick={props.onCancel}
                                    />
                                )}
                                {props.onApply && (
                                    <Button content={Button.Content.Text} text={applyText} onClick={props.onApply} />
                                )}
                            </Markup.Controls>
                        </Markup.Container>
                    }
                />
            }
        />
    );
});
