import { Detector } from "app-domain/detector";
import { makeObservable, override, runInAction } from "mobx";
import { WarehouseEntities } from "../common/warehouse-entities";
import { DetectorsFilter } from "./detectors-filter";
import { DetectorsService, LoadDetectorOptions } from "./detectors.types";

export class Detectors extends WarehouseEntities<Detector, DetectorsService> {
    public filter!: DetectorsFilter;

    constructor(service: DetectorsService) {
        super({ service });
        this.setFilter(new DetectorsFilter());
        makeObservable(this, {
            loadItem: override,
        });
    }

    public async loadItem(id: number, options?: LoadDetectorOptions) {
        const detector = await this.service.fetchItem(id, options);
        runInAction(() => {
            this.items.set(id, detector);
        });
        return detector;
    }
}
