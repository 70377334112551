import { makeAutoObservable } from "mobx";
import { TrafficLightDomain } from "app-domain";
import { WindowInstance } from "shared/window-manager";
import {
    ITrafficLightRemoteControlService,
    HoldPhaseInput,
    HoldCycleInput,
    HoldStatusInput,
} from "./traffic-light-remote-control.types";

/** Пульт управления светофорным объектом */
export class TrafficLightRemoteControl {
    /** Значение длительности в секундах по умолчанию */
    public static defaultDurationSeconds = 300;
    /** Минимальная продолжительность команды в секундах (1 мин) */
    public static minDurationTime = 60;

    constructor(
        public id: number,
        private _governance: TrafficLightDomain.Governance,
        private currentUserId: string,
        private service: ITrafficLightRemoteControlService,
        private window: WindowInstance,
        private _onRunCommand: (control: TrafficLightRemoteControl, isPlanned: boolean) => void
    ) {
        makeAutoObservable(this);
    }

    /** Свернут ли пульт */
    public get isCollapsed() {
        return this.window.isCollapsed;
    }

    public get isControlledByCurrentUser() {
        return this.user.id === this.currentUserId;
    }

    public get governanceId() {
        return this.governance.id;
    }

    public get isPlanned() {
        return this._governance.isPlanned;
    }

    public get holdExpireDate() {
        return this._governance.data?.holdExpireDate ?? null;
    }

    public get trafficLightId() {
        return this._governance.facilityId;
    }

    public get createdAt() {
        return this.governance.data?.start;
    }

    public get isUnderUserControl() {
        return this.governance.isUnderUserControl;
    }

    /** Данные пользователя, управляющего пультом */
    public get user() {
        return {
            id: this.governance?.data?.profileId ?? "",
            name: this.governance?.data?.username ?? "",
        };
    }

    public get runningCommand() {
        return this.governance.command;
    }

    public get activePhaseNumber() {
        if (!(this.runningCommand instanceof TrafficLightDomain.HoldPhaseCommand)) return null;
        return this.runningCommand.phase;
    }

    public get activeStatus() {
        if (!(this.runningCommand instanceof TrafficLightDomain.HoldStatusCommand)) return null;
        return this.runningCommand.status;
    }

    public get governance() {
        return this._governance;
    }

    private set governance(value: TrafficLightDomain.Governance) {
        this._governance = value;
    }

    public updateGovernance(data: TrafficLightDomain.Governance) {
        this.governance = data;
    }

    public expand() {
        this.window.expand();
    }

    public collapse() {
        this.window.collapse();
    }

    public destroy() {
        this.window.close();
    }

    public async finishCommand() {
        this.governance = await this.service.finishCommand(this.trafficLightId);
    }

    public async holdPhase(params: HoldPhaseInput) {
        const governance = await this.service.holdPhase({ ...params, id: this.trafficLightId });
        if (governance) this.governance = governance;
        this._onRunCommand(this, !!params.startAt);
    }

    public async holdStatus(params: HoldStatusInput) {
        const governance = await this.service.holdStatus({ ...params, id: this.trafficLightId });
        if (governance) this.governance = governance;
        this._onRunCommand(this, !!params.startAt);
    }

    public async holdCycle(params: HoldCycleInput) {
        const data = params.data.map((phase) => ({ phaseTime: phase.tPhase, number: phase.phaseNumber }));
        const governance = await this.service.holdCycle({ ...params, data, id: this.trafficLightId });
        if (governance) this.governance = governance;
        this._onRunCommand(this, !!params.startAt);
    }

    public async extendCommandRuntime() {
        if (!this.runningCommand) return;

        const trafficLightId = this.trafficLightId;
        const diff = Math.floor((new Date(this.runningCommand.expiresAt).getTime() - Date.now()) / 1000);

        const duration = diff + TrafficLightRemoteControl.defaultDurationSeconds;

        let governance: TrafficLightDomain.Governance | void = undefined;

        if (this.runningCommand instanceof TrafficLightDomain.HoldPhaseCommand) {
            governance = await this.service.holdPhase({
                id: trafficLightId,
                data: this.runningCommand.phase,
                duration,
            });
        }
        if (this.runningCommand instanceof TrafficLightDomain.HoldStatusCommand) {
            governance = await this.service.holdStatus({
                id: trafficLightId,
                data: this.runningCommand.status,
                duration,
            });
        }

        if (this.runningCommand instanceof TrafficLightDomain.HoldCycleCommand) {
            governance = await this.service.holdCycle({
                id: trafficLightId,
                data: this.runningCommand.phases.map((phase) => ({ number: phase.number, phaseTime: phase.tPhase })),
                cycleId: this.runningCommand.cycleId,
                duration,
            });
        }

        if (typeof governance !== "undefined") {
            this.governance = governance;
        }
    }
}
