import { WarehouseEntity, WarehouseEntityInfo } from "../warehouse-entity";

export interface TVCameraInfo extends WarehouseEntityInfo {
    streamNums?: string[];
}

export class TVCamera extends WarehouseEntity implements TVCameraInfo {
    public streamNums?: string[];

    constructor(id: number, data: Omit<TVCameraInfo, "id">) {
        super(id, data);
        this.streamNums = data.streamNums ?? [];
    }
}
