import { makeAutoObservable } from "mobx";
import { MapController } from "map-controller";
import { ViewState } from "../../store/view";
import { RouteBuildingLayerBinding, RouteBuildingLayerBindingsDeps } from "./route-building-layer-binding";
import { LayerBinding } from "./layer-binding";
import { TrafficLightsLayerBinding, TrafficLightsBindingDeps } from "./traffic-lights-layer-binding";
import { CooGroupsLayerBinding, CooGroupsBindingDeps } from "./coo-groups-layer-binding";
import { DetectorLayerBinding, DetectorsLayerBindingDeps } from "./detectors-layer-binding";
import { TVCamerasLayerBinding, TVCamerasLayerBindingDeps } from "./tv-cameras-layer-binding";
import { SpeedCamerasLayerBinding, SpeedCamerasLayerBindingDeps } from "./speed-cameras-layer-binding";

export type MapViewModelDeps = RouteBuildingLayerBindingsDeps &
    TrafficLightsBindingDeps &
    CooGroupsBindingDeps &
    DetectorsLayerBindingDeps &
    TVCamerasLayerBindingDeps &
    SpeedCamerasLayerBindingDeps & {
        mapController: MapController;
        viewState: ViewState;
    };

export class MapViewModel {
    private bindings: LayerBinding[] = [];
    private reactions: VoidFunction[] = [];
    private mapController: MapController;
    private viewState: ViewState;

    constructor(deps: MapViewModelDeps) {
        this.mapController = deps.mapController;
        this.viewState = deps.viewState;
        const trafficLightsBinding = new TrafficLightsLayerBinding(deps);
        const cooGroupEditorBinding = new RouteBuildingLayerBinding(deps);
        const cooGroupsBinding = new CooGroupsLayerBinding(deps);
        const detectorsBinding = new DetectorLayerBinding(deps);
        const tvCamerasBinding = new TVCamerasLayerBinding(deps);
        const speedCamerasBinding = new SpeedCamerasLayerBinding(deps);
        this.bindings.push(
            trafficLightsBinding,
            cooGroupEditorBinding,
            cooGroupsBinding,
            detectorsBinding,
            tvCamerasBinding,
            speedCamerasBinding
        );
        makeAutoObservable<MapViewModel, "_bindings">(this, { _bindings: false });
    }

    public onCreateMap = (container: HTMLElement) => {
        this.mapController.on(this.mapController.events.load, this.initBindings);
        this.mapController.createMap(container);
    };

    public onResizeMap = () => {
        this.mapController.resizeMap();
    };

    public onDestroy = () => {
        for (const binding of this.bindings) {
            binding.destroy();
        }
        for (const reaction of this.reactions) {
            reaction();
        }
    };

    private initBindings = () => {
        for (const binding of this.bindings) {
            binding.bind();
        }
    };
}
