import { APIRegistry } from "api";
import { DetectorListMapper } from "shared/mappers";
import { Detector, Direction } from "app-domain/detector";
import { FetchListFilter } from "../common/warehouse-entities";
import { DetectorsService, LoadDetectorOptions } from "./detectors.types";

export class DetectorsAdapter implements DetectorsService {
    private detectorAPI = APIRegistry.detectorAPI;
    private detectorServiceAPI = APIRegistry.detectorServiceAPI;
    private mapper = new DetectorListMapper();

    public async fetchList(filter?: FetchListFilter): Promise<Map<number, Detector>> {
        const detectors = await this.detectorAPI.getDetectors(filter);
        const map = new Map<number, Detector>();
        for (const apiDetector of detectors) {
            const detector = this.mapper.fromAPI(apiDetector);
            map.set(detector.id, detector);
        }
        return map;
    }

    public async fetchItem(detectorId: number, options?: LoadDetectorOptions) {
        const data = await this.detectorAPI.getDetector(detectorId);
        let directions: Direction[] = [];
        if (options?.includeDirections) {
            const detectorAdditionalData = await this.detectorServiceAPI.getById(detectorId);
            directions = detectorAdditionalData.directions.map((direction) => ({
                id: direction.id,
                type: direction.kind,
                lanes: direction.lanes?.map((lane) => ({ number: lane, averageSpeed: 60 })) ?? [],
            }));
        }
        const detector = this.mapper.fromAPI(data);
        detector.directions = directions;
        return detector;
    }
}
