import mapboxgl from "mapbox-gl";
import {
    REACT_APP_MAP_CENTER,
    REACT_APP_KSODD_LAYER,
    REACT_APP_MAP_URL,
    REACT_APP_API_SERVICE_DTM_TRAFFIC,
    REACT_APP_API_SERVICE_WAREHOUSE,
} from "env-data";
import { TypedEmitter } from "tiny-typed-emitter";
import { TrafficLightDispatcher } from "trafficlight-dispatcher";
import { Events, Handlers } from "./map-controller.events";
import { FlyManager } from "./fly-manager";
import { ThemeManager } from "./theme-manager";
import { LayerCollection, LayerKey } from "./layers";

/** Контроллер карты, лениво инициализирует карту и инициализирует контроллеры слоев */
export class MapController {
    public events = Events;
    public isInitialized = false;
    /** @deprecated */
    public flyManager = new FlyManager();
    public themeManager = new ThemeManager();
    public layerKeys = LayerKey;
    private layers = new LayerCollection();
    private emitter: TypedEmitter<Handlers> = new TypedEmitter<Handlers>();
    private map?: mapboxgl.Map;

    constructor(private readonly options: { accessToken: string; dispatcher: TrafficLightDispatcher }) {}

    public set accessToken(value: string) {
        this.options.accessToken = value;
    }

    public readonly on = this.emitter.on.bind(this.emitter);
    public readonly off = this.emitter.off.bind(this.emitter);
    public readonly getLayer = this.layers.getLayer.bind(this.layers);
    public readonly setWorkingMode = this.layers.setWorkingMode.bind(this.layers);
    public readonly getLayerKeys = this.layers.getLayerKeys.bind(this.layers);
    public readonly setActive = this.layers.setActive.bind(this.layers);
    public readonly clearActive = this.layers.clearActive.bind(this.layers);

    public destroy() {
        this.unsubscribe();
        this.layers.destroy();
    }

    public resizeMap() {
        this.map?.resize();
    }

    public flyToPoint(point: LatLng) {
        if (this.map?.getBounds().contains(point)) return;
        this.map?.flyTo({
            center: point,
            maxDuration: 800,
            essential: true,
        });
    }

    public flyToEntity(id: number, layer: LayerKey) {
        const position = this.layers.getEntityPosition(id, layer);
        if (!position) return;
        if (!Array.isArray(position)) return this.flyToPoint(position);
        this.fitPositionInBounds(position);
    }

    public getPointByLocation(location: LatLng): Nullable<{ x: number; y: number }> {
        const point = this.map?.project(location);
        const bounds = this.map?.getContainer().getBoundingClientRect() ?? { x: 0, y: 0 };
        return point ? { x: bounds.x + point.x, y: bounds.y + point.y } : null;
    }

    public createMap = (container: HTMLElement) => {
        this.map = new mapboxgl.Map({
            container,
            zoom: 10,
            fadeDuration: 0,
            hash: true,
            antialias: true,
            interactive: true,
            attributionControl: false,
            transformRequest: this.transformRequest,
            center: REACT_APP_MAP_CENTER as [number, number],
            style: this.style,
        });
        this.subscribe();
    };

    public zoomIn() {
        this.map?.zoomIn();
    }

    public zoomOut() {
        this.map?.zoomOut();
    }

    public getCenter() {
        return this.map?.getCenter();
    }

    private fitPositionInBounds(position: GeoJSON.Position[]) {
        const bounds = new mapboxgl.LngLatBounds(position[0] as [number, number], position[0] as [number, number]);
        for (const coords of position) {
            bounds.extend(coords as [number, number]);
        }
        this.map?.fitBounds(bounds, {
            padding: 200,
            maxDuration: 800,
            essential: true,
        });
    }

    private get style() {
        const baseUrl = `${REACT_APP_MAP_URL}?`;
        const layers = `l=vector&l=${REACT_APP_KSODD_LAYER}`;
        const ksoddLayerVisibility = this.getLayer(LayerKey.KSODD).isVisible ? "" : `&h=${REACT_APP_KSODD_LAYER}`;

        return `${baseUrl}${layers}${this.themeManager.themeParams}${ksoddLayerVisibility}`;
    }

    private handleMapLoad = async () => {
        if (!this.map) return;
        this.isInitialized = true;
        await this.layers.init(this.map);
        this.flyManager.init({
            map: this.map,
            layers: this.layers,
        });
        this.emitter.emit(this.events.load, this.map);
    };

    private _handleThemeChange = () => {
        this.map?.setStyle(this.style, { diff: false });
        this.map?.on("styledata", this._handleThemeChanged);
    };

    private _handleThemeChanged = () => {
        this.layers.onMapThemeChanged();
        this.map?.off("styledata", this._handleThemeChanged);
    };

    private subscribe = () => {
        this.map?.on("load", this.handleMapLoad);
        this.themeManager.on(this.themeManager.events.Change, this._handleThemeChange);
    };

    private unsubscribe() {
        this.map?.off("load", this.handleMapLoad);
        this.themeManager.off(this.themeManager.events.Change, this._handleThemeChange);
        this.map?.off("styledata", this._handleThemeChanged);
        this.isInitialized = false;
    }

    private transformRequest = (url: string) => {
        const request: { url: string; headers?: Object } = { url };

        if (
            url.startsWith(`${REACT_APP_API_SERVICE_WAREHOUSE}/asset`) ||
            url.startsWith(REACT_APP_API_SERVICE_DTM_TRAFFIC)
        ) {
            request.headers = { Authorization: `Bearer ${this.options.accessToken}` };
        }

        return request;
    };
}
