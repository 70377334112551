import { MapMouseEvent } from "mapbox-gl";
import { TrafficLightDomain } from "app-domain";
import { TrafficLightLayer } from "./traffic-light-layer";
import { CustomLayerController } from "../custom-layer-controller";
import { LayerEmitter, BaseEvents, BaseEventKeys } from "../layer-emitter";

type Events = BaseEvents & {
    click: (id: number) => void;
    visualRender: (trafficLight: TrafficLightDomain.TrafficLight) => void;
};

const events = {
    ...BaseEventKeys,
    click: "click",
    visualRender: "visualRender",
} as const;

export class TrafficLightController extends CustomLayerController<Events> {
    protected emitter = new LayerEmitter<Events>();
    protected layer: TrafficLightLayer;

    public get events() {
        return events;
    }

    constructor(id: string) {
        super(id);
        this.layer = new TrafficLightLayer(this.id, this._handleTrafficLightVisualRender);
    }

    public getEntityPosition(id: number) {
        return this.layer.visuals.get(id)?.trafficLight.location;
    }

    public setData(trafficLights: TrafficLightDomain.TrafficLight[]) {
        this.layer.setItems(trafficLights);
    }

    public setSelectedEvent(value: Nullable<TrafficLightDomain.HistoryEvent>) {
        this.layer.setSelectedEvent(value);
    }

    public setActive(id: number | null) {
        this.layer.setActive(id);
    }

    public resetActive() {
        this.layer.resetActive();
    }

    protected _onBeforeDestroy = async () => {
        super._onBeforeDestroy();
        this.unsubscribe();
    };

    protected async _onBeforeAddLayer() {
        this.subscribe();
    }

    private _handleTrafficLightVisualRender = (trafficLight: TrafficLightDomain.TrafficLight) => {
        this.emitter.emit(this.events.visualRender, trafficLight);
    };

    private _handleClick = (event: MapMouseEvent) => {
        if (!this.layer.interactive) return;
        const visual = this.layer?.hitTest(event.lngLat) ?? null;

        if (visual !== null) {
            this.emitter.emit(this.events.click, visual.trafficLight.id);
            return;
        }
    };

    private subscribe = () => {
        this.map.on("click", this._handleClick);
    };

    private unsubscribe = () => {
        this.map.off("click", this._handleClick);
    };
}
