import styled, { css } from "styled-components";
import { ContainerStyleProps } from "./menu-item.types";

const disabledCss = css`
    background-color: transparent;
    cursor: default;
    color: ${({ theme }) => theme.colors.content.tertiary};
`;

export const Container = styled.div<ContainerStyleProps>(
    ({ theme: { spacings, colors }, padding, isActive, isDisabled }) =>
        css`
            display: flex;
            align-items: center;
            padding: ${padding ?? spacings[3].str};
            border-radius: ${spacings[2].str};
            background-color: ${isActive ? colors.containerBackground.tertiary.active : "inherit"};
            border-color: ${isActive ? colors.content.quinary : "transparent"};

            &:hover {
                background-color: ${colors.content.quinary};
            }

            ${isDisabled && disabledCss}
        `
);
