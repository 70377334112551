import { makeAutoObservable, runInAction } from "mobx";
import { SpeedCamera } from "app-domain/speed-camera";
import { BooleanProperty } from "lib";
import { LayerKey, MapController } from "map-controller";
import { WindowInstance } from "shared/window-manager";
import { openURLInNewTab } from "shared/utils/open-url-in-new-tab";
import { REACT_APP_WAREHOUSE_URL } from "env-data";
import { WarehouseEntities } from "../../store/common";

export type SpeedCameraCardViewModelDeps = {
    store: WarehouseEntities<SpeedCamera>;
    mapController: MapController;
    window: WindowInstance;
};

export class SpeedCameraCardViewModel {
    public cameraId: number;
    public camera?: SpeedCamera;
    private mapController: MapController;
    private store: WarehouseEntities<SpeedCamera>;
    private window: WindowInstance;
    private _isLoading = new BooleanProperty(false);

    constructor(deps: SpeedCameraCardViewModelDeps) {
        this.store = deps.store;
        this.mapController = deps.mapController;
        this.window = deps.window;
        this.cameraId = deps.window.id;
        makeAutoObservable<SpeedCameraCardViewModel, "store">(this, { store: false });
        this.loadData();
    }

    public get isLoading() {
        return this._isLoading.value;
    }

    public onCloseClick = () => {
        this.window.close();
    };

    public onShowAllSpecsClick = () => {
        openURLInNewTab(`${REACT_APP_WAREHOUSE_URL}/speedcam/${this.cameraId}`);
    };

    public onLocateClick = () => {
        this.mapController.flyToEntity(this.cameraId, LayerKey.SpeedCameras);
    };

    private async loadData() {
        this._isLoading.setTruly();
        const camera = await this.store.loadItem(this.cameraId);
        runInAction(() => {
            this.camera = camera;
        });
        this._isLoading.setFalsy();
    }
}
