import mapboxgl from "mapbox-gl";
import { REACT_APP_CDN_FILES } from "env-data";
import { WarehouseEntityInfo, WarehouseIssueStatus, WarehouseMonitoringStatus } from "app-domain/warehouse-entity";
import { MapBoxLikeLayerController } from "../mapbox-like-layer-controller";
import { BaseEventKeys, BaseEvents, LayerEmitter } from "../layer-emitter";
import { WarehouseFeaturesLayer } from "./warehouse-features-layer";
import { CustomFeature, CustomFeatureCollection, MapImageLoader } from "../helpers";
import { ActiveFeature } from "./active-feature";

type Events = BaseEvents & {
    itemClick: (id: number) => void;
};

const EventsKeys = {
    ...BaseEventKeys,
    itemClick: "itemClick",
} as const;

export class WarehouseFeaturesController<
    Feature extends WarehouseEntityInfo = WarehouseEntityInfo
> extends MapBoxLikeLayerController<Events> {
    protected layer: WarehouseFeaturesLayer;
    protected activeFeatureLayer?: ActiveFeature;
    protected emitter = new LayerEmitter<Events>();
    private collection?: CustomFeatureCollection<
        CustomFeature<
            number,
            GeoJSON.Point,
            {
                isActive: boolean;
                issueStatus: WarehouseIssueStatus;
                num?: string;
                monitoringStatus?: WarehouseMonitoringStatus;
            }
        >
    >;
    private cacheHover = false;
    private imageLoader: MapImageLoader;
    private activeFeatureId?: number | null;

    constructor(id: string, imagePath: string) {
        super(id);
        this.layer = new WarehouseFeaturesLayer({ id, imagePath });
        this.imageLoader = new MapImageLoader({ endpointURL: REACT_APP_CDN_FILES, filter: imagePath });
    }

    public get events() {
        return EventsKeys;
    }

    public setVisibility(value: boolean): void {
        super.setVisibility(value);
        this.activeFeatureLayer?.setVisibility(value);
    }

    public setActive(id: NullableNumber) {
        if (this.activeFeatureId) this.collection?.updateFeatureProps(this.activeFeatureId, { isActive: false });
        if (id === null) return (this.activeFeatureId = null);
        this.collection?.updateFeatureProps(id, { isActive: true });
        this.activeFeatureId = id;
    }

    public getEntityPosition(id: number): void | LatLng {
        const feature = this.collection?.getFeature(id);
        if (!feature) return;
        const [lng, lat] = feature.geometry.coordinates;
        return { lng, lat };
    }

    public setData(data: Feature[]): void {
        if (!this.collection) return;
        this.collection?.setFeatures(
            data.map((feature) => ({
                id: feature.id,
                geometry: {
                    type: "Point",
                    coordinates: [feature.location.lng, feature.location.lat],
                },
                type: "Feature",
                properties: {
                    num: feature.num,
                    issueStatus: feature.issueStatus,
                    monitoringStatus: feature.monitoringStatus,
                    isActive: feature.id === this.activeFeatureId,
                },
            }))
        );
    }

    protected _onBeforeAddLayer() {
        this._createSource();
        this._subscribe();
        this.activeFeatureLayer = new ActiveFeature(`${this.id}-active`, this.id, this.map);
    }

    protected _onMapThemeChanged() {
        this._unsubscribe();
        super._onMapThemeChanged();
        this._subscribe();
    }

    protected _onBeforeDestroy() {
        super._onBeforeDestroy();
        this.map.removeSource(this.id);
        this._unsubscribe();
    }

    protected _setLayerVisibility(value: boolean): void {
        super._setLayerVisibility(value);
        this.activeFeatureLayer?.setVisibility(value);
    }

    private _handleMouseClick = (event: mapboxgl.MapMouseEvent) => {
        const features = this.map.queryRenderedFeatures(event.point, { layers: [this.id] });
        if (!features.length) return;
        this.emitter.emit(this.events.itemClick, features[0].id as number);
    };

    private _handleMouseMove = (event: mapboxgl.MapMouseEvent & mapboxgl.EventData) => {
        if (!this._isLayerMounted()) return;
        const isHover = !!this.map.queryRenderedFeatures(event.point, { layers: [this.id] }).length;
        if (isHover) {
            this.cacheHover = true;
            this.map.getCanvas().style.cursor = "pointer";
            return;
        }
        if (!this.cacheHover) return;
        this.cacheHover = false;
        this.map.getCanvas().style.cursor = "default";
    };

    private _createSource() {
        if (this.map.getSource(this.id)) return;
        this.collection =
            typeof this.collection === "undefined" ? new CustomFeatureCollection(this.id, this.map) : this.collection;
        this.map.addSource(this.id, this.collection.source);
    }

    private _subscribe() {
        this.map.on("mousemove", this._handleMouseMove);
        this.map.on("click", this.layer.id, this._handleMouseClick);
        this.imageLoader.setMap(this.map).subscribe();
    }

    private _unsubscribe() {
        this.map.off("mousemove", this._handleMouseMove);
        this.map.off("click", this.layer.id, this._handleMouseClick);
        this.imageLoader.unsubscribe();
    }
}
