import { APIRegistry } from "api";
import { TVCamera } from "app-domain";
import { Mappers } from "shared";
import { WarehouseEntitiesService, FetchListFilter } from "../common/warehouse-entities";

export class TVCamerasAdapter implements WarehouseEntitiesService<TVCamera.TVCamera> {
    private _tvCameraAPI = APIRegistry.tvCameraAPI;
    private _mapper = new Mappers.TVCameraListMapper();

    public async fetchList(filter?: FetchListFilter): Promise<Map<number, TVCamera.TVCamera>> {
        const cameraList = await this._tvCameraAPI.getCameras(filter);
        const map = new Map();
        for (const camera of cameraList) {
            map.set(camera.id, this._mapper.fromAPI(camera));
        }
        return map;
    }

    public async fetchItem(id: number): Promise<TVCamera.TVCamera> {
        const data = await this._tvCameraAPI.getCamera(id);
        return this._mapper.fromAPI(data);
    }
}
