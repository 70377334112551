import { Suspense, lazy } from "react";
import { Route, Switch, Router } from "react-router-dom";
import { useAuthContext } from "@megapolis/react-auth";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { lightTheme } from "@megapolis/uikit/theme";
import { ThemeProvider } from "shared/globals";
import { BlockLoader } from "components/block-loader";
import { RegisterGlobalBroker } from "event-brokers";
import { AppContextProvider } from "./store";
import { useApp } from "./app.hooks";
import { GlobalStyles } from "./app.styles";
import { AppRouter } from "./routing";
import "@megapolis/uikit/theme/fonts.css";

// Установка глобальной темы, которая будет использоваться вне контекста React(например: в canvas);
const themeProvider = ThemeProvider.getInstance();
themeProvider.theme = lightTheme;

const MainPage = lazy(() => import("pages/main"));
const pageLoading = <BlockLoader size="48px" />;

export const App = () => {
    useApp();

    const { user } = useAuthContext();

    if (!user) return null;

    AppRouter.init();

    return (
        <Router history={AppRouter.history}>
            <StyledThemeProvider theme={lightTheme}>
                <GlobalStyles />
                <Suspense fallback={pageLoading}>
                    <Switch>
                        <Route path="*">
                            <AppContextProvider>
                                <RegisterGlobalBroker />
                                <MainPage />
                            </AppContextProvider>
                        </Route>
                    </Switch>
                </Suspense>
            </StyledThemeProvider>
        </Router>
    );
};
