export enum WarehouseIssueStatus {
    /**
     * Демонтаж
     */
    Dismantling = 0,

    /**
     * Работает
     */
    Works = 1,

    /**
     * Работает с замечаниями
     */
    WorksWithRemarks = 2,

    /**
     * Не работает
     */
    NoWorks = 3,

    /**
     * Регламентные работы
     */
    RoutineWork = 4,
}
