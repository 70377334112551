import * as React from "react";
import { observer } from "mobx-react-lite";
import { ViewModelType, useCreateViewModel, ViewModelByType } from "./use-view-model";

type ViewModelProviderName<Name extends string> = `${Name}Provider`;
type ViewModelProviderContextHook<Name extends string> = `use${Name}`;

type ViewModelProviderAPI<Name extends string, VM extends new (...args: any[]) => any, Props = {}> = {
    [key in ViewModelProviderName<Name>]: React.FunctionComponent<Props>;
} &
    {
        [key in ViewModelProviderContextHook<Name>]: () => InstanceType<VM>;
    };

export const createViewModelProviderAPI = <
    Type extends ViewModelType,
    Props extends React.PropsWithChildren<{}>,
    Name extends string,
    VM extends ViewModelByType<Type>
>(
    type: Type,
    name: Name,
    renderCallback?: (vm: InstanceType<VM>, props: Props) => React.ReactNode
) => {
    const Context = React.createContext<VM>({} as VM);

    const provider = observer((props: Props) => {
        const vm = useCreateViewModel(type);
        return (
            <Context.Provider value={vm as VM}>
                {typeof renderCallback === "function" ? renderCallback(vm, props) : props.children}
            </Context.Provider>
        );
    });

    const useContext = () => React.useContext(Context);

    return {
        [`${name}Provider`]: provider,
        [`use${name}`]: useContext,
    } as ViewModelProviderAPI<Name, VM, Props>;
};
