import { CycleView } from "../cycle-view";
import { copyCooGroupCycle } from "./context.utils";
import { DrawData } from "../drawing";
import { CooGroupCycle } from "../editor.types";
import { drawParams } from "../editor.constants";
import { FreeRoadSpeed } from "app/store/coo-group-editor/coo-group-editor.types";

export class Context {
    public hasBackwardDirection: boolean = false;
    public isEditMode: boolean = false;
    public totalTime: number = 0;
    public cycles: CycleView[] = [];
    public editableCooGroupCycle: CooGroupCycle;
    public initialCooGroupCycle: CooGroupCycle;
    public view = {
        pixelsPerSecond: 0,
        width: 0,
        height: 0,
    };
    /** Проставляется при синхронизации скоростей */
    public directSpeedSyncCycleId: NullableNumber = null;
    public reverseSpeedSyncCycleId: NullableNumber = null;
    public areDirectSpeedsInSync = false;
    public areReverseSpeedsInSync = false;
    public freeRoadSpeed: FreeRoadSpeed | undefined;
    public startMoveTimestamp: NullableNumber = null;
    private drawDataBuilder = new DrawData();

    constructor(cooGroupCycle: CooGroupCycle) {
        this.editableCooGroupCycle = copyCooGroupCycle(cooGroupCycle);
        this.initialCooGroupCycle = copyCooGroupCycle(cooGroupCycle);
    }

    public get drawData() {
        this.updateDrawData();
        return this.drawDataBuilder;
    }

    public updateDrawData() {
        this.drawDataBuilder.update({
            cycles: this.cycles,
            width: this.view.width,
            height: this.view.height,
            isEditMode: this.isEditMode,
            totalViewTime: this.totalTime,
            defaultFont: drawParams.defaultFont,
            cycleHeadGap: drawParams.cycleHeadGap,
            pixelsPerSecond: this.view.pixelsPerSecond,
            labelStrokeStyle: drawParams.strokeStyle,
            cycleHeadHeight: drawParams.cycleHeadHeight,
            defaultFontSize: drawParams.defaultFontSize,
            dragAnchorWidth: drawParams.dragHandleWidth,
            emptyPartFillStyle: drawParams.phaseBackground,
            hasBackwardDirection: this.hasBackwardDirection,
            directionBarGap: drawParams.backwardDirectionGap,
            directionBarHeight: drawParams.directionBarHeight,
            legendLabelFillStyle: drawParams.legendLabelFillStyle,
            directionLabelFillStyle: drawParams.directionLabelFillStyle,
        });
    }

    public get controlsSettings() {
        const hasBackwardDirection = this.hasBackwardDirection;
        return this.cycles.map((cycle, index) => {
            const prevCycle: CycleView | null = this.cycles[index - 1] ?? null;
            const minDirectionHeight = hasBackwardDirection
                ? drawParams.directionBarHeight + drawParams.backwardDirectionGap
                : 0;

            const settingsTop = prevCycle
                ? prevCycle.y + drawParams.directionBarHeight + minDirectionHeight
                : drawParams.timeScaleTop + drawParams.timeScaleHeight;

            const settingsHeight = prevCycle ? cycle.y - settingsTop : cycle.y - settingsTop;

            return {
                id: cycle.id,
                facilityId: cycle.facilityId,
                shift: cycle.shift,
                trafficLight: cycle.trafficLight,
                cycleDurationTime: cycle.cycleDurationTime,
                cycleMinDurationTime: cycle.cycleMinDurationTime,
                cycleControlsY: cycle.y - drawParams.cycleHeadHeight - drawParams.cycleHeadGap,
                speedControlsY: cycle.speedDistanceLegendY,
                segmentDistance: cycle.segmentDistance,
                directSpeed: cycle.directSpeed,
                reverseSpeed: cycle.reverseSpeed,
                areDirectSpeedsInSync: this.areDirectSpeedsInSync,
                areReverseSpeedsInSync: this.areReverseSpeedsInSync,
                settingsTop,
                settingsHeight,
            };
        });
    }

    public reset(cooGroupCycle: CooGroupCycle) {
        this.initialCooGroupCycle = copyCooGroupCycle(cooGroupCycle);
        this.editableCooGroupCycle = copyCooGroupCycle(cooGroupCycle);
    }
}
