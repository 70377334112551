import { Behavior } from "./behavior";
import { Context } from "./context";
import { drawParams } from "./editor.constants";
import { HitTestTarget } from "./editor.types";

export class InteractionManager {
    private dragState: Nullable<HitTestTarget> = null;

    constructor(private context: Context, private behavior: Behavior, private canvas: HTMLCanvasElement) {}

    public subscribe() {
        this.canvas.addEventListener("mousedown", this.handleMouseDown);
        this.canvas.addEventListener("mousemove", this.handleMove);
        window.addEventListener("mouseup", this.removeListeners);
    }

    public unsubscribe() {
        this.canvas.removeEventListener("mousedown", this.handleMouseDown);
        this.canvas.removeEventListener("mousemove", this.handleMove);
        window.removeEventListener("mouseup", this.removeListeners);
    }

    private setCursor(value: "resize" | "default" | "changeShiftGrab" | "changeShiftGrabbing") {
        let cursor = "default";
        if (value === "resize") cursor = "ew-resize";
        if (value === "changeShiftGrab") cursor = "grab";
        if (value === "changeShiftGrabbing") cursor = "grabbing";
        this.canvas.style.cursor = cursor;
    }

    private updateCursorOnMove(x: number, y: number) {
        const target = this.hitTest(x, y);

        if (!target) return this.setCursor("default");

        if (this.dragState && this.dragState.type === "cycle") return this.setCursor("changeShiftGrabbing");
        if (target.type === "cycle") return this.setCursor("changeShiftGrab");
        if (target.type === "phase") return this.setCursor("resize");
        return this.setCursor("default");
    }

    private readonly handleMouseDown = (event: MouseEvent): void => {
        const dragState = this.hitTest(event.offsetX, event.offsetY);
        if (!dragState) return;
        this.dragState = dragState;
        this.canvas.style.userSelect = "none";
        this.behavior.setTimestamp(event.offsetX);
    };

    private handleMove = (event: MouseEvent) => {
        this.updateCursorOnMove(event.offsetX, event.offsetY);

        if (!this.dragState) return;

        if (this.dragState.type === "cycle") {
            this.behavior.cycleShiftChange(event.offsetX, this.dragState);
        }

        if (this.dragState.type === "phase") {
            this.behavior.cyclePhaseSizeChange(event.offsetX, this.dragState);
        }
        this.behavior.updateDrawData();
        this.behavior.onChange();
    };

    private removeListeners = () => {
        this.canvas.style.userSelect = "unset";
        this.dragState = null;
        this.behavior.setTimestamp(null);
    };

    private hitTest = (x: number, y: number): Nullable<HitTestTarget> => {
        const distanceFromDragElement = 10;
        let target: Nullable<HitTestTarget> = null;
        this.context.cycles.forEach((cycle, index) => {
            const cycleTop = cycle.y;
            const cycleBottom = cycle.y + drawParams.directionBarHeight * 2 + distanceFromDragElement;
            const isPointerInCycle = y > cycleTop - distanceFromDragElement && y < cycleBottom;

            if (!isPointerInCycle) return;

            const targetXInSeconds = Math.round(x / this.context.view.pixelsPerSecond);

            const currentPhaseIndex = cycle.phases.findIndex(
                (phase) => phase.drawOptions.phaseShiftFromStart + phase.tPhase === targetXInSeconds
            );

            let indexOfRepeatCycle;

            for (let index = 0; index < cycle.visibleCycleRepeatCount; index++) {
                const shiftInPixels =
                    (cycle.shift + index * cycle.cycleDurationTime) * this.context.view.pixelsPerSecond;
                if (shiftInPixels - distanceFromDragElement < x && shiftInPixels + distanceFromDragElement > x) {
                    indexOfRepeatCycle = index;
                }
            }

            if (currentPhaseIndex !== -1) {
                const currentPhase = cycle.phases[currentPhaseIndex];
                const nextPhase = cycle.phases[currentPhaseIndex + 1] ?? cycle.phases[0];

                return (target = {
                    type: "phase",
                    dragElement: [currentPhase, nextPhase],
                    cycleIndex: index,
                    repeated: true,
                });
            }
            cycle.visibleCycleRepeatDragIndex = indexOfRepeatCycle;
            return (target = {
                type: "cycle",
                dragElement: cycle,
                repeated: false,
            });
        });
        return target;
    };
}
