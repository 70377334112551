import { TrafficLightDomain } from "app-domain";
import { Manage, APIRegistry } from "api";
import { Factories } from "shared";
import { ITrafficLightRemoteControlService, IHoldParams, HoldCycleParams } from "./traffic-light-remote-control.types";

export class TrafficLightRemoteControlAdapter implements ITrafficLightRemoteControlService {
    private manageAPI: Manage.ManageAPI = APIRegistry.manageAPI;
    private factory = new Factories.TrafficLightGovernanceFactory();

    public async holdCycle({
        id,
        data,
        stopAt,
        startAt,
        cycleId,
        cycleSource,
        duration,
    }: HoldCycleParams): Promise<TrafficLightDomain.Governance | void> {
        const result = await this.manageAPI.enableFacilityTemporaryCycle({
            id,
            stopAt,
            startAt,
            duration,
            customCycleInfo: {
                phases: data,
                cycleId: cycleId ?? 0,
                cycleSource: cycleSource ?? 0,
            },
        });

        if (result) return this.factory.create(result);
    }

    public async holdPhase({
        duration,
        startAt,
        stopAt,
        id,
        data,
    }: IHoldParams<number>): Promise<TrafficLightDomain.Governance | void> {
        const result = await this.manageAPI.postPhase(id, { duration, phase: data, startAt, stopAt });

        if (result) return this.factory.create(result);
    }

    public async holdStatus({
        id,
        duration,
        data,
        startAt,
        stopAt,
    }: IHoldParams<TrafficLightDomain.Enums.StatusCode>): Promise<TrafficLightDomain.Governance | void> {
        const result = await this.manageAPI.postStatus(id, { duration, status: data, startAt, stopAt });

        if (result) return this.factory.create(result);
    }

    public async finishCommand(trafficLightId: number): Promise<TrafficLightDomain.Governance> {
        const result = await this.manageAPI.releaseFacilityCommand(trafficLightId);
        return this.factory.create(result);
    }
}
