import { makeAutoObservable } from "mobx";
import { CooGroupDomain } from "app-domain";
import { CoordinationCycleForm, GreenStreetForm } from "app/view-model/scenario-view-model/forms";

export class CooGroupForm {
    /** Минимальная продолжительность команды в секундах (15 мин) */
    public static cycleMinRunTime = 900;
    private _coordinationForm = new CoordinationCycleForm();
    private _greenStreetForm = new GreenStreetForm();
    private _activeForm: GreenStreetForm | CoordinationCycleForm = this._coordinationForm;

    constructor(public id: number) {
        makeAutoObservable(this);
    }

    public get activeForm() {
        return this._activeForm;
    }

    public get cycle() {
        return this.activeForm.cycle;
    }

    public get greenStreetForm() {
        return this._greenStreetForm;
    }

    public get coordinationForm() {
        return this._coordinationForm;
    }

    public get isCoordinationForm() {
        return this._activeForm instanceof CoordinationCycleForm;
    }

    public get isGreenStreetForm() {
        return this._activeForm instanceof GreenStreetForm;
    }

    public setActiveForm(value: "coordination" | "greenStreet") {
        const form = value === "coordination" ? this.coordinationForm : this.greenStreetForm;
        this._activeForm = form;
    }

    public setCycle(cycle: CooGroupDomain.Cycle) {
        this._activeForm.setCycle(cycle);
    }
}
