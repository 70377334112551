type Listeners = {
    [key: string]: (...args: any[]) => any;
};

type LocalListener<Cb extends (...args: any[]) => any> = {
    once: boolean;
    callback: Cb;
};

type ListenersMap<L extends Listeners> = { [Event in keyof L]: LocalListener<L[Event]>[] };

export class EventEmitter<L extends Listeners> {
    private _listeners: ListenersMap<L> = {} as ListenersMap<L>;

    public on<K extends keyof L>(key: K, callback: L[K]) {
        this._addListener(key, callback, false);
    }

    public once<K extends keyof L>(key: K, callback: L[K]) {
        this._addListener(key, callback, true);
    }

    public off<K extends keyof L>(key: K, callback: L[K]) {
        if (typeof this._listeners[key] === "undefined") return;
        this._listeners[key] = this._listeners[key].filter((_listener) => _listener.callback !== callback);
    }

    public emit<K extends keyof L>(key: K, ...data: Parameters<L[K]>) {
        if (typeof this._listeners[key] === "undefined") return;
        let index = 0;
        for (const listener of this._listeners[key]) {
            listener.callback(...data);
            if (listener.once) this._listeners[key].splice(index, 1);
            index++;
        }
    }

    public removeAllListeners<K extends keyof L>(key?: K) {
        if (typeof key === "undefined") {
            this._listeners = {} as ListenersMap<L>;
            return;
        }
        this._listeners[key] = [];
    }

    private _addListener<K extends keyof L>(key: K, callback: L[K], once: boolean) {
        let listeners = this._listeners[key];
        if (typeof listeners === "undefined") {
            listeners = this._listeners[key] = [];
        }
        listeners.push({ once, callback });
    }
}
