import { DashboardAPI } from "api";
import { SearchEntityType } from "app-domain/dashboard";
import { IAppWindowManager } from "../../store/app-windows";
import { AppRouter, Pages } from "../../routing";

export class AppSearchController {
    private api = new DashboardAPI();

    constructor(private windowManager: IAppWindowManager) {}

    public search(value: string) {
        return this.api.search(value);
    }

    public handleSearchResultClick = (type: SearchEntityType, id: number) => {
        if (type === SearchEntityType.Detector) return this.handleDetectorClick(id);
        if (type === SearchEntityType.SpeedCamera) return this.handleSpeedCameraClick(id);
        if (type === SearchEntityType.TVCamera) return this.handleTVCameraClick(id);
        if (type === SearchEntityType.TrafficLight) return this.handleTrafficLightClick(id);
        if (type === SearchEntityType.CooGroup) return this.handleCooGroupClick(id);
        console.log("Not Implemented");
    };

    private handleDetectorClick = (id: number) => {
        this.windowManager.spawnDetectorCard(id);
    };

    private handleTVCameraClick = (id: number) => {
        this.windowManager.spawnTVCameraCard(id);
    };

    private handleSpeedCameraClick = (id: number) => {
        this.windowManager.spawnSpeedCameraCard(id);
    };

    private handleTrafficLightClick = (id: number) => {
        AppRouter.navigate(Pages.TrafficLightDetails, { id: id.toString() });
    };

    private handleCooGroupClick = (id: number) => {
        AppRouter.navigate(Pages.CooGroupView, { id: id.toString() });
    };
}
