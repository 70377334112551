import { MessageStreamHub, Types } from "../message-hub";
import { REACT_APP_API_DETECTOR_SERVICE } from "env-data";

const StreamName = "DetectorEvent";

const URL = `${REACT_APP_API_DETECTOR_SERVICE}/events`;

export type DetectorStreamMessage = {
    lane: number;
    speed: number;
    vehicleClass: number;
};

export type DetectorStreamSubscriber = Types.Subscriber<DetectorStreamMessage>;

export class DetectorStream {
    private hub = new MessageStreamHub<typeof StreamName>(URL, false);
    private subscribers: Record<number, DetectorStreamSubscriber[]> = {};

    public async init() {
        await this.hub.init();
    }

    public async destroy() {
        await this.hub.destroy();
        this.subscribers = {};
    }

    public subscribeToDetector(detectorId: number, subscriber: DetectorStreamSubscriber) {
        if (!this.hub.getStream(StreamName)) this.hub.createStream(StreamName, detectorId);
        this.hub.subscribe(StreamName, subscriber);
        if (!Array.isArray(this.subscribers[detectorId])) return (this.subscribers[detectorId] = [subscriber]);
        this.subscribers[detectorId].push(subscriber);
    }

    public unsubscribeFromDetector(detectorId: number, subscriber: DetectorStreamSubscriber) {
        this.hub.unsubscribe(StreamName, subscriber);
        this.subscribers[detectorId] = this.subscribers[detectorId].filter((sub) => sub === subscriber);
    }
}
