export enum LaunchType {
    /** Ручной */
    Manual = 0,
    /** По расписанию */
    Scheduled = 1,
    /** По событию */
    ByEvent = 2,
}

export type LaunchTabsType = {
    uid: LaunchType;
    label: string;
};

export const launchTabs: LaunchTabsType[] = [
    {
        uid: LaunchType.Manual,
        label: "Ручной",
    },
    {
        uid: LaunchType.Scheduled,
        label: "По расписанию",
    },
    {
        uid: LaunchType.ByEvent,
        label: "По событию",
    },
];
