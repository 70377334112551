import { computed, makeObservable, override } from "mobx";
import { TVCamera } from "app-domain/tv-camera";
import { MapController } from "map-controller";
import { AppRouter, Pages } from "app/routing";
import { ListViewModel, ListViewModelDeps } from "../list-view-model";

type WindowManager = {
    spawnTVCameraCard(id: number): void;
};

export type TVCamerasListViewModelDeps = ListViewModelDeps<TVCamera> & {
    mapController: MapController;
    windowManager: WindowManager;
};

export class TVCamerasListViewModel extends ListViewModel<TVCamera> {
    private mapController: MapController;
    private windowManager: WindowManager;

    constructor(deps: TVCamerasListViewModelDeps) {
        super(deps);
        this.mapController = deps.mapController;
        this.windowManager = deps.windowManager;
        makeObservable<TVCamerasListViewModel>(this, {
            onClick: override,
            onDoubleClick: override,
            onArrowClick: override,
            activeItem: computed,
        });
    }

    public get activeItem() {
        const { params } = AppRouter.getCurrentRoute<Pages.TVCameraList>();
        return this.getById(Number(params?.id));
    }

    public onClick(camera: TVCamera) {
        AppRouter.navigate(Pages.TVCameraList, { id: camera.id.toString() });
    }

    public onDoubleClick(camera: TVCamera) {
        this.windowManager.spawnTVCameraCard(camera.id);
    }

    public onArrowClick(camera: TVCamera) {
        this.onDoubleClick(camera);
    }
}
