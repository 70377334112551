import { CooGroupDomain } from "app-domain";
import { action, makeObservable, observable } from "mobx";
import { Form } from "./abstract-form";

export class CoordinationCycleForm extends Form {
    public hasChanges: boolean = false;
    public viewCycle: CooGroupDomain.CustomCycle | null = null;

    constructor() {
        super();
        makeObservable(this, {
            setCycle: action,
            updateCycle: action,
            hasChanges: observable,
            viewCycle: observable.ref,
        });
    }

    public updateCycle(hasChanges: boolean, cycle: CooGroupDomain.CustomCycle) {
        if (!this.viewCycle) return;
        this.viewCycle.cycles = cycle.cycles;
        this.hasChanges = hasChanges;
    }

    public setCycle(cycle: CooGroupDomain.Cycle): void {
        super.setCycle(cycle);
        this.viewCycle = {
            ...cycle,
            cycles: cycle.cycles ? Array.from(cycle.cycles) : [],
        };
    }
}
