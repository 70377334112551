import { makeAutoObservable, runInAction } from "mobx";
import { TVCamera } from "app-domain/tv-camera";
import { TVCameras } from "../../store/tv-cameras";
import { BooleanProperty } from "lib";
import { WindowInstance } from "shared/window-manager";
import { openURLInNewTab } from "shared/utils";
import { REACT_APP_WAREHOUSE_URL } from "env-data";
import { LayerKey, MapController } from "map-controller";

export type TVCameraCardViewModelDeps = {
    store: TVCameras;
    window: WindowInstance;
    mapController: MapController;
};

export class TVCameraCardViewModel {
    public camera?: TVCamera;
    private store: TVCameras;
    private _isLoading = new BooleanProperty(false);
    private cameraId: number;
    private window: WindowInstance;
    private mapController: MapController;

    constructor(deps: TVCameraCardViewModelDeps) {
        this.store = deps.store;
        this.window = deps.window;
        this.cameraId = this.window.id;
        this.mapController = deps.mapController;
        makeAutoObservable(this);
        this.loadData();
    }

    public get isLoading() {
        return this._isLoading.value;
    }

    public onCloseClick = () => {
        this.window.close();
    };

    public onLocateClick = () => {
        this.mapController.flyToEntity(this.cameraId, LayerKey.Detectors);
    };

    public onAllSpecsClick = () => {
        openURLInNewTab(`${REACT_APP_WAREHOUSE_URL}/camera/${this.cameraId}`);
    };

    private async loadData() {
        this._isLoading.setTruly();
        const camera = await this.store.loadItem(this.cameraId);
        runInAction(() => {
            this.camera = camera;
        });
        this._isLoading.setFalsy();
    }
}
