import { AppRouter, Pages } from "app/routing";
import { useCallback } from "react";

export const useSetActiveScenario = () => {
    return useCallback((id: NullableNumber) => {
        if (id === null) {
            return AppRouter.navigate(Pages.ScenarioList);
        }
        AppRouter.navigate(Pages.ScenarioDetails, { id: String(id) });
    }, []);
};
