import { APIRegistry } from "api";
import { TrafficLightHistoryService } from "./traffic-light-history";
import { GeocodeService } from "./geocode";
import { DtmRouterService } from "./dtm-router";
import { StateService } from "./state";
import { CooGroupService } from "./coo-group";
import { TrafficLightService } from "./traffic-lights";
import { IssueService } from "./issues";
import { WarehouseService } from "./warehouse";
import { YandexGeocodeService } from "./yandex-geocode";
import { CooGroupCycleService } from "./coo-group-cycle-service";
import { ScenarioService } from "app/store/scenarios/scenario.service";

export const ServiceRegistry = {
    dtmRouterService: new DtmRouterService(APIRegistry.dtmRouterAPI),
    geocodeService: new GeocodeService(APIRegistry.geocodeAPI),
    trafficLightHistoryService: new TrafficLightHistoryService(APIRegistry.facilityAPI),
    stateService: new StateService(APIRegistry.stateAPI),
    cooGroupService: new CooGroupService(
        APIRegistry.cooGroupAPI,
        APIRegistry.facilityAPI,
        APIRegistry.stateAPI,
        APIRegistry.manageAPI
    ),
    CooGroupCycleService: new CooGroupCycleService(APIRegistry.cooGroupAPI),
    trafficLightService: new TrafficLightService(
        APIRegistry.facilityAPI,
        APIRegistry.assetTrafficLightAPI,
        APIRegistry.manageAPI,
        APIRegistry.stateAPI,
        APIRegistry.cycleAPI
    ),
    scenarioService: new ScenarioService(),
    issueService: new IssueService(APIRegistry.facilityAPI),
    warehouseService: new WarehouseService(),
    yandexGeocodeService: new YandexGeocodeService(APIRegistry.yandexGeocodeAPI),
};
