import { action, makeObservable, observable } from "mobx";
import { createBrowserHistory } from "history";
import { matchPath } from "react-router-dom";
import { Pages, routes, RouteName } from "./routes";

const browserHistory = createBrowserHistory();

type Routes = typeof routes;

type RouteParams<Name extends keyof Routes> = Parameters<Routes[Name]["createURL"]>[number];

type Route<Name extends RouteName> = {
    name: Name;
    params: RouteParams<Name>;
    url: string;
    path: string;
};

const routeNameByPath = Object.entries(routes).reduce((result, [name, route]) => {
    result[route.path] = name as RouteName;
    return result;
}, {} as Record<string, RouteName>);

const appMatcher = Object.values(routes).map((route) => route.path);

export class Router {
    public history = browserHistory;
    private route: Route<RouteName> = { name: Pages.Dashboard } as Route<Pages.Dashboard>;
    private prevRoute: Route<RouteName> | null = null;

    constructor() {
        makeObservable<Router, "syncPath" | "route" | "prevRoute">(this, {
            syncPath: action.bound,
            navigate: action,
            route: observable.ref,
            prevRoute: observable.ref,
        });
        this.history.listen(this.syncPath);
    }

    public init() {
        this.syncPath();
    }

    public navigate<Name extends RouteName>(name: Name, params?: RouteParams<Name>) {
        // @ts-ignore
        const newURL = routes[name].createURL(params as any);
        if (params && this.history.location.pathname === newURL) return;
        const hash = this.history.location.hash;
        this.history.push(newURL + hash);
    }

    public getRouteNameByPath(path: string) {
        return routeNameByPath[path];
    }

    public getRouteParams<Name extends RouteName>(name: Name): RouteParams<Name> | null {
        return this.route.name === name ? this.route.params : null;
    }

    public getCurrentRoute<Name extends RouteName = RouteName>() {
        return this.route as Route<Name>;
    }

    public getPrevRoute<Name extends RouteName = RouteName>() {
        return this.prevRoute as Route<Name> | null;
    }

    private syncPath = () => {
        const match = matchPath<RouteParams<RouteName>>(this.history.location.pathname, {
            path: appMatcher,
        });
        if (!match) return this.navigate(Pages.Dashboard);

        this.prevRoute = { ...this.route };
        this.route = { url: match.url, path: match.path, params: match.params, name: routeNameByPath[match.path] };
    };
}

export const AppRouter = new Router();
