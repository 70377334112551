import mapboxgl from "mapbox-gl";
import { MapImageLoader } from "../helpers";

export class ActiveFeature {
    public readonly id;
    private readonly map: mapboxgl.Map;
    private readonly icon = "active-substrate";
    private imageLoader: MapImageLoader;

    constructor(id: string, private sourceId: string, map: mapboxgl.Map) {
        this.id = id;
        this.map = map;
        this.imageLoader = new MapImageLoader({
            endpointURL: global.location.origin,
            filter: this.icon,
            imageFormat: "png",
            map,
        });
        this.imageLoader.subscribe();
        this.createLayer();
    }

    public setVisibility = (visibility: boolean) => {
        this.map.setLayoutProperty(this.id, "visibility", this.layoutVisibilityProperty(visibility));
    };

    public destroy = () => {
        this.removeLayer();
        this.imageLoader.unsubscribe();
    };

    private layoutVisibilityProperty(visibility: boolean): mapboxgl.Visibility {
        return visibility ? "visible" : "none";
    }

    private getZoomLinearExpression = (base: number | undefined = 1): mapboxgl.Expression => {
        return ["interpolate", ["linear"], ["zoom"], 8, base * 0.1, 22, base * 1.5];
    };

    private get layout(): mapboxgl.SymbolLayout {
        return {
            "icon-image": this.icon,
            "icon-size": this.getZoomLinearExpression(0.5),
            "icon-allow-overlap": true,
            "icon-offset": [0, 0],
            "icon-pitch-alignment": "map",
            "visibility": "visible",
            "symbol-z-order": "source",
        };
    }

    private createLayer() {
        const config: mapboxgl.SymbolLayer = {
            id: this.id,
            type: "symbol",
            source: this.sourceId,
            layout: this.layout,
            filter: ["==", "isActive", true],
        };

        this.map.addLayer(config);
    }

    private removeLayer() {
        if (!this.map.getLayer(this.id)) return;

        this.map.removeLayer(this.id);
    }
}
