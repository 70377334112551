import { Pages } from "app/routing";

export enum TimelineEntityType {
    TrafficLight,
    CooGroup,
}

export type TimelineEntity = {
    type: TimelineEntityType;
    id: number;
};

export type ObservableRoute =
    | Pages.TrafficLightDetails
    | Pages.TrafficLightList
    | Pages.CooGroupList
    | Pages.CooGroupView;
