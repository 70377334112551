// import { useCallback, useEffect } from "react";
// import { TrafficLightDomain } from "app-domain";
// import { Store } from "app";
// import { Hooks } from "shared";

export const useMapControllerEvents = () => {
    // const { mapController } = Store.useAppState();
    // const trafficLightStore = Store.useTrafficLightStore();
    // const cooGroupStore = Store.useCooGroupStore();
    // const activeCooGroupId = Store.useActiveCooGroupId();
    // const cooGroupId = activeCooGroupId || cooGroupStore.firstInSelection?.id;
    // const activeTrafficLightId = Store.useActiveTrafficLightId();
    // const tlsId = activeTrafficLightId || trafficLightStore.firstInSelection?.id;
    // const viewState = Store.useViewState();
    // const select = Store.useSelectTrafficLight();
    // const setActive = Store.useSetActiveTrafficLight();
    // const flyToCooGroup = Hooks.useFlyToCooGroup();
    // const flyToTrafficLight = Hooks.useFlyToTrafficLight();
    // const updateTrafficLight = useCallback(
    //     (trafficLight: TrafficLightDomain.TrafficLight) => {
    //         if (viewState.isTrafficLightListView) {
    //             select(trafficLight.id, true);
    //         } else {
    //             setActive(trafficLight.id);
    //         }
    //     },
    //     [select, setActive, viewState]
    // );
    // useEffect(() => {
    //     const { cooGroupController, trafficLightController } = mapController.layerControllers;
    //     const trafficLight = tlsId && trafficLightStore.getById(tlsId);
    //     if (cooGroupId) {
    //         flyToCooGroup(cooGroupId);
    //         cooGroupController?.setActive(cooGroupId);
    //     } else {
    //         cooGroupController?.resetActive();
    //     }
    //     if (trafficLight) {
    //         flyToTrafficLight(tlsId);
    //         trafficLightController?.setActive(trafficLight);
    //     } else {
    //         trafficLightController?.resetActive();
    //     }
    // }, [mapController.layerControllers, cooGroupId, trafficLightStore, tlsId, flyToCooGroup, flyToTrafficLight]);
    // useEffect(() => {
    //     const handleLoad = () => {
    //         const { trafficLightController, cooGroupController } = mapController.layerControllers;
    //         const trafficLight = activeTrafficLightId && trafficLightStore.getById(activeTrafficLightId);
    //         if (trafficLight) {
    //             flyToTrafficLight(activeTrafficLightId);
    //             trafficLightController?.setActive(trafficLight);
    //         } else {
    //             trafficLightController?.resetActive();
    //         }
    //         if (activeCooGroupId) {
    //             flyToCooGroup(activeCooGroupId);
    //             cooGroupController?.setActive(activeCooGroupId);
    //         } else {
    //             cooGroupController?.resetActive();
    //         }
    //         trafficLightController?.emitter.on(trafficLightController.emitter.events.activeChanged, updateTrafficLight);
    //     };
    //     mapController.on(mapController.events.load, handleLoad);
    //     return () => {
    //         mapController.off(mapController.events.load, handleLoad);
    //     };
    // }, [
    //     activeTrafficLightId,
    //     activeCooGroupId,
    //     mapController,
    //     trafficLightStore,
    //     updateTrafficLight,
    //     flyToTrafficLight,
    //     flyToCooGroup,
    // ]);
    // useEffect(
    //     () => () => {
    //         const { trafficLightController } = mapController.layerControllers;
    //         trafficLightController?.emitter.off(
    //             trafficLightController.emitter.events.activeChanged,
    //             updateTrafficLight
    //         );
    //     },
    //     [mapController, updateTrafficLight]
    // );
};
