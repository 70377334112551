import { AppStateManager } from "app/store";
import { MapController } from "map-controller";
import { Broker } from "./broker";
import { TrafficLightHistoryBroker, TrafficForecastHistoryBroker } from "./brokers";
import { CooGroupBroker } from "./brokers/coo-group";

export class GlobalBroker extends Broker {
    private readonly cooGroupBroker: CooGroupBroker;
    private readonly trafficLightHistoryBroker: TrafficLightHistoryBroker;
    private readonly trafficForecastHistoryBroker: TrafficForecastHistoryBroker;

    constructor(mapController: MapController, uiStore: AppStateManager) {
        super(mapController, uiStore);
        this.cooGroupBroker = new CooGroupBroker(this.mapController, this.uiStore);
        this.trafficLightHistoryBroker = new TrafficLightHistoryBroker(
            this.mapController,
            this.uiStore.trafficLightHistoryStore
        );
        this.trafficForecastHistoryBroker = new TrafficForecastHistoryBroker(
            this.mapController,
            this.uiStore.trafficForecastHistory
        );
    }

    public destroy = () => {
        this.cooGroupBroker.destroy();
        this.trafficLightHistoryBroker.destroy();
        this.trafficForecastHistoryBroker.destroy();
    };
}
