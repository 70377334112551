import { TypedEmitter } from "tiny-typed-emitter";
import { ServiceRegistry } from "services";
import { configuration } from "api/config";
import { REACT_APP_API_BASEURL } from "env-data";
import { CooGroup } from "../../coo-group";
import { StatusCode, ControlModeCode, EventTypeCode, DetectorType, ConditionType, CycleSource } from "../enums";
import { CodeDictionary, MalfunctionCodeDictionary } from "../types";
import { ServerInfo } from "../../common";
import {
    controlModeCodeDictionaryMap,
    malfunctionCodeDictionaryMap,
    statusCodeDictionaryMap,
    phaseGroupEntries,
} from "../constants";
import { TrafficLightMessage } from "./message";
import { TrafficLightState } from "./traffic-light-state";
import { CustomCycle } from "./custom-cycle";
import { DetectorStateMessage, DetectorTriggerMessage } from "./detector";
import { Direction, IDirection } from "./direction";
import { ModelInfo } from "./model-info";
import { Phase, PhaseStart } from "./phase";
import { CameraInfo } from "./camera-info";
import { DirectionLane, DirectionLaneMetrics } from "./direction-lane";
import { AssetDataT } from "./asset-data";
import { TrafficLightInitialData, TrafficLightMetaData } from "./traffic-light-data";
import { Detector } from "./detector";
import { messageFromLog } from "../utils";
import { GovernanceInfo } from "./governance";

export type AssetDataShape = {
    isFetching: boolean;
    data: AssetDataT | null;
};

export type PhaseItemsGroup = {
    name: string;
    items: Phase[];
};

/**
 *  TODO: убрать
 */
export interface LogFilter {
    /**Начальная дата */
    from?: Date;

    /**Конечная дата */
    to?: Date;

    /**Номер страницы */
    page?: number;

    /**Размер страницы */
    pageSize?: number;
}

/**
 *  TODO: убрать
 */
/**Фильтр для запроса лога работы СО */
export interface TrafficLightLogFilter extends LogFilter {
    /**Список типов событий */
    eventType?: EventTypeCode[];

    /**Список режимов работы */
    controlMode?: ControlModeCode[];

    /**Состояние СО */
    status?: StatusCode[];

    // /**Код ошибки */
    // errorCode?: ErrorCode[];
}

export interface TrafficLightEvents {
    initialized: () => void;
    currentPhaseChanged: (args: { currentPhase: PhaseStart | null }) => void;
    message: (args: { message: TrafficLightMessage }) => void;
    subTactChanged: (args: { isSubTact: boolean }) => void;
    statusChanged: (args: { status: StatusCode }) => void;
    errorStatusChanged: () => void;
    controlModeChanged: (args: { controlMode: ControlModeCode }) => void;
    activeProgramChanged: (args: { value: number | null }) => void;
    controlSessionChanged: () => void;
    directionLaneIsBusyChanged: (args: { lane: DirectionLane; isBusy: boolean }) => void;
    directionLaneMetricsChanged: (args: { lane: DirectionLane; metrix: DirectionLaneMetrics }) => void;
    adaptiveModuleEnabledChanged: (args: { enabled: boolean }) => void;
    localAdaptiveEnabledChange: (value: boolean) => void;
    cyclesChanged: () => void;
    assetDataChanged: () => void;
    governanceChanged: (governance: NullableNumber) => void;
    conditionChanged: (condition: ConditionType) => void;
    camerasChanged: (cameras: CameraInfo[]) => void;
    plannedGovernanceTimeChanged: (args: { plannedGovernanceTime?: NullableString }) => void;
}

const MS_IN_SECOND = 1000;

export class TrafficLight extends TypedEmitter<TrafficLightEvents> {
    public id: number;
    public controlMode: CodeDictionary<ControlModeCode> = controlModeCodeDictionaryMap[ControlModeCode.Unknown];
    public status: CodeDictionary<StatusCode> = statusCodeDictionaryMap[StatusCode.Unknown];
    public phasesByGroups: PhaseItemsGroup[] = [];
    public currentPhase: PhaseStart | null = null;
    public passportId?: number;
    public num?: string;
    public boxSize?: number;
    public address?: string;
    public streets?: string[];
    public modelInfo?: ModelInfo;
    public plannedGovernanceTime?: NullableString;
    /** Направления по паспорту */
    public isSubTact?: boolean;
    public cameras: CameraInfo[] = [];
    public groups: CooGroup[] = [];
    public activeIssueCount: number;
    public cyclesWithWarningCount: number;
    public issueCount: number;
    /** Состояние по заявкам (warehouse) */
    public assetStatus?: string;
    public userDisplayName?: string;
    public readonly directionLaneByDetector: Record<string, DirectionLane> = {};
    public readonly serverInfo: ServerInfo;
    public location: LatLng = { lat: 0, lng: 0 };
    public isInitializing = false;
    public assetLifecycle?: string;
    /** Паспортные и кастомные циклы */
    private _cycles: CustomCycle[] = [];
    private _currentCycleNum: NullableNumber = null;
    private _condition: ConditionType = ConditionType.Offline;
    private _directions: Direction[] = [];
    private _phases: Phase[] = [];
    private _isInitialized = false;
    private _isCyclesInitialized = false;
    private _malfunctions: MalfunctionCodeDictionary[] = [];
    private _directionByNum: Record<number, Direction> = {};
    private _isAdaptiveModuleEnabled = false;
    private _isLocalAdaptiveEnabled = false;
    /** Минимальная информация об активном объекте управления СО */
    private _governanceInfo: Nullable<GovernanceInfo> = null;
    private trafficLightService = ServiceRegistry.trafficLightService;
    private historyService = ServiceRegistry.trafficLightHistoryService;
    /** @deprecated Будет переработан */
    private assetDataShape: AssetDataShape = {
        isFetching: false,
        data: null,
    };

    constructor(data: TrafficLightInitialData) {
        super();
        this.id = data.facilityId;
        this.passportId = data.id;
        this.location = {
            lat: data.lat,
            lng: data.lng,
        };
        this.serverInfo = data.serverInfo;
        this.boxSize = data.boxSize;
        this.num = data.num;
        this.address = data.address;
        this.streets = data.streets;
        this.activeIssueCount = data.activeIssueCount ?? 0;
        this.cyclesWithWarningCount = data.cyclesWithWarningCount ?? 0;
        this.issueCount = data.issueCount ?? 0;
        this.userDisplayName = data.userDisplayName;
        this.assetStatus = data.assetStatus;

        if (data.state) {
            this.updateState(data.state);
        }

        this.setMaxListeners(100);
    }

    public get cycles() {
        return this._cycles;
    }

    public set cycles(cycles) {
        this._cycles = cycles;
        this._isCyclesInitialized = true;
        this.emit("cyclesChanged");
    }

    public static getErrorsFromStatusCode(statusCode: number) {
        return Object.values(malfunctionCodeDictionaryMap)
            .filter((e) => statusCode & e.code)
            .map((e) => ({ ...e }));
    }

    public get governanceInfo() {
        return this._governanceInfo;
    }

    public set governanceInfo(info: GovernanceInfo | null) {
        this._governanceInfo = info;
        this.emit("governanceChanged", this.governanceInfo?.id ?? null);
    }

    public get assetData() {
        if (!this.assetDataShape.data && !this.assetDataShape.isFetching) {
            this.fetchAssetData();
        }
        return this.assetDataShape;
    }

    public get isUnderControl() {
        return this.controlModeCode === ControlModeCode.User || this.controlModeCode === ControlModeCode.Console;
    }

    public get isOffline() {
        return this.condition === ConditionType.Offline;
    }

    public get isNotConnected() {
        return this.condition === ConditionType.NotConnected;
    }

    public getDirectionByNum(num: number) {
        return this._directionByNum[num];
    }

    public getPhaseByNum(num: number) {
        return this.phases[num];
    }

    public getDirectionLaneByDetector(detectorId: number, channel: number): DirectionLane | null {
        return this.directionLaneByDetector[`${detectorId}_${channel}`] ?? null;
    }

    public updateDirectionLaneIsBusy(msg: DetectorTriggerMessage) {
        const lane = this.directionLaneByDetector[`${msg.detector}_${msg.channel}`];
        if (!lane) return;
        lane.isBusy = msg.isBusy;
        this.emit("directionLaneIsBusyChanged", {
            lane,
            isBusy: msg.isBusy,
        });
    }

    public updateDirectionLaneMetrics(msg: DetectorStateMessage) {
        const lane = this.directionLaneByDetector[`${msg.detector}_${msg.channel}`];
        if (!lane) return;
        lane.setMetrics(msg.metrics);
        this.emit("directionLaneMetricsChanged", {
            lane,
            metrix: msg.metrics,
        });
    }

    public get timer(): number {
        let timer = 0;
        const now = new Date().getTime();
        if (this.currentPhase) {
            const dt = Math.floor((now - this.currentPhase.start) / 500) / 2;
            if (dt < this.currentPhase!.phase.duration) {
                // const phasePercentage = dt / this.currentPhase.phase.duration;
                timer = Math.round(this.currentPhase.phase.duration - dt);
            }
        }
        return timer;
    }

    public getActiveCycle() {
        return this.cycles.find((cycle) => cycle.isActiveNow && cycle.source === CycleSource.Passport) ?? null;
    }

    public get isAdaptiveModuleEnabled() {
        return this._isAdaptiveModuleEnabled;
    }

    public get isLocalAdaptiveEnabled() {
        return this._isLocalAdaptiveEnabled;
    }

    public get criticalErrors() {
        return this._malfunctions.filter((e) => e.isCritical);
    }

    public get nonCriticalErrors() {
        return this._malfunctions.filter((e) => !e.isCritical);
    }

    /** @deprecated */
    public get criticalErrorCount(): number {
        return this._malfunctions.filter((e) => e.isCritical).length;
    }

    /** @deprecated */
    public get nonCriticalErrorCount(): number {
        return this._malfunctions.filter((e) => !e.isCritical).length;
    }

    /** Список ошибок СО */
    public get errors() {
        return this._malfunctions;
    }

    public get controlModeCode() {
        return this.controlMode.code;
    }

    public get statusCode() {
        return this.status.code;
    }

    public get isInitialized() {
        return this._isInitialized;
    }

    public get isCyclesInitialized() {
        return this._isCyclesInitialized;
    }

    /** Может ли быть управляемым в рамках ленты координации */
    public get isCoordinationControllable() {
        const noCriticalErrors = this.condition !== ConditionType.Critical;
        const isOnline = !this.isOffline;
        const status = this.statusCode;
        const isValidStatus =
            status !== StatusCode.AllRed &&
            status !== StatusCode.YellowBlink &&
            status !== StatusCode.Hold &&
            status !== StatusCode.Disabled;
        const isControllable = isOnline && noCriticalErrors && isValidStatus;
        return this.controlModeCode === ControlModeCode.User
            ? isControllable && status !== StatusCode.Cycle
            : isControllable;
    }

    public addCycle(cycle: CustomCycle) {
        this.cycles.push(cycle);
        this.emit("cyclesChanged");
    }

    public getActiveCycleTime() {
        return this.cycles?.find((item) => item.number === this.currentCycleNum)?.time;
    }

    /**
     * TODO: подумать, что с этим можно сделать
     * @deprecated
     */
    public async fetchTrafficlightLog(filter: TrafficLightLogFilter): Promise<TrafficLightMessage[]> {
        const map = await this.historyService.getHistoryEvents({
            trafficLightId: this.id,
            filter: {
                start: filter.from?.toISOString(),
                finish: filter.to?.toISOString(),
                controlModes: filter.controlMode,
                eventTypes: filter.eventType,
                statuses: filter.status,
            },
        });
        const items: TrafficLightMessage[] = [];
        map.items.forEach((event) => items.push(messageFromLog(event)));
        return items;
        // return this.dispatcher.fetchTrafficlightsLog([this.id], filter);
    }

    /** @deprecated Чуть позже будет убран */
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
    public async fetchDetectorLog(filter: any): Promise<any> {
        try {
            console.log("Trying to fetch detector log");
            let url = `${REACT_APP_API_BASEURL}/facility/${this.id}/detector/logs?count=10000`;

            if (filter.from) {
                url += `&start=${filter.from.toISOString()}`;
            }

            if (filter.to) {
                url += `&finish=${filter.to.toISOString()}`;
            }
            const response = await fetch(url, {
                headers: {
                    Authorization: `Bearer ${configuration.accessToken}`,
                },
            });
            return await response.json();
        } catch (error) {
            console.log("Cathed error while tried to fetch detector log");
            console.error(error);
        }
    }

    public processSignalMessage(message: TrafficLightMessage) {
        this.emit("message", { message });
    }

    public updateState(state: TrafficLightState) {
        this.setCurrentCycleNum(state.program);
        this.updateMalfunctions(state.malfunctions);
        this.updateStatus(state.status);
        this.updateControlMode(state.controlMode);
        this.updateAdaptiveModuleEnabled(state.isGuidedAdaptiveAllowed);
        this.updateLocalAdaptiveEnabled(state.isLocalAdaptiveEnabled);
        this.updateCondition(state.condition);
        this.updatePlannedGovernanceTime(state.plannedGovernanceTime);
        // Факт из управления внешней системы - приоритетней.
        this.governanceInfo = state.externalGovernance ?? state.governanceInfo;
        if (
            this.status.code === StatusCode.Cycle ||
            this.status.code === StatusCode.Hold ||
            this.status.code === StatusCode.LocalAdaptive ||
            this.status.code === StatusCode.Coordination
        ) {
            if (this.isSubTact !== state.isPromTime) {
                this.isSubTact = state.isPromTime;
                this.emit("subTactChanged", { isSubTact: this.isSubTact });
            }

            this.currentPhase = {
                phase: {
                    num: state.phase,
                    duration: state.phaseTime,
                    next: state.nextPhase,
                },
                start: this.serverInfo.now - state.secondsGone * MS_IN_SECOND,
            };
            this.emit("currentPhaseChanged", { currentPhase: this.currentPhase });
        } else {
            this.currentPhase = null;
            this.emit("currentPhaseChanged", { currentPhase: null });
        }
    }

    private setPhases(phases: Phase[]) {
        const phaseGroupMap: Record<string, Phase[]> = {};
        this._phases = new Array(phases.length);

        for (let i = 0, len = phases.length; i < len; i++) {
            const phase = { ...phases[i] };
            this.phases[phase.number] = phase;
            phase.directionByNum = {};
            if (phase.directions && phase.directions.length) {
                for (let j = 0, len1 = phase.directions.length; j < len1; j++) {
                    phase.directionByNum[phase.directions[j]] = true;
                }
            }
            const groupName = phaseGroupEntries[phase.type];
            const groupItems = phaseGroupMap[groupName];
            if (groupItems) {
                groupItems.push(phase);
            } else {
                phaseGroupMap[groupName] = [phase];
            }
        }

        this.phasesByGroups = Object.entries(phaseGroupMap).map(([name, items]) => ({ name, items }));
    }

    public get isAdaptiveControlMode() {
        return this.controlMode.code === ControlModeCode.Adaptive;
    }

    public get phases() {
        return this._phases;
    }

    public get activeCycle() {
        return this.cycles.find((cycle) => cycle.number === this.currentCycleNum) ?? null;
    }

    public setDirections(directions: IDirection[]) {
        let newDirections: Direction[] = [];
        directions
            .slice()
            .sort((a, b) => a.number - b.number)
            .forEach((directionData) => {
                const newDirection = new Direction(directionData);
                this._directionByNum[newDirection.number] = newDirection;
                newDirections.push(newDirection);
            });
        this._directions = newDirections;
    }

    public get directions() {
        return this._directions;
    }

    public getPhaseGroupsByDirectionNumber(directionNumber: number) {
        return this.phasesByGroups.reduce((result, group) => {
            const items = group.items.filter((phase) => phase.directionByNum[directionNumber]);
            if (!items.length) return result;
            result.push({
                name: group.name,
                items,
            });
            return result;
        }, [] as PhaseItemsGroup[]);
    }

    public setDetectors(detectors?: Detector[]) {
        if (!this.directions.length || !detectors) return;
        detectors.forEach((detector) => {
            detector.channels.forEach((channel) => {
                const direction = this._directionByNum[channel.direction];
                if (!direction) return;
                let lane = direction.laneByNum[channel.channel];
                if (!lane) {
                    lane = new DirectionLane(channel.channel, direction.number);
                    direction.lanes.push(lane);
                    if (detector.type === DetectorType.Video) direction.videoDetector = detector;
                    direction.laneByNum[channel.channel] = lane;
                }

                this.directionLaneByDetector[`${detector.number}_${channel.channel}`] = lane;
            });
        });
    }

    public setCameras(cameras: CameraInfo[]) {
        this.cameras = cameras;
        this.emit("camerasChanged", cameras);
    }

    /** Проверяет направление на разрешенность(зеленый/красный свет) */
    public isDirectionAllowed(direction: Direction) {
        if (!this.currentPhase) return false;
        const phase = this.phases[this.currentPhase.phase.num];
        return !!phase.directionByNum[direction.number];
    }

    /**
     * Проставить важные метаданные светофора
     * после их простановки мы считаем, что объект СО
     * прошел ининциализацию
     * Это важно для зависимых сущностей
     * например для слоя светофоров на карте, где важно отображение фаз + направлений
     */
    public setMetaData(data: TrafficLightMetaData) {
        if (this.isInitialized) return;
        this.setDirections(data.directions);
        this.setPhases(data.phases);
        this.setDetectors(data.detectors);
        this.activeIssueCount = data.activeIssueCount;
        this.cyclesWithWarningCount = data.cyclesWithWarningCount;
        this.issueCount = data.issueCount;
        this.assetLifecycle = data.assetLifecycle;

        if (data.cycles) {
            this._cycles = data.cycles;
        }
        if (data.cameras) {
            this.setCameras(data.cameras);
        }
        if (data.state) {
            this.updateState(data.state);
        }
        if (data.userGovernance) {
            this.governanceInfo = new GovernanceInfo(
                data.userGovernance.id,
                data.userGovernance.userId,
                data.userGovernance.username
            );
        }
        this._isInitialized = true;
        this.emit("initialized");
    }

    private updateCondition(value: ConditionType) {
        if (value === this._condition) return;
        this._condition = value;
        this.emit("conditionChanged", value);
    }

    private updatePlannedGovernanceTime(plannedGovernanceTime?: NullableString) {
        if (plannedGovernanceTime === this.plannedGovernanceTime) return;
        this.plannedGovernanceTime = plannedGovernanceTime;
        this.emit("plannedGovernanceTimeChanged", { plannedGovernanceTime });
    }

    public get condition() {
        return this._condition;
    }

    public setCurrentCycleNum(num: number) {
        if (this._currentCycleNum === num) return;
        this._currentCycleNum = num;
        this.emit("activeProgramChanged", { value: num });
    }

    public get currentCycleNum() {
        return this._currentCycleNum;
    }

    public updateControlMode(code: ControlModeCode) {
        if (this.controlMode.code === code) return;
        this.controlMode = controlModeCodeDictionaryMap[code] ?? controlModeCodeDictionaryMap[ControlModeCode.Unknown];
        this.emit("controlModeChanged", { controlMode: code });
    }

    public updateStatus(statusCode: StatusCode) {
        if (this.status.code === statusCode) return;
        this.status = statusCodeDictionaryMap[statusCode] ?? statusCodeDictionaryMap[StatusCode.Unknown];
        this.emit("statusChanged", { status: statusCode });
    }

    public updateMalfunctions(malfunctions?: MalfunctionCodeDictionary[]) {
        this._malfunctions = malfunctions ?? [];
        this.emit("errorStatusChanged");
    }

    private updateAdaptiveModuleEnabled(value: boolean) {
        if (this._isAdaptiveModuleEnabled === value) return;
        this._isAdaptiveModuleEnabled = value;
        this.emit("adaptiveModuleEnabledChanged", { enabled: value });
    }

    private updateLocalAdaptiveEnabled(value: boolean) {
        if (this._isLocalAdaptiveEnabled === value) return;
        this._isLocalAdaptiveEnabled = value;
        this.emit("localAdaptiveEnabledChange", value);
    }

    /** @deprecated Делается через сервис */
    private async fetchAssetData() {
        this.assetDataShape = { ...this.assetDataShape, isFetching: true };
        this.emit("assetDataChanged");
        const data = await this.trafficLightService.getTrafficLightAssetData({ id: this.id });
        this.assetDataShape = { isFetching: false, data };
        this.emit("assetDataChanged");
    }
}
