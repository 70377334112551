import { makeAutoObservable } from "mobx";
import { BooleanProperty } from "lib";
import { BaseLayerController } from "map-controller";

export class LayerControl {
    private _isVisible: BooleanProperty;

    constructor(private _layer: BaseLayerController) {
        this._isVisible = new BooleanProperty(_layer.isVisible);
        makeAutoObservable(this);
    }

    public get isVisible() {
        return this._isVisible.value;
    }

    public onClick = () => {
        const value = !this._layer.isVisible;
        this._layer.setVisibility(value);
        this._isVisible.value = value;
    };
}
