import { CooGroup, Facility, State, Manage } from "api";
import { Common, CooGroupDomain, RouteDomain } from "app-domain";
import * as utils from "./coo-group-service.utils";
import * as mappers from "./coo-group-service.utils";
import {
    CreateCooGroupInput,
    EnableCooGroupCycleInput,
    GetCooGroupListOptions,
    UpdateCooGroupInput,
    ValidateCooGroupInput,
} from "./coo-group-service.types";
import { TrafficLightData } from "../traffic-lights/traffic-light-service.types";
import { CooGroupCycleMapper } from "../mappers";

export class CooGroupService {
    private cycleMapper = new CooGroupCycleMapper();

    constructor(
        private api: CooGroup.CooGroupAPI,
        private facilityApi: Facility.FacilityAPI,
        private stateAPI: State.StateAPI,
        private manageAPI: Manage.ManageAPI
    ) {}

    public async getCooGroupList(options?: GetCooGroupListOptions): Promise<Map<number, CooGroupDomain.CooGroup>> {
        const result = await this.api.getCooGroupList(options);
        return result.reduce((acc, item) => {
            acc.set(item.id, utils.cooGroupMapper(item));
            return acc;
        }, new Map());
    }

    public async getCooGroupById(id: number) {
        const cooGroup = await this.api.getCooGroupById(id);
        const cycles = await this.api.getCooGroupCycles(id);
        const result = utils.cooGroupMapper(cooGroup);
        result.cycles = this.cycleMapper.listFromAPI(cycles);
        return result;
    }

    public async getStateList() {
        const list = await this.stateAPI.geCooGroupStateList();
        return list.map(mappers.mapCooGroupState);
    }

    public async getCooGroupTrafficLights(params: {
        serverInfo: Common.ServerInfo;
        facilities: CooGroupDomain.Facility[];
        includeState?: boolean;
    }) {
        const ids = params.facilities.map((item) => item.facilityId ?? 0);
        const items = await this.facilityApi.getBatchedFacilities(ids, params.includeState ?? false);
        const result = items.filter(Boolean) as TrafficLightData[];

        return result.map((trafficLight) =>
            mappers.trafficLightMapper({
                trafficLight,
                serverInfo: params.serverInfo,
            })
        );
    }

    public async getCooGroupLogs(id: number, filter: CooGroup.Types.GetCooGroupLogsFilter) {
        const result = await this.api.getCooGroupLogs(id, filter);
        return result.items.map(mappers.cooGroupLogsMapper);
    }

    public async createCooGroup(data: CreateCooGroupInput) {
        const result = await this.api.postCooGroup(mappers.prepareCooGroup(data));
        if (Array.isArray(result)) return result;
        return utils.cooGroupMapper(result);
    }

    public async updateCooGroup(data: UpdateCooGroupInput) {
        const result = await this.api.updateCooGroup(mappers.prepareUpdateCooGroupData(data));
        if (Array.isArray(result)) return result;
        return utils.cooGroupMapper(result);
    }

    public enableCooGroupCycles(cooGroupId: number, cycles: EnableCooGroupCycleInput[]) {
        return Promise.all(cycles.map((cycle) => this.manageAPI.enableCooGroupCycle(cooGroupId, cycle)));
    }

    public enableCooGroup(cooGroupId: number, value: boolean) {
        return this.manageAPI.enableCooGroup(cooGroupId, value);
    }

    public validateCooGroup(data: ValidateCooGroupInput) {
        return this.api.postValidateCooGroup(mappers.prepareValidateCooGroup(data));
    }

    public postCooGroupFreeRoadSpeed(id: number) {
        return this.api.postCooGroupFreeRoadSpeed(id);
    }

    public deleteCooGroup(id: number) {
        return this.api.deleteCooGroup(id);
    }

    public async getEditTracking(id: number) {
        return this.api.getEditTracking(id);
    }

    public async putEditTracking(id: number) {
        return await this.api.putEditTracking(id);
    }

    public deleteEditTracking(id: number) {
        return this.api.deleteEditTracking(id);
    }

    public async getCooGroupRoute(points: RouteDomain.Point[]) {
        const coordinates = points.map(mappers.routePointMapper);
        const response = await this.api.getCooGroupRoute(coordinates);
        return mappers.cooGroupRouteMapper(response);
    }
}
