export class URLBuilder {
    private resultPath = "";

    public get path() {
        return this.resultPath;
    }

    public baseURL(url: string) {
        this.resultPath = url;
        return this;
    }

    public param(param?: string | number | null) {
        if (typeof param === "undefined" || param === null) return this;
        this.resultPath += `/${param}`;
        return this;
    }
}
