import {
    CooGroup,
    Facility,
    Geocode,
    DtmRouter,
    State,
    Warehouse,
    Manage,
    Cycle,
    YandexGeocode,
    Infrastructure,
    DashboardAPI,
    DetectorServiceAPI,
    DetectorAPI,
    DtmTraffic,
    TVCameraAPI,
    SpeedCameraAPI,
} from "./endpoints";

export const APIRegistry = {
    facilityAPI: new Facility.FacilityAPI(),
    stateAPI: new State.StateAPI(),
    cooGroupAPI: new CooGroup.CooGroupAPI(),
    geocodeAPI: new Geocode.GeocodeAPI(),
    dtmRouterAPI: new DtmRouter.DtmRouterAPI(),
    dtmTrafficAPI: new DtmTraffic.DtmTrafficAPI(),
    assetTrafficLightAPI: new Warehouse.AssetTrafficLight.AssetTrafficLightAPI(),
    manageAPI: new Manage.ManageAPI(),
    cycleAPI: new Cycle.CycleAPI(),
    yandexGeocodeAPI: new YandexGeocode.YandexGeocodeAPI(),
    dashboardAPI: new DashboardAPI(),
    detectorServiceAPI: new DetectorServiceAPI(),
    detectorAPI: new DetectorAPI(),
    infrastructureAPI: new Infrastructure.InfrastructureAPI(),
    tvCameraAPI: new TVCameraAPI(),
    speedCameraAPI: new SpeedCameraAPI(),
};
