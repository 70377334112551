import { reaction } from "mobx";
import { LayerKey } from "map-controller";
import { Pages, AppRouter } from "app/routing";
import { CooGroupStore } from "../../store/coo-group";
import { LayerBinding, MapBindingDeps } from "./layer-binding";

export type CooGroupsBindingDeps = MapBindingDeps & {
    cooGroups: CooGroupStore;
};

export class CooGroupsLayerBinding extends LayerBinding {
    _cooGroups: CooGroupStore;

    constructor(deps: CooGroupsBindingDeps) {
        super(deps);
        this._cooGroups = deps.cooGroups;
    }

    public bind(): void {
        const layer = this.mapController.getLayer(LayerKey.CooGroups);
        this.addReaction(
            reaction(
                () => this._cooGroups.filteredList,
                (cooGroups) => layer.setData(cooGroups),
                { fireImmediately: true }
            )
        );

        this.addReaction(
            reaction(
                () => AppRouter.getCurrentRoute(),
                (route) => {
                    if (
                        (route.name === Pages.CooGroupList ||
                            route.name === Pages.CooGroupEdit ||
                            route.name === Pages.CooGroupView) &&
                        route.params?.id
                    ) {
                        const id = Number(route.params.id);
                        this.mapController.setActive(id, LayerKey.CooGroups);
                        this.mapController.flyToEntity(id, LayerKey.CooGroups);
                    }
                },
                { fireImmediately: true }
            )
        );
    }
}
