import { makeAutoObservable } from "mobx";
import { CooGroup } from "app-domain/coo-group";
import { TrafficLight } from "app-domain/traffic-light";
import { EntityType, Scenario } from "app-domain/scenario";
import { CooGroupStore, TrafficLightStore } from "app/store";

export type ScenarioViewModelDeps = {
    cooGroupStore: CooGroupStore;
    scenarioSelected: Scenario | null;
    trafficlightStore: TrafficLightStore;
};

export class ScenarioViewModel {
    public selectedScenario: Scenario | null;

    private _cooGroupMap: Map<number, CooGroup> = new Map();
    private _trafficlightsMap: Map<number, TrafficLight> = new Map();
    private trafficlightStore: TrafficLightStore;
    private cooGroupStore: CooGroupStore;

    constructor(deps: ScenarioViewModelDeps) {
        this.selectedScenario = deps.scenarioSelected;
        this.trafficlightStore = deps.trafficlightStore;
        this.cooGroupStore = deps.cooGroupStore;

        this.updateObjects(deps.scenarioSelected?.actions || []);
        makeAutoObservable(this);
    }

    public get cooGroupMap() {
        return this._cooGroupMap;
    }

    public get trafficlightsMap() {
        return Array.from(this._trafficlightsMap.values());
    }

    public getTrafficlightById = (id: NullableNumber) => {
        return typeof id === "number" ? this._trafficlightsMap.get(id) : undefined;
    };

    public getCooGroupById = (id: NullableNumber) => {
        return typeof id === "number" ? this._cooGroupMap.get(id) : undefined;
    };

    public getObjectByType = (id: NullableNumber, type?: EntityType) => {
        if (type === EntityType.TrafficLight) return this.getTrafficlightById(id);
        if (type === EntityType.CooGroup) return this.getCooGroupById(id);
        return undefined;
    };

    public getCycleTrafficlightById = (trafficlightId: NullableNumber, cycleId: NullableNumber) => {
        return this.getTrafficlightById(trafficlightId)?.cycles.find((cycle) => cycle.number === cycleId);
    };

    public loadEvent = (id: number, type?: EntityType) => {
        if (type === EntityType.TrafficLight) this.loadTrafficlight(id);
        else if (type === EntityType.CooGroup) this.loadCooGroup(id);
    };

    public loadTrafficlight = async (id: number) => {
        if (this._trafficlightsMap.get(id)) return;
        const trafficlight = (await this.trafficlightStore?.loadById(id)) || undefined;
        trafficlight && this._trafficlightsMap.set(id, trafficlight);
    };

    public loadCooGroup = async (id: number) => {
        if (this._cooGroupMap.get(id)) return;
        const cooGroup = (await this.cooGroupStore?.loadDetailsById(id)) || undefined;
        cooGroup && this._cooGroupMap.set(id, cooGroup);
        cooGroup.facilities.forEach((facility) => facility.facilityId && this.loadTrafficlight(facility.facilityId));
    };

    public updateObjects = (newActions: Scenario["actions"]) => {
        newActions.forEach((action) => action.id && this.loadEvent(action.id, action.type));
    };
}
