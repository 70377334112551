import { useAppState } from "../../store/hooks";
import { MapViewModelDeps } from "./map-view-model";

export const useGetDeps = (): MapViewModelDeps => {
    const state = useAppState();
    return {
        mapController: state.mapController,
        trafficLights: state.trafficLightStore,
        windowManager: state.appWindows,
        cooGroups: state.cooGroupStore,
        detectors: state.detectors,
        viewState: state.viewState,
        cooGroupEditor: state.cooGroupEditor,
        tvCameras: state.tvCameras,
        speedCameras: state.speedCameras,
    };
};
