import { APIRegistry, State } from "api";
import { TrafficLightDomain } from "app-domain";
import { Factories } from "shared";
import { ISubscriberService } from "./traffic-light-stream-subscriber.types";

export class Service implements ISubscriberService {
    private stateApi: State.StateAPI = APIRegistry.stateAPI;
    private factory = new Factories.TrafficLightGovernanceFactory();

    public async getGovernanceById(id: number) {
        const data = await this.stateApi.getFacilityGovernanceById(id);
        if (!data) return null;
        return this.factory.create(data as TrafficLightDomain.GovernanceData);
    }

    public async getTrafficLightsStates(): Promise<TrafficLightDomain.TrafficLightState[]> {
        // @ts-ignore
        return this.stateApi.getFacilitiesStates();
    }

    public async getTrafficLightGovernanceList(id: number): Promise<TrafficLightDomain.Governance[]> {
        const list = await this.stateApi.getFacilityGovernanceList(id);
        return list.map((i) => this.factory.create(i as TrafficLightDomain.GovernanceData));
    }
}
