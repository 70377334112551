import { ControlSource } from "app-domain/traffic-light";
import { makeAutoObservable, runInAction } from "mobx";
import { WindowInstance } from "shared/window-manager";
import { TrafficLightDomain } from "app-domain";
import { TrafficLightStore } from "../traffic-light-store";
import { TrafficLightRemoteControl, TrafficLightRemoteControlAdapter } from "./traffic-light-remote-control";
import { ITrafficLightRemoteControlStoreService } from "./traffic-light-remote-controls.types";

type StopControlsResult = {
    promises: Promise<void>[];
    ids: number[];
};

interface WindowService {
    spawnTrafficLightRemoteControl(id: number, options: { isCollapsed: boolean }): WindowInstance;
}

/** Хранилище пультов управления СО текущего пользователя */
export class TrafficLightRemoteControls {
    private items: Map<number, TrafficLightRemoteControl> = new Map();

    constructor(
        private store: TrafficLightStore,
        private service: ITrafficLightRemoteControlStoreService,
        private windowService: WindowService,
        private currentUserId: string
    ) {
        makeAutoObservable(this);
    }

    public get remoteControlsList() {
        return Array.from(this.items.values()).filter((item) => !item.isPlanned);
    }

    public get allExpandedControls() {
        return Array.from(this.items.values()).filter((control) => !control.isCollapsed);
    }

    public get plannedControlsList() {
        return Array.from(this.items.values()).filter((item) => item.isPlanned);
    }

    /** Свернутые пульты, относящиеся к текущему пользователю, кроме планированных */
    public get currentUserCollapsedNotPlannedControls(): TrafficLightRemoteControl[] {
        return Array.from(this.items.values()).filter(
            (control) => control.isCollapsed && control.isControlledByCurrentUser && !control.isPlanned
        );
    }

    /** Загружает пульты управления СО с запущенными отложенными командами  */
    public loadPlannedControls = async () => {
        const plannedGovernanceList = await this.service.getPlannedControls();
        runInAction(() => {
            for (const plannedGovernance of plannedGovernanceList) {
                const control = this.createRemoteControl(plannedGovernance, true);
                this.items.delete(control.id);
                this.items.set(control.id, control);
            }
        });
    };

    public async loadItems() {
        const governanceList = await this.service.getCurrentUserControls();
        runInAction(() => {
            for (const governance of governanceList) {
                const control = this.createRemoteControl(governance, true);
                this.items.set(control.id, control);
            }
        });
    }

    public async loadById(controlId: number) {
        const governance = await this.service.getRemoteControlData(controlId);

        if (!governance) return;

        const existingControl = this.items.get(controlId);
        const control = existingControl ?? this.createRemoteControl(governance, true);

        if (existingControl && governance.data?.controlSource !== ControlSource.CooGroup) {
            existingControl.updateGovernance(governance);
        }

        runInAction(() => {
            this.items.set(control.id, control);
        });

        return control;
    }

    public getById(remoteControlId: number) {
        return this.items.get(remoteControlId);
    }

    public removeRemoteControl(controlId: number) {
        const control = this.getById(controlId);
        if (!control) return;
        return this.removeControlFromStore(control);
    }

    public removeTrafficLightRemoteControl(trafficLightId: number) {
        const control = this.remoteControlsList.find((control) => control.trafficLightId === trafficLightId);
        if (!control) return;
        return this.removeControlFromStore(control);
    }

    /** Создает пульт управления и берет его под контроль текущего пользователя */
    public startRemoteControl = async (trafficLightId: number) => {
        try {
            const governance = await this.service.startRemoteControl(trafficLightId);
            const trafficLight = this.store.getById(trafficLightId);

            if (!trafficLight) return;
            trafficLight.governanceInfo = new TrafficLightDomain.GovernanceInfo(
                governance.id,
                governance.data?.userId,
                governance.data?.username
            );

            runInAction(() => {
                const remoteControl = this.createRemoteControl(governance, false);
                this.items.delete(remoteControl.id);
                this.items.set(remoteControl.id, remoteControl);
                remoteControl.expand();
            });
        } catch (e) {}
    };

    /** Перехват пульта под текущего пользователя */
    public takeOverControl(controlId: number) {
        const control = this.getById(controlId);
        if (!control) return;
        return this.startRemoteControl(control.trafficLightId);
    }

    public updateRemoteControlByTrafficLightId = async (trafficLightId: number) => {
        const governanceList = await this.service.getTrafficLightGovernanceList(trafficLightId);
        const userGovernance = governanceList.find(
            (item) => item.data?.controlSource === TrafficLightDomain.Enums.ControlSource.User
        );

        if (!userGovernance) return;

        const existingControl = this.items.get(userGovernance.facilityId);

        if (existingControl) {
            existingControl.updateGovernance(userGovernance);
        }
    };

    /** Останавливает контроль пользователя над пультом и удаляет его */
    public stopRemoteControl = async (id: number) => {
        const remoteControl = this.getById(id);
        if (!remoteControl) return;
        await this.service.stopRemoteControl(remoteControl.trafficLightId);
        remoteControl.destroy();
        this.removeRemoteControl(remoteControl.id);
    };

    public stopAllRemoteControls = async () => {
        try {
            const { promises, ids } = this.remoteControlsList.reduce<StopControlsResult>(
                (result, control) => {
                    if (!control.runningCommand) {
                        result.ids.push(control.id);
                        result.promises.push(this.service.stopRemoteControl(control.trafficLightId));
                    }
                    return result;
                },
                { promises: [], ids: [] }
            );
            await Promise.all(promises);
            runInAction(() => {
                for (const id of ids) {
                    const remoteControl = this.getById(id);
                    if (!!remoteControl) {
                        remoteControl!!.destroy();
                        this.removeRemoteControl(remoteControl.id);
                    }
                }
            });
        } catch (e) {}
    };

    private removeControlFromStore(control: TrafficLightRemoteControl) {
        // TODO придумать способ отказаться от использования здесь сторы светофоров
        // в данном случае это нужно для того, чтобы при открытой карточке СО не происходил перезапрос Закрытого пульта
        const trafficLight = this.store.getById(control?.trafficLightId);
        if (trafficLight) trafficLight.governanceInfo = null;
        control.destroy();
        this.items.delete(control.id);
    }

    private onRunCommand = (control: TrafficLightRemoteControl, isPlanned: boolean) => {
        if (!isPlanned) return;
        this.store.loadGovernancePlanned(control.id);
    };

    private createRemoteControl(governance: TrafficLightDomain.Governance, isCollapsed: boolean) {
        const service = new TrafficLightRemoteControlAdapter();
        const id = governance.id;
        const window = this.windowService.spawnTrafficLightRemoteControl(id, { isCollapsed });
        return new TrafficLightRemoteControl(id, governance, this.currentUserId, service, window, this.onRunCommand);
    }
}
