import { makeAutoObservable, reaction, toJS } from "mobx";
import { BooleanProperty } from "lib";
import {
    Phase,
    INewCustomCycle,
    CustomCycle,
    GovernanceCommandType,
    TrafficLight,
    CyclePhase,
    CycleType,
} from "app-domain/traffic-light";
import { TrafficLightRemoteControl } from "../../../store/traffic-light-remote-controls";
import { Popup, PopupType } from "../popup";
import { ChangeHistory } from "./change-history";
import { ChangeHistoryStore } from "./remote-control-editor-change-history-store";
import { EditorData, CycleData } from "./cycle-editor-view-model.types";

export class CycleEditorViewModel {
    /** @observable */
    public activePhaseNumber: number | null = null;
    public remoteControl?: TrafficLightRemoteControl;
    private _trafficLight: Nullable<TrafficLight> = null;
    private cycles: CustomCycle[] = [];
    private history = new ChangeHistory<EditorData>();
    private _editorData: Nullable<EditorData> = null;
    private popup: Popup;
    private areDirectionsVisible = new BooleanProperty(false);
    private runningCommandEffect?: VoidFunction;

    constructor(popup: Popup, public onStartCycle: VoidFunction) {
        this.popup = popup;
        makeAutoObservable(this);
    }

    public get isLoading() {
        return (this.activeCycle && !this.activeCycle.isApplied) ?? false;
    }

    public get trafficLight() {
        return this._trafficLight;
    }

    public set trafficLight(traffficLight: TrafficLight | null) {
        this.trafficLight?.off("cyclesChanged", this.handleTrafficLightCyclesChange);
        this._trafficLight = traffficLight;
        this.cycles = traffficLight?.cycles ?? [];
        this._trafficLight?.on("cyclesChanged", this.handleTrafficLightCyclesChange);
    }

    public get activeCycle() {
        if (this.remoteControl?.runningCommand?.type !== GovernanceCommandType.HoldCycle) return null;
        const { cycleId, cycleSource, isApplied } = this.remoteControl.runningCommand;
        return {
            cycleId,
            cycleSource,
            isApplied,
        };
    }

    public get editorData() {
        return this._editorData;
    }

    private set editorData(value: EditorData | null) {
        this._editorData = value;
    }

    public get selectedCycleId() {
        return this.editorData?.baseCycle?.id ?? null;
    }

    public get cycleOptions() {
        const cycles = this.cycles.reduce<{ id: number; name: string }[]>((result, cycle) => {
            if (
                (cycle.type === CycleType.Basic || cycle.type === CycleType.Reserve) &&
                typeof cycle.name === "string"
            ) {
                result.push({
                    id: cycle.id,
                    name: cycle.name,
                });
            }
            return result;
        }, []);
        return cycles ?? [];
    }

    public get time() {
        return this.editorData?.modifiedCycle.time ?? 0;
    }

    public get isExpanded() {
        return this.areDirectionsVisible.value;
    }

    public get isCycleEditingDisabled() {
        return this.isRunningCommand;
    }

    public get isCycleSelectDisabled() {
        return this.isRunningCommand;
    }

    public get arePhaseCountsEqual() {
        return this.editorData?.baseCycle.phases.length === this._editorData?.modifiedCycle.phases.length;
    }

    public get isAddPhaseDisabled() {
        return (
            this.isRunningCommand ||
            !this.editorData?.modifiedCycle ||
            this.editorData.modifiedCycle.phases.length === this.trafficLight?.phases.filter(Boolean).length
        );
    }

    public get isCycleTimeDisabled() {
        return !this.editorData?.modifiedCycle || this.isRunningCommand;
    }

    public get isDeleteBtnDisabled() {
        return this.activePhaseNumber === null || this.isRunningCommand;
    }

    public get isCancelChangeDisabled() {
        return !this.history.hasPrevState || this.isRunningCommand;
    }

    public get isRepeatChangeDisabled() {
        return !this.history.hasNextState || this.isRunningCommand;
    }

    public get isResetDisabled() {
        return (!this.hasChanges && !this.history.hasNextState && !this.history.hasPrevState) || this.isRunningCommand;
    }

    public get isSaveDisabled() {
        return !this.hasChanges || this.isRunningCommand;
    }

    private get hasChanges() {
        return !this.arePhaseCountsEqual || this.checkCycleTimeChanges() || this.checkPhaseChanges();
    }

    private get isRunningCommand() {
        return !!this.remoteControl?.runningCommand;
    }

    public init() {
        if (!this.remoteControl) return;
        const existingHistory = ChangeHistoryStore.read(this.remoteControl.id);
        if (existingHistory) {
            this.history = existingHistory;
            this.editorData = existingHistory.state;
        }
        this.runningCommandEffect = reaction(() => this.remoteControl?.runningCommand, this.handleRunningCommand, {
            fireImmediately: true,
        });
    }

    public destroy() {
        if (!this.remoteControl) return;
        this.runningCommandEffect?.();
        ChangeHistoryStore.save(this.remoteControl?.id, this.history);
    }

    public setActivePhaseNumber = (value: number) => {
        this.activePhaseNumber = value;
    };

    public clearActivePhase = () => {
        this.activePhaseNumber = null;
    };

    public onCancelChange = () => {
        if (!this.history.hasPrevState) return;
        this.history.setPrevState();
        this.setEditorDataFromHistoryState();
    };

    public onRepeatChange = () => {
        if (!this.history.hasNextState) return;
        this.history.setNextState();
        this.setEditorDataFromHistoryState();
    };

    public onAddPhaseToCycle = () => {
        if (!this.editorData) return;
        this.popup.setParams({
            type: PopupType.AddPhase,
            data: this.editorData.modifiedCycle.phases,
            onSubmit: this.addPhaseToCycle,
        });
        this.popup.open();
    };

    public onRemoveActivePhase = () => {
        const phaseNumber = this.activePhaseNumber;
        if (phaseNumber === null) return;
        this.popup.setParams({
            type: PopupType.RemovePhase,
            onClose: () => {
                this.clearActivePhase();
                this.popup.close();
            },
            onSubmit: () => {
                this.removePhase(phaseNumber);
                this.popup.close();
            },
        });
        this.popup.open();
    };

    public onSelectCycle(cycle: CustomCycle | null) {
        if (this.hasChanges) {
            this.popup.setParams({
                type: PopupType.CycleChangeConfirmation,
                onSubmit: () => {
                    this.selectCycle(cycle);
                    this.popup.close();
                },
            });
            return this.popup.open();
        }
        this.selectCycle(cycle);
    }

    public getCurrentCycle(): INewCustomCycle | null {
        if (!this.editorData) return null;
        const { baseCycle } = this.editorData;
        return {
            id: baseCycle.id ?? 0,
            name: baseCycle.name,
            source: baseCycle.source || 0,
            userDisplayName: baseCycle.userDisplayName,
            userId: baseCycle.userId,
            time: this.time,
            phases: (this.editorData ? toJS(this.editorData.modifiedCycle.phases) : baseCycle.phases) ?? [],
        };
    }

    public onCycleTimeChange(value: number) {
        if (!this.editorData) return;
        this.updateCycleData({
            time: value,
        });
    }

    public updateCycleData = (data: Partial<CycleData>) => {
        if (!this.editorData) return;
        this.editorData = {
            baseCycle: this.editorData.baseCycle,
            modifiedCycle: {
                ...this.editorData.modifiedCycle,
                ...data,
            },
        };
        this.history.pushState(this.editorData);
    };

    public onReset = () => {
        if (!this.editorData) return;
        this.editorData = {
            baseCycle: this.editorData.baseCycle,
            modifiedCycle: {
                time: this.editorData.baseCycle.time,
                phases: this.editorData.baseCycle.phases.slice(),
            },
        };
        this.history.clear();
        this.history.pushState(this.editorData);
    };

    public onSaveCycleAs = () => {
        const data = this.getCurrentCycle();
        if (!data) return;
        this.popup.setParams({ type: PopupType.SaveCycleAs, data, onSubmit: this.setSavedCycle });
        this.popup.open();
    };

    public onToggleExpansion = () => {
        return this.areDirectionsVisible.toggle();
    };

    private selectCycle = (cycle: Nullable<CustomCycle>) => {
        this.history.clear();
        if (cycle === null) return (this.editorData = null);
        const phases = cycle.phases ?? [];
        const time = cycle.time;
        this.editorData = {
            baseCycle: {
                id: cycle.id,
                time,
                phases: cycle.phases || phases,
                name: cycle.name,
                userDisplayName: cycle.userDisplayName,
                userId: cycle.userId,
                source: cycle.source,
            },
            modifiedCycle: {
                time,
                phases,
            },
        };
        this.history.pushState(this.editorData);
    };

    private removePhase(phaseNumber: number) {
        if (!this.editorData) return;
        const { modifiedCycle } = this.editorData;
        let updatedCycle = modifiedCycle.phases.reduce(
            (result, phase) => {
                if (phase.phaseNumber === phaseNumber) {
                    result.time -= phase.tPhase;
                    return result;
                }
                result.phases.push(phase);
                return result;
            },
            { time: modifiedCycle.time, phases: [] } as CycleData
        );
        this.updateCycleData(updatedCycle);
    }

    private setSavedCycle = (cycle: CustomCycle) => {
        this.onSelectCycle(cycle);
        this.popup.close();
    };

    private addPhaseToCycle = (phase: Phase) => {
        if (!this.editorData) return;
        const newCyclePhase = {
            phaseNumber: phase.number,
            tBasic: phase.tBasic,
            tMin: phase.tMin,
            tPhase: phase.tPhase,
            tProm: phase.tProm,
        };
        this.updateCycleData({
            time: this.editorData.modifiedCycle.time + newCyclePhase.tPhase,
            phases: [...this.editorData.modifiedCycle.phases, newCyclePhase],
        });
        this.popup.close();
    };

    private setEditorDataFromHistoryState() {
        const state = this.history.state;
        if (!state) return;
        this.editorData = {
            baseCycle: state.baseCycle,
            modifiedCycle: state.modifiedCycle,
        };
    }

    private checkCycleTimeChanges() {
        if (!this.editorData) return false;
        return this.editorData.baseCycle.time !== this.editorData.modifiedCycle.time;
    }

    private checkPhaseChanges() {
        if (!this.editorData) return false;
        const { modifiedCycle, baseCycle } = this.editorData;
        return baseCycle.phases.some((basePhase, index) => {
            const modifiedPhase = modifiedCycle.phases[index];
            if (!modifiedPhase) return true;
            return modifiedPhase.tPhase !== basePhase.tPhase || modifiedPhase.phaseNumber !== basePhase.phaseNumber;
        });
    }

    /**
     * Обработчик реации изменения команды в пульте
     * Если был запущен временный цикл, то отобразятся фазы, сохраненные в пульте
     * Иначе должен выбираться текущий активный цикл светофора, если нет истории изменений
     */
    private handleRunningCommand = (command?: TrafficLightRemoteControl["runningCommand"]) => {
        if (command?.type === GovernanceCommandType.HoldCycle) {
            const cycle = this.trafficLight?.cycles.find((cycle) => cycle.id === command.cycleId);
            const basePhases =
                cycle?.phases?.reduce((result, phase) => {
                    if (!phase) return result;
                    result[phase.phaseNumber] = phase;
                    return result;
                }, {} as Record<number, CyclePhase>) ?? {};
            if (cycle) {
                const { phases } = command;
                let time = 0;
                const newPhases = phases.reduce((res, phase) => {
                    const basePhase = basePhases[phase.number];
                    time += phase.tPhase;
                    res.push({
                        phaseNumber: phase.number,
                        tPhase: phase.tPhase,
                        tProm: phase.tProm,
                        tBasic: phase.tPhase - phase.tProm,
                        tMin: basePhase.tMin ?? 0,
                    });
                    return res;
                }, [] as CyclePhase[]);
                this.editorData = {
                    baseCycle: {
                        ...cycle,
                        phases: cycle.phases?.filter(Boolean) ?? [],
                    },
                    modifiedCycle: {
                        phases: newPhases,
                        time,
                    },
                };
                // Если у нас нет предыдущего состояния, то пушим новое(нужно после обновления страницы)
                if (!this.history.state) this.history.pushState(this.editorData);
                return;
            }
        }
        if (this.history.state) return;
        this.selectCycle(this.trafficLight?.getActiveCycle() ?? null);
    };

    private handleTrafficLightCyclesChange = () => {
        this.cycles = this._trafficLight?.cycles ?? [];
    };
}
