import { StateStreamData } from "app-domain/scenario";
import { MessageStreamHub, Types } from "../message-hub";
import { REACT_APP_API_BASEURL } from "env-data";

const StreamName = "StoryplayStateShort";

const URL = `${REACT_APP_API_BASEURL}/hub/notification`;

export type ScenarioStreamSubscriber = Types.Subscriber<StateStreamData & { id?: number }>;

export class ScenarioStream {
    private hub = new MessageStreamHub<typeof StreamName>(URL, false);
    private subscribers: Record<number, ScenarioStreamSubscriber[]> = {};

    public async init() {
        await this.hub.init();
    }

    public async destroy() {
        await this.hub.destroy();
        this.subscribers = {};
    }

    public subscribeToScenario(scenarioIds: number[], subscriber: ScenarioStreamSubscriber) {
        if (!this.hub.getStream(StreamName)) this.hub.createStream(StreamName, scenarioIds);
        this.hub.subscribe(StreamName, subscriber);
        scenarioIds.forEach((scenarioId) => {
            if (!Array.isArray(this.subscribers[scenarioId])) return (this.subscribers[scenarioId] = [subscriber]);
            this.subscribers[scenarioId].push(subscriber);
        });
    }

    public unsubscribeFromScenario(scenarioIds: number[], subscriber: ScenarioStreamSubscriber) {
        this.hub.unsubscribe(StreamName, subscriber);
        scenarioIds.forEach((scenarioId) => {
            this.subscribers[scenarioId] = this.subscribers[scenarioId]?.filter((sub) => sub === subscriber);
        });
    }
}
