import mapboxgl from "mapbox-gl";
import { CooGroupDomain } from "app-domain";
import {
    FullRouteLayer,
    RouteArrowLayer,
    RouteBorderLayer,
    RouteLayer,
    RouteStrokeLayer,
    RouteActiveStrokeLayer,
} from "./layers";
import { getGeoJsonGeometry } from "./coo-group-layer.utils";
import { Options } from "./coo-group-layer.types";
import { CooGroupVisual } from "./coo-group";
import { source, data } from "./coo-group-layer.constants";

export class CooGroupLayer {
    public id: string;
    public isVisible: boolean = true;
    public interactive: boolean = true;
    public type: "custom" = "custom";
    private cooGroupsList: CooGroupVisual[] = [];
    private activeGroupId: NullableNumber = null;
    private data: GeoJSON.FeatureCollection<GeoJSON.Geometry> = data;

    private readonly sourceId: string;
    private readonly activeStrokeLayerId: string;
    private readonly routeActiveLayerId: string;
    private readonly routeLayerId: string;
    private readonly routeBorderLayerId: string;
    private readonly strokeLayerId: string;
    private readonly arrowLayerId: string;
    private readonly beforeId: string;

    constructor(private map: mapboxgl.Map, options: Options) {
        this.id = options.id;
        this.beforeId = options.beforeId;
        this.sourceId = `${this.id}_source`;
        this.routeLayerId = this.id;
        this.routeBorderLayerId = `${this.id}_route-border`;
        this.activeStrokeLayerId = `${this.id}_active-stroke`;
        this.strokeLayerId = `${this.id}_stroke`;
        this.arrowLayerId = `${this.id}_arrow`;
        this.routeActiveLayerId = `${this.id}_route-active`;
        this.addSource();
        this.addLayers();
    }

    public removeLayers() {
        this.map.removeLayer(this.routeActiveLayerId);
        this.map.removeLayer(this.activeStrokeLayerId);
        this.map.removeLayer(this.routeLayerId);
        this.map.removeLayer(this.routeBorderLayerId);
        this.map.removeLayer(this.strokeLayerId);
        this.map.removeLayer(this.arrowLayerId);
        this.map.removeLayer(this.id);
    }

    public setActive = (id: NullableNumber) => {
        if (id === null) return this.resetActive();
        if (this.activeGroupId !== null) {
            this.map.setFeatureState({ source: this.sourceId, id: this.activeGroupId }, { isActive: false });
        }

        this.map.setFeatureState({ source: this.sourceId, id }, { isActive: true });

        this.activeGroupId = id;
    };

    public getItem(id: number) {
        return this.cooGroupsList.find((item) => item.cooGroup.id === id)?.cooGroup;
    }

    public resetActive() {
        if (this.activeGroupId !== null) {
            this.map.setFeatureState({ source: this.sourceId, id: this.activeGroupId }, { isActive: false });
        }
        this.activeGroupId = null;
    }

    public setVisibility = (isVisible: boolean) => {
        this.isVisible = isVisible;
        this.cooGroupsList.forEach((cooGroup) => cooGroup.setFeatureState(isVisible, this.activeGroupId));
    };

    public readonly destroy = () => {
        this.removeLayers();
    };

    public setCooGroups = (cooGroups: CooGroupDomain.CooGroup[]) => {
        if (this.cooGroupsList.length) {
            this.cooGroupsList.forEach((cooGroup) => cooGroup.destroy());
        }
        this.cooGroupsList = cooGroups.map(
            (cooGroup) => new CooGroupVisual(this.map, { cooGroup, sourceId: this.sourceId, isVisible: this.isVisible })
        );

        this.setData(getGeoJsonGeometry(cooGroups));

        if (this.activeGroupId) {
            this.map.setFeatureState({ source: this.sourceId, id: this.activeGroupId }, { isActive: true });
        }
    };

    public reAttachLayers = () => {
        this.addSource();
        this.setData(this.data);
        this.addLayers();
        this.cooGroupsList.forEach((cooGroup) => {
            cooGroup.setFeatureState(this.isVisible, this.activeGroupId);
        });
        if (this.activeGroupId) {
            this.setActive(this.activeGroupId);
        }
    };

    private setData = (data: GeoJSON.FeatureCollection<GeoJSON.Geometry>) => {
        this.data = data;
        const source = this.map.getSource(this.sourceId) as mapboxgl.GeoJSONSource | undefined;
        source?.setData(data);
    };

    private checkSource = () => {
        return this.map.getSource(this.sourceId);
    };

    private addSource = () => {
        if (this.checkSource()) return;
        this.map.addSource(this.sourceId, source);
    };

    private addLayers = () => {
        if (!this.map.getLayer(this.routeActiveLayerId)) {
            this.map.addLayer(
                new FullRouteLayer({
                    id: this.routeActiveLayerId,
                    source: this.sourceId,
                }),
                this.beforeId
            );
        }
        if (!this.map.getLayer(this.activeStrokeLayerId)) {
            this.map.addLayer(
                new RouteActiveStrokeLayer({
                    id: this.activeStrokeLayerId,
                    source: this.sourceId,
                }),
                this.beforeId
            );
        }
        if (!this.map.getLayer(this.routeLayerId)) {
            this.map.addLayer(
                new RouteLayer({
                    id: this.routeLayerId,
                    source: this.sourceId,
                }),
                this.beforeId
            );
        }
        if (!this.map.getLayer(this.routeBorderLayerId)) {
            this.map.addLayer(
                new RouteBorderLayer({
                    id: this.routeBorderLayerId,
                    source: this.sourceId,
                }),
                this.beforeId
            );
        }
        if (!this.map.getLayer(this.strokeLayerId)) {
            this.map.addLayer(
                new RouteStrokeLayer({
                    id: this.strokeLayerId,
                    source: this.sourceId,
                }),
                this.beforeId
            );
        }
        if (!this.map.getLayer(this.arrowLayerId)) {
            this.map.addLayer(
                new RouteArrowLayer({
                    id: this.arrowLayerId,
                    source: this.sourceId,
                }),
                this.beforeId
            );
        }
    };
}
