import { makeAutoObservable } from "mobx";
import { BooleanProperty } from "lib";
import { CyclePhase, CustomCycle, CycleSource, CycleType } from "app-domain/traffic-light";
import { ChangeHistory } from "../scenario-edit-view-model/change-history";

type CycleData = {
    time: number;
    phases: CyclePhase[];
};

type BaseCycleData = CycleData &
    Partial<{
        id: number;
        name: string;
        userId: string;
        source: CycleSource;
        userDisplayName: string;
        type?: CycleType;
    }>;

export type ViewData = BaseCycleData | null;

export class CycleEditorState {
    public viewData: Nullable<ViewData> = null;
    public areDirectionsVisible = new BooleanProperty(false);
    public _history = new ChangeHistory<ViewData>();

    constructor() {
        makeAutoObservable(this);
    }

    public get selectedCycleId() {
        return this.viewData?.id ?? null;
    }

    public onSelectCycle(cycle?: CustomCycle | null) {
        if (!cycle || cycle.time === 0) return;
        this._history.clear();
        const phases = cycle.phases ?? [];
        const time = cycle.time;
        this.viewData = {
            id: cycle.id,
            time,
            phases: cycle.phases || phases,
            name: cycle.name,
            userDisplayName: cycle.userDisplayName,
            userId: cycle.userId,
            source: cycle.source,
            type: cycle.type,
        };
        this._history.pushState(this.viewData);
    }
}
