import { CustomLayerController } from "../custom-layer-controller";
import { TrafficcLightDetectorLayer } from "./trafficlight-detector-layer";
import { DetectorLaneBusyData, DetectorStateData } from "./traffic-light-detector.types";

export class TrafficLightDetectorController extends CustomLayerController {
    public isLayerVisible = true;
    protected layer: TrafficcLightDetectorLayer = new TrafficcLightDetectorLayer();

    public updateDetectorState(data: DetectorStateData) {
        this.layer.updateDetectorState(data);
    }

    public updateDetectorLaneBusy(data: DetectorLaneBusyData) {
        this.layer.updateLaneBusy(data);
    }
}
