import { APIRegistry } from "api";
import { SpeedCamera } from "app-domain/speed-camera";
import { WarehouseEntitiesService } from "../common/warehouse-entities";
import { Mappers } from "shared";

export class SpeedCamerasAdapter implements WarehouseEntitiesService<SpeedCamera> {
    private _api = APIRegistry.speedCameraAPI;
    private _listMapper = new Mappers.SpeedCamerasListMapper();

    public async fetchList() {
        const cameraList = await this._api.getCameraList();
        let map = new Map();
        for (const camera of cameraList) {
            map.set(camera.id, this._listMapper.fromAPI(camera));
        }
        return map;
    }

    public async fetchItem(id: number): Promise<SpeedCamera> {
        const data = await this._api.getCamera(id);
        return this._listMapper.fromAPI(data);
    }
}
