import mapboxgl from "mapbox-gl";
import { GeoJSONFeatureCollection } from "./geojson-feature-collection";

export type CustomFeature<
    Id extends string | number = string | number,
    Geometry extends GeoJSON.Geometry = GeoJSON.Geometry,
    Props extends GeoJSON.GeoJsonProperties = GeoJSON.GeoJsonProperties
> = GeoJSON.Feature<Geometry, Props> & {
    id: Id;
};

type FeatureMap<Feature extends CustomFeature> = Record<string | number, Feature>;

export class CustomFeatureCollection<Feature extends CustomFeature> {
    private collection = new GeoJSONFeatureCollection<Feature>();
    private map?: mapboxgl.Map;

    constructor(private sourceId: string, map?: mapboxgl.Map) {
        this.map = map;
    }

    public get source() {
        return this.collection;
    }

    public get sourceData() {
        return this.collection.data;
    }

    public setMap(map: mapboxgl.Map) {
        this.map = map;
    }

    private featureMap: FeatureMap<Feature> = {};

    public setFeatures(features: Feature[]) {
        this.sourceData.features = features;
        this.featureMap = features.reduce((res, feature) => {
            if (!feature.id) return res;
            res[feature.id] = feature;
            return res;
        }, {} as FeatureMap<Feature>);
        this.updateSource();
    }

    public getFeature(id: number | string): Feature | null {
        return this.featureMap[id] ?? null;
    }

    public updateFeatureProps(id: number | string, props: Partial<Feature["properties"]>) {
        const feature = this.getFeature(id);
        if (!feature) return;
        feature.properties = { ...feature.properties, ...props };
        this.updateSource();
    }

    private updateSource() {
        const source = this.map?.getSource(this.sourceId) as mapboxgl.GeoJSONSource;
        if (!source) return;
        source.setData(this.sourceData);
    }
}
