import { Map, MapMouseEvent } from "mapbox-gl";
import { RouteDomain, CooGroupDomain } from "app-domain";
import { RouteLayer, TrafficlightsLayer, CooGroupLayer } from "./layers";
import { RoutePointsMarker } from "./markers";
import type { LngLat } from "./route-building-controller.types";
import { BaseLayerController } from "../layer-controller";
import { LayerEmitter, BaseEventKeys, BaseEvents } from "../layer-emitter";

const EventKeys = {
    ...BaseEventKeys,
    change: "change",
    addMarker: "addMarker",
    moveMarker: "moveMarker",
} as const;

type Events = BaseEvents & {
    change: (id: number) => void;
    addMarker: (point: LngLat) => void;
    moveMarker: (props: { id: number; lngLat: LngLat }) => void;
};

export class RouteBuildingController extends BaseLayerController<Events> {
    public setMarkersData!: Function;
    public isInteractive = false;
    public isVisible: boolean = true;
    private routePointsMarker?: RoutePointsMarker;
    private routeLayer?: RouteLayer;
    private trafficLightLayer?: TrafficlightsLayer;
    private cooGroupLayer?: CooGroupLayer;
    protected emitter = new LayerEmitter<Events>();

    public get events() {
        return EventKeys;
    }

    public setData = (data?: RouteDomain.MapRouteFeatures) => {
        this.routeLayer?.setData(data?.routeFeatures?.features ?? []);
        this.cooGroupLayer?.setData(CooGroupDomain.Utils.convertToLine(data));
        this.trafficLightLayer?.setData(data?.trafficLightFeatures?.features ?? []);
    };

    protected _setVisibility = async (isVisible: boolean) => {
        this.routeLayer?.setVisibility(isVisible);
        this.cooGroupLayer?.setVisibility(isVisible);
        this.trafficLightLayer?.setVisibility(isVisible);
        this.routePointsMarker?.setData([]);
    };

    protected _setInteractivity(value: boolean): void {
        if (value) {
            this.map.getCanvas().style.cursor = "crosshair";
        } else {
            this.map.getCanvas().style.cursor = "";
        }
        this.isInteractive = value;
    }

    protected async _onMapSet(map: Map): Promise<void> {
        this.routePointsMarker = new RoutePointsMarker(map);
        this.routeLayer = new RouteLayer(map);
        this.cooGroupLayer = new CooGroupLayer(map);
        this.trafficLightLayer = new TrafficlightsLayer(map);
        this.setMarkersData = this.routePointsMarker.setData;
        this._subscribe();
    }

    protected readonly _onBeforeDestroy = async () => {
        this.routeLayer?.destroy();
        this.cooGroupLayer?.destroy();
        this.trafficLightLayer?.destroy();
        this.routePointsMarker?.setData([]);
        this._unsubscribe();
    };

    private _addMarker = (lngLat: LngLat) => {
        this.emitter.emit(this.events.addMarker, lngLat);
    };

    private _handleClick = (event: MapMouseEvent) => {
        if (!this.isInteractive) return;
        this._addMarker(event.lngLat);
    };

    private _handleDragRoutePoint: Events["moveMarker"] = (props) => {
        this.emitter.emit(this.events.moveMarker, props);
    };

    private _subscribe() {
        this.routePointsMarker?.on(this.routePointsMarker?.events.dragend, this._handleDragRoutePoint);
        this.map.on("click", this._handleClick);
    }

    private _unsubscribe() {
        this.map.getCanvas().removeAttribute("style");
        this.routePointsMarker?.off(this.routePointsMarker?.events.dragend, (props) =>
            this.emitter.emit(this.events.moveMarker, props)
        );
        this.map.off("click", this._handleClick);
    }
}
