import { makeAutoObservable, observable, runInAction } from "mobx";
import { GovernanceCommandType, TrafficLight, Phase, StatusCode } from "app-domain/traffic-light";
import { AppRouter, Pages } from "app/routing";
import { BooleanProperty } from "lib";
import { TrafficLightStore } from "../../store/traffic-light-store";
import {
    TrafficLightRemoteControls,
    TrafficLightRemoteControl,
    TrafficLightRemoteControlRunCommandParams,
} from "../../store/traffic-light-remote-controls";
import { CycleEditorViewModel } from "./cycle-editor-view-model";
import { HoldingEntity } from "./traffic-light-remote-control-view-model.types";
import { Popup, PopupType } from "./popup";

type TrafficLightRemoteControlViewModelDeps = {
    remoteControlId: number;
    trafficLightStore: TrafficLightStore;
    remoteControlsStore: TrafficLightRemoteControls;
};

export class TrafficLightRemoteControlViewModel {
    public trafficLight: Nullable<TrafficLight> = null;
    public readonly popup = new Popup();
    public readonly cycleEditorVM: CycleEditorViewModel;
    private trafficLightStore: TrafficLightStore;
    private remoteControlId: number;
    private remoteControlsStore: TrafficLightRemoteControls;
    private remoteControl: Nullable<TrafficLightRemoteControl> = null;
    private _isLoading = new BooleanProperty(false);
    private _holdingEntity: HoldingEntity | null = null;

    constructor(deps: TrafficLightRemoteControlViewModelDeps) {
        this.trafficLightStore = deps.trafficLightStore;
        this.remoteControlId = deps.remoteControlId;
        this.remoteControlsStore = deps.remoteControlsStore;
        this.cycleEditorVM = new CycleEditorViewModel(this.popup, this.onStartCycle);
        makeAutoObservable<TrafficLightRemoteControlViewModel, "remoteControl" | "trafficLight">(this, {
            remoteControl: observable.ref,
            trafficLight: observable.ref,
        });
        this.init();
    }

    public get isLoading() {
        return this._isLoading.value;
    }

    public get holdingEntity() {
        return this._holdingEntity;
    }

    public get phases() {
        return this.trafficLight?.phases.filter(Boolean) ?? [];
    }

    public get isControlledByCurrentUser() {
        return this.remoteControl?.isControlledByCurrentUser ?? false;
    }

    public get remoteControlInfo() {
        return {
            createdAt: this.remoteControl?.createdAt,
            userName: this.remoteControl?.user.name,
            command: this.remoteControl?.runningCommand,
        };
    }

    public get areFinishExtendControlsVisible() {
        return !!this.remoteControl?.runningCommand;
    }

    public get arePhaseControlsDisabled() {
        return (
            (this.remoteControl?.runningCommand &&
                this.remoteControl.runningCommand.type !== GovernanceCommandType.HoldPhase) ||
            !this.remoteControl?.isControlledByCurrentUser
        );
    }

    public get areStatusControlsDisabled() {
        return (
            (this.remoteControl?.runningCommand &&
                this.remoteControl.runningCommand.type !== GovernanceCommandType.HoldStatus) ||
            !this.remoteControl?.isControlledByCurrentUser
        );
    }

    public get areCycleControlsDisabled() {
        if (!this.remoteControl?.runningCommand) return false;
        return this.remoteControl.runningCommand.type !== GovernanceCommandType.HoldCycle;
    }

    public get isCloseButtonDisabled() {
        return !!this.remoteControl?.runningCommand && this.remoteControl.isControlledByCurrentUser;
    }

    public get isExtendButtonDisabled() {
        return !this.remoteControl?.isControlledByCurrentUser;
    }

    public get isFinishButtonDisabled() {
        return !this.remoteControl?.runningCommand && !this.remoteControl?.isControlledByCurrentUser;
    }

    public get activePhase() {
        if (this.remoteControl?.runningCommand?.type !== GovernanceCommandType.HoldPhase) return null;
        return {
            number: this.remoteControl.runningCommand?.phase,
            isApplied: this.remoteControl.runningCommand.isApplied,
        };
    }

    public get activeStatus() {
        if (this.remoteControl?.runningCommand?.type !== GovernanceCommandType.HoldStatus) return null;
        const { status, isApplied } = this.remoteControl.runningCommand;
        return {
            code: status,
            isApplied,
        };
    }

    public get trafficLightNum() {
        return this.trafficLight?.num ?? null;
    }

    public get trafficLightAddress() {
        return this.trafficLight?.address ?? null;
    }

    public destroy() {
        this.cycleEditorVM.destroy();
    }

    public onStartPhase = (phase: Phase) => {
        this._holdingEntity = { type: "phase", data: phase.number };
        this.openCommandLaunchPopup();
    };

    public onStartStatus = (status: StatusCode) => {
        this._holdingEntity = { type: "status", data: status };
        this.openCommandLaunchPopup();
    };

    public onCollapseClick = () => {
        this.remoteControl?.collapse();
    };

    public onCloseClick = () => {
        if (!this.remoteControl) return;
        if (!this.remoteControl.isControlledByCurrentUser) return this.remoteControl.collapse();
        this.remoteControlsStore.stopRemoteControl(this.remoteControl?.id);
    };

    public onNavigateClick = () => {
        if (!this.trafficLight) return;
        AppRouter.navigate(Pages.TrafficLightDetails, { id: this.trafficLight?.id.toString() });
    };

    public onFinishCommand = () => {
        this.remoteControl?.finishCommand();
    };

    public onExtendCommand = () => {
        this.remoteControl?.extendCommandRuntime();
    };

    private async init() {
        await this.loadData();
    }

    private onStartCycle = () => {
        const data = this.cycleEditorVM.getCurrentCycle();
        if (!data) return;
        this._holdingEntity = { type: "cycle", data: data.phases, cycleId: data.id, cycleSource: data.source };
        this.openCommandLaunchPopup();
    };

    private openCommandLaunchPopup() {
        this.popup.setParams({ type: PopupType.CommandLaunch, onSubmit: this.runHoldingCommand });
        this.popup.open();
    }

    private runHoldingCommand = async (params: TrafficLightRemoteControlRunCommandParams) => {
        if (!this._holdingEntity) return this.popup.close();
        if (this._holdingEntity.type === "phase")
            this.remoteControl?.holdPhase({ ...params, data: this._holdingEntity.data });
        else if (this._holdingEntity.type === "status")
            this.remoteControl?.holdStatus({ ...params, data: this._holdingEntity.data });
        else
            this.remoteControl?.holdCycle({
                ...params,
                data: this._holdingEntity.data,
                cycleId: this._holdingEntity.cycleId,
                cycleSource: this._holdingEntity.cycleSource,
            });
        this.popup.close();
        this.popup.clearParams();
    };

    private loadData = async () => {
        this._isLoading.setTruly();
        const remoteControl = await this.remoteControlsStore.loadById(this.remoteControlId);
        if (!remoteControl) return;
        this.remoteControl = remoteControl;
        const trafficLight = await this.trafficLightStore.loadById(remoteControl.trafficLightId);
        await this.trafficLightStore.loadTrafficLightCycles(remoteControl.trafficLightId);
        runInAction(() => {
            this.trafficLight = trafficLight;
            this.remoteControl = remoteControl;
            this.cycleEditorVM.remoteControl = remoteControl;
            this.cycleEditorVM.trafficLight = trafficLight!;
            this.cycleEditorVM.init();
            this._isLoading.setFalsy();
        });
    };
}
