import { CycleType } from "../enums";

export const getCycleText = (cycle: number | undefined) => {
    return cycle === CycleType.YellowBlink
        ? "ЖМ"
        : cycle === CycleType.AllRed
        ? "ВК"
        : cycle === CycleType.Disabled
        ? "ВЫКЛ"
        : "ВЫКЛ";
};
