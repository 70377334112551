import { useAppState } from "../../store/hooks";
import { TVCamerasListViewModelDeps } from "./tv-cameras-list-view-model";

export const useGetDeps = (): TVCamerasListViewModelDeps => {
    const state = useAppState();

    return {
        filter: state.tvCameras.filter,
        store: state.tvCameras,
        onFilterOpen: () => state.openFilter("tv-camera-list"),
        mapController: state.mapController,
        windowManager: state.appWindows,
    };
};
