import { SymbolLayer, SymbolLayout } from "mapbox-gl";
import { ILayout } from "../layer";

type Options = {
    id: string;
    imagePath: string;
};

export class WarehouseFeaturesLayer implements SymbolLayer {
    public id: string;
    public type: "symbol" = "symbol";
    public source: string;
    public interactive = true;
    public layout: ILayout<SymbolLayout> = {
        "icon-allow-overlap": true,
        "text-size": 23,
        "icon-offset": [0, 0],
        "icon-pitch-alignment": "map",
        "icon-size": ["interpolate", ["linear"], ["zoom"], 8, 0.5 * 0.1, 22, 0.5 * 1.5],
        "visibility": "visible",
    };
    constructor(options: Options) {
        this.id = options.id;
        this.source = options.id;
        this.layout["icon-image"] = `${options.imagePath}-{issueStatus}-{monitoringStatus}`;
    }
}
