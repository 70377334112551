import { Common } from "lib/canvas/drawing";
import { Visualizer } from "./visualizer";
import { VisualizationData, DirectionVisData } from "./visualizers.types";
import { getCycleColor, getCycleText } from "app-domain/traffic-light/utils";

export class StatusCycleVisualizer extends Visualizer {
    protected _visualize(data: VisualizationData): void {
        this.ctx.font = this.textFont;
        this.drawCycleBar();

        let y = this.topOffset + this.barHeight + this.directionsTopOffset;
        for (const direction of data.directions) {
            this.drawDirectionBar(y, direction);
            y += this.barHeight + this.directionsGap;
        }
    }

    protected getViewHeight(): number {
        if (!this.data) return 0;
        let height = this.topOffset + this.barHeight + this.directionsTopOffset;
        if (this.areDirectionsVisible) {
            height += this.data.directions.length * (this.barHeight + this.directionsGap);
        }
        return height;
    }

    private drawCycleBar() {
        const width = this.getContainerWidth();

        Common.drawRect(this.ctx, {
            x: this.leftOffset,
            y: this.topOffset,
            width: this.getContainerWidth(),
            height: this.barHeight,
            fillColor: getCycleColor(this.cycleType),
        });
        Common.drawText(this.ctx, {
            x: (this.leftOffset + width) * 0.5,
            y: (this.topOffset + this.barHeight) * 0.5,
            fillColor: "#fff",
            strokeColor: "#03284C4D",
            strokeWidth: 2,
            text: getCycleText(this.cycleType),
            baseline: "top",
        });
    }

    private drawDirectionBar(y: number, direction: DirectionVisData) {
        const { icon, number } = direction;
        const x = this.leftOffset;
        const imgY = y + this.barHeight * 0.5 - (icon?.img.height || 0) * 0.5;

        Common.drawRect(this.ctx, {
            x,
            y,
            width: this.getContainerWidth(),
            height: this.barHeight,
            fillColor: getCycleColor(this.cycleType),
        });
        icon?.img && this.ctx.drawImage(icon.img, x, imgY);
        Common.drawText(this.ctx, {
            x: x + (icon?.img.width || 0),
            y: y + this.barHeight * 0.5,
            text: `${number}Н`,
            baseline: "middle",
        });
    }
}
