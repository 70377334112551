const CARD_DEFAULT_WIDTH = 444;
const CARD_MIN_HEIGHT = 244;

export const getCenteredXPosition = (width = 0) => global.innerWidth * 0.5 - width * 0.5;
export const getCenteredYPosition = (height = 0) => global.innerHeight * 0.5 - height * 0.5;

export const getCardDefaultBounds = () => {
    return {
        minHeight: CARD_MIN_HEIGHT,
        width: CARD_DEFAULT_WIDTH,
        x: getCenteredXPosition(CARD_DEFAULT_WIDTH),
        y: getCenteredYPosition(CARD_MIN_HEIGHT),
    };
};
