import { makeAutoObservable, reaction } from "mobx";
import { AppRouter, Pages } from "app/routing";
import { BooleanProperty } from "lib";
import { TimelineEntity, TimelineEntityType, ObservableRoute } from "./timeline.types";

const EntityTypeByPage = {
    [Pages.TrafficLightDetails]: TimelineEntityType.TrafficLight,
    [Pages.TrafficLightList]: TimelineEntityType.TrafficLight,
    [Pages.CooGroupList]: TimelineEntityType.CooGroup,
    [Pages.CooGroupView]: TimelineEntityType.CooGroup,
} as const;

export class Timeline {
    private routeUpdateReaction: VoidFunction;
    private _entity: Nullable<TimelineEntity> = null;
    private _isExpanded = new BooleanProperty(false);

    constructor() {
        makeAutoObservable(this);
        this.routeUpdateReaction = this.createRouteReaction();
    }

    public get isEnabled() {
        return Boolean(this._entity);
    }

    public get isExpanded() {
        return this._isExpanded.value;
    }

    public get entity() {
        return this._entity;
    }

    public toggleExpansion() {
        this._isExpanded.toggle();
    }

    public destroy() {
        this.routeUpdateReaction();
    }

    private createRouteReaction() {
        return reaction(
            () => AppRouter.getCurrentRoute<ObservableRoute>(),
            (route) => {
                const id = route.params?.id;
                const type = EntityTypeByPage[route.name];
                /** Не меняем ранее проставленное значение. Такое требование */
                if (id === undefined || type === undefined) return;
                this._entity = { type, id: Number(id) };
            },
            { fireImmediately: true }
        );
    }
}
