export const NO_DATA_STRING = "Нет данных";

export const nameOfStatusOnIssues = new Map<number, string>()
    .set(0, "Демонтаж")
    .set(1, "Работает")
    .set(2, "Работает с замечаниями")
    .set(3, "Не работает")
    .set(4, "Регламентные работы");

export const getIssueStatusName = (id?: number) => {
    const name = nameOfStatusOnIssues.get(id ?? -1);
    return name ?? "Нет статуса";
};

export const colorOfStatusOnIssues = new Map<number, string>()
    /** Демонтаж **/
    .set(0, "#83909A")
    /** Работает **/
    .set(1, "#289F46")
    /** Работает с замечаниями **/
    .set(2, "#FECB00")
    /** Не работает **/
    .set(3, "#FF3A2F")
    /** Регламентные работы **/
    .set(4, "#5ACAFD");

export const getIssueStatusColor = (id?: number) => {
    const color = colorOfStatusOnIssues.get(id ?? -1);
    return color ?? "#000000";
};

export const nameOfStatusOnMonitoring = new Map<number, string>()
    .set(1, "Работает")
    .set(2, "Нет данных")
    .set(3, "Работает с замечаниями")
    .set(4, "Не работает");

export const getMonitoringStatusName = (id?: number) => {
    const name = nameOfStatusOnMonitoring.get(id ?? -1);
    return name ?? "Нет статуса";
};

export const colorOfStatusOnMonitoring = new Map<number, string>()
    /** Работает **/
    .set(1, "#289F46")
    /** Нет данных **/
    .set(2, "#83909A")
    /** Работает с замечаниями **/
    .set(3, "#FECB00")
    /** Не работает **/
    .set(4, "#FF3A2F");

export const getMonitoringStatusColor = (id?: number) => {
    const color = colorOfStatusOnMonitoring.get(id ?? -1);
    return color ?? "#000000";
};

const lifeCycleStageNames = {
    1: "Планирование",
    2: "Проектирование",
    3: "Строительство",
    4: "Эксплуатация",
    5: "Реконструкция",
    6: "Демонтаж",
    7: "Планирование (проектирование отклонено)",
    8: "Планирование (направлено на проектирование)",
    9: "Проектирование (отложено)",
    10: "Проектирование (направлено на строительство)",
    11: "Строительство (отложено)",
    12: "Строительство (направлено в эксплуатацию)",
    13: "Эксплуатация (направлено на реконструкцию)",
    14: "Реконструкция (планирование)",
    15: "Реконструкция (проектирование отклонено)",
    16: "Реконструкция (направлено на проектирование)",
    17: "Реконструкция (проектирование)",
    18: "Реконструкция (проектирование отложено)",
    19: "Реконструкция (направлено на строительство)",
    20: "Реконструкция (строительство)",
    21: "Реконструкция (строительство отложено)",
    22: "Реконструкция (направлено в эксплуатацию)",
} as Record<number, string>;

export const getLifeCycleStageName = (id?: number) => {
    if (typeof id === "undefined") return NO_DATA_STRING;
    return lifeCycleStageNames[id];
};
