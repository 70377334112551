import { makeAutoObservable, observable, runInAction } from "mobx";
import { BooleanProperty } from "lib";
import { AppRouter, Pages } from "app/routing";
import { Detector } from "app-domain";
import { Detectors } from "../../store/detectors";
import { DetectorStream, DetectorStreamSubscriber, DetectorStreamMessage } from "api/sockets";
import { MapController, LayerKey } from "map-controller";
import { IAppWindowManager } from "../../store/app-windows";

export interface DetectorDetailsViewModelDeps {
    detectorStore: Detectors;
    /** FIXME: Прямая зависимость */
    mapController: MapController;
    /** FIXME: Прямая зависимость */
    windowManager: IAppWindowManager;
}

type Signal = {
    laneNumber: number;
    carSpeed: number;
    carType: number;
    averageSpeed: number;
};

export class DetectorDetailsViewModel {
    public detector?: Detector.Detector;
    private detectorId?: number;
    private store: Detectors;
    private detectorStream = new DetectorStream();
    private subscriber: DetectorStreamSubscriber;
    private mapController: MapController;
    private windowManager: IAppWindowManager;

    private _isLoading: BooleanProperty = new BooleanProperty(false);
    private _signals: Signal[] = [];

    constructor(deps: DetectorDetailsViewModelDeps) {
        this.store = deps.detectorStore;
        this.mapController = deps.mapController;
        this.windowManager = deps.windowManager;
        makeAutoObservable<DetectorDetailsViewModel, "_signals">(this, {
            _signals: observable.ref,
        });
        this.subscriber = { onMessage: this.onMessage };
    }

    public get isLoading() {
        return this._isLoading.value;
    }

    public get signals() {
        return this._signals;
    }

    public destroy() {
        if (!this.detector) return;
        this.detectorStream.unsubscribeFromDetector(this.detector.id, this.subscriber);
        this.detectorStream.destroy();
    }

    public onBackwardClick = () => {
        this.navigateToList();
    };

    public onShowClick = () => {
        if (!this.detectorId) return;
        this.mapController.flyToEntity(this.detectorId, LayerKey.Detectors);
    };

    public onMinimizeScreenClick = () => {
        if (!this.detectorId) return;
        this.windowManager.spawnDetectorCard(this.detectorId);
        this.navigateToList();
    };

    public setDetectorId(id: number) {
        this.detectorId = id;
        this.load(id);
    }

    private navigateToList() {
        AppRouter.navigate(Pages.DetectorList, { id: this.detectorId?.toString() });
    }

    private setSignals(signals: Signal[]) {
        this._signals = signals;
    }

    private onMessage = (msg: DetectorStreamMessage) => {
        this.setSignals([
            {
                laneNumber: msg.lane,
                carSpeed: msg.speed,
                carType: msg.vehicleClass,
                averageSpeed: 60,
            },
        ]);
    };

    private initSubscription = async (id: number) => {
        await this.detectorStream.init();
        this.detectorStream.subscribeToDetector(id, this.subscriber);
    };

    private load = async (id: number) => {
        this._isLoading.setTruly();
        const detector = await this.store.loadItem(id, { includeDirections: true });
        this._isLoading.setFalsy();
        this.initSubscription(detector.id);
        runInAction(() => (this.detector = detector));
    };
}
