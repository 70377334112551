export enum EventType {
    BaseEvent = 0,
    /** Включение отключение группы координации */
    SetEnabledEvent = 1,
    /** Изменение режима группы координации */
    ChangeModeEvent = 2,
    /** Создаёт предупреждение группы координации */
    WarningEvent = 3,
    /** Взятие пульта ЛК в управление */
    TakeGovernanceEvent = 4,
    /** Снятие ЛК с пульта управления */
    ReleaseGovernanceEvent = 5,
    /** Обновление пульта ЛК */
    UpdateGovernanceEvent = 6,
    /** Инициализация состояния ленты координации */
    InitStateEvent = 7,

    BaseCommand = 2048,
    /** Переключение режима работы группы координации */
    UpdateGovernanceCommand = 2049,
    /** Включение\отключение группы координации */
    SetEnabledCommand = 2050,
    /** Включение\отключение программы группы координации */
    SetEnabledCycleCommand = 2051,
    /** Режим диспетчерского управления */
    TakeGovernanceCommand = 2052,
    /** Снятие режима диспетчерского управления */
    ReleaseGovernanceCommand = 2053,
    /** Синхронизация списка групп координации */
    SyncCooGroupsCommand = 2054,

    /** Изменение пользователя, который редактирует ЛК */
    EditTrackingSetEvent = 6145,
}
