import { WarehouseIssueStatus } from "./warehouse-issue-status";
import { WarehouseMonitoringStatus } from "./warehouse-monitoring-status";

export interface WarehouseEntityInfo {
    id: number;
    location: LatLng;
    issueStatus: WarehouseIssueStatus;
    addresses?: string[];
    num?: string;
    monitoringStatus?: WarehouseMonitoringStatus;
}

export type WarehouseEntityParams = Omit<WarehouseEntityInfo, "id">;

export class WarehouseEntity {
    public readonly id: number;
    public num?: string;
    public location: LatLng;
    public addresses: string[];
    public issueStatus: WarehouseIssueStatus;
    public monitoringStatus: WarehouseMonitoringStatus;

    constructor(id: number, data: WarehouseEntityParams) {
        this.id = id;
        this.num = data.num;
        this.location = data.location;
        this.addresses = data.addresses ?? [];
        this.issueStatus = data.issueStatus;
        this.monitoringStatus = data.monitoringStatus ?? WarehouseMonitoringStatus.NoData;
    }

    public getFullAddress() {
        return this.addresses.join(",");
    }

    public getLocationString() {
        return `${this.location.lat.toFixed(2)}, ${this.location.lng.toFixed(2)}`;
    }
}
