import { MapControlsViewModel, LayerControlKey } from "./map-controls-view-model";
import { useGetDeps } from "./use-get-deps";
import { VMModule } from "../types";

export { MapControlsViewModel, LayerControlKey };

export default {
    model: MapControlsViewModel,
    useGetDeps,
} as VMModule<typeof MapControlsViewModel>;
