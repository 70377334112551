import { makeAutoObservable } from "mobx";
import { BooleanProperty } from "lib";
import { EventEmitter } from "../event-emitter";

export type WindowOptions<Content = unknown> = {
    content: Content;
    onClose: (id: number) => void;
    onFocus: (id: number) => void;
    x?: number;
    y?: number;
    width?: number;
    height?: number;
    minWidth?: number;
    minHeight?: number;
    isCollapsed?: boolean;
    isFocused?: boolean;
    isResizable?: boolean;
};

export type onCloseCallback = (id: number) => void;
export type onFocusChangeCallback = (id: number, value: boolean) => void;

export type WindowInstanceEventListeners = {
    resize: (window: WindowInstance) => void;
    positionChange: (window: WindowInstance) => void;
};

export class WindowInstance<Content = unknown> {
    public readonly id: number;
    public readonly content: Content;
    public readonly minHeight?: number;
    public readonly minWidth?: number;
    public readonly events: Record<keyof WindowInstanceEventListeners, keyof WindowInstanceEventListeners> = {
        resize: "resize",
        positionChange: "positionChange",
    };
    public on: EventEmitter<WindowInstanceEventListeners>["on"];
    public off: EventEmitter<WindowInstanceEventListeners>["off"];
    private _x: number;
    private _y: number;
    private _width: number;
    private _height: number;
    private _zIndex: number = 0;
    private _isCollapsed: BooleanProperty;
    private _isFocused: BooleanProperty;
    private _isResizable: BooleanProperty;
    private onClose: onCloseCallback;
    private onFocusChange: onFocusChangeCallback;
    private emitter = new EventEmitter<WindowInstanceEventListeners>();

    constructor(id: number, options: WindowOptions<Content>) {
        this.id = id;
        this._x = options.x ?? 0;
        this._y = options.y ?? 0;
        this._width = options.width ?? 0;
        this._height = options.height ?? 0;
        this.minHeight = options.minHeight;
        this.minWidth = options.minWidth;
        this.content = options.content;
        this._isCollapsed = new BooleanProperty(options.isCollapsed ?? false);
        this._isFocused = new BooleanProperty(options.isFocused || !this._isCollapsed.value);
        this._isResizable = new BooleanProperty(options.isResizable ?? false);
        this.onClose = options.onClose;
        this.onFocusChange = options.onFocus;
        this.on = this.emitter.on.bind(this.emitter);
        this.off = this.emitter.off.bind(this.emitter);
        makeAutoObservable<WindowInstance, "emitter">(this, {
            emitter: false,
        });
    }

    /** @alias id */
    public get contentId() {
        return this.id;
    }

    public get x() {
        return this._x;
    }

    public get y() {
        return this._y;
    }

    public get width() {
        return this._width;
    }

    public get height() {
        return this._height;
    }

    public get size() {
        return { width: this.width, height: this.height };
    }

    public get isCollapsed() {
        return this._isCollapsed.value;
    }

    public get isResizable() {
        return this._isResizable.value;
    }

    public get isFocused() {
        return this._isFocused.value;
    }

    public get zIndex() {
        return this._zIndex;
    }

    public set zIndex(value: number) {
        this._zIndex = value;
    }

    public setPosition = (x: number, y: number) => {
        this._x = x;
        this._y = y;
        this.emitter.emit(this.events.positionChange, this);
    };

    public setSize = (width: number, height: number) => {
        this._width = width;
        this._height = height;
        this.emitter.emit(this.events.resize, this);
    };

    public focusIn = () => {
        this._isFocused.setTruly();
        this.onFocusChange(this.id, this._isFocused.value);
    };

    public focusOut = () => {
        this._isFocused.setFalsy();
        this.onFocusChange(this.id, this._isFocused.value);
    };

    public expand = () => {
        this._isCollapsed.setFalsy();
        this.focusIn();
    };

    public collapse = () => {
        this._isCollapsed.setTruly();
        this.focusOut();
    };

    public close = () => {
        this.onClose(this.id);
        this.emitter.removeAllListeners();
    };
}
