import { Map as MapBoxMap } from "mapbox-gl";
import { TrafficLightController } from "./traffic-light-controller";
import { CooGroupController } from "./coo-group";
import { DtmTrafficController } from "./dtm-traffic-controller";
import { KsoddController } from "./ksodd-controller";
import { DetectorsController } from "./detectors";
import { RouteBuildingController } from "./route-building-controller";
import { SpeedCamerasController } from "./speed-cameras";
import { TVCamerasController } from "./tv-cameras";
import { TrafficLightDetectorController } from "./trafficlight-detector";
import { LayerKey, getLayerId, LayerInteractivityByWorkingMode, WorkingMode } from "./layer-collection.layers";

export type Layers = {
    [LayerKey.TrafficLights]: TrafficLightController;
    [LayerKey.CooGroups]: CooGroupController;
    [LayerKey.DtmTraffic]: DtmTrafficController;
    [LayerKey.KSODD]: KsoddController;
    [LayerKey.Detectors]: DetectorsController;
    [LayerKey.RouteBuilding]: RouteBuildingController;
    [LayerKey.SpeedCameras]: SpeedCamerasController;
    [LayerKey.TVCameras]: TVCamerasController;
    [LayerKey.TrafficLightDetectors]: TrafficLightDetectorController;
};

/**
 * Коллекция слоев карты
 * Позволяет производить групповые операции над слоями
 * Например переключение видимости
 */
export class LayerCollection {
    private workingMode = WorkingMode.DEFAULT;
    private layers: Layers = {} as Layers;

    constructor() {
        this.layers[LayerKey.TrafficLights] = new TrafficLightController(getLayerId(LayerKey.TrafficLights));
        this.layers[LayerKey.CooGroups] = new CooGroupController(
            getLayerId(LayerKey.CooGroups),
            getLayerId(LayerKey.TrafficLights)
        );
        this.layers[LayerKey.DtmTraffic] = new DtmTrafficController(
            getLayerId(LayerKey.DtmTraffic),
            getLayerId(LayerKey.CooGroups)
        );
        this.layers[LayerKey.KSODD] = new KsoddController(getLayerId(LayerKey.KSODD));
        this.layers[LayerKey.Detectors] = new DetectorsController(getLayerId(LayerKey.Detectors));
        this.layers[LayerKey.RouteBuilding] = new RouteBuildingController(getLayerId(LayerKey.RouteBuilding));
        this.layers[LayerKey.SpeedCameras] = new SpeedCamerasController(getLayerId(LayerKey.SpeedCameras));
        this.layers[LayerKey.TVCameras] = new TVCamerasController(getLayerId(LayerKey.TVCameras));
        this.layers[LayerKey.TrafficLightDetectors] = new TrafficLightDetectorController(
            getLayerId(LayerKey.TrafficLightDetectors)
        );
    }

    public async init(map: MapBoxMap) {
        for (const layer of this.getLayerList()) {
            layer.onInit(map);
        }
        this.setWorkingMode(this.workingMode);
    }

    public async onMapThemeChanged() {
        for (const layer of this.getLayerList()) {
            await layer.onMapThemeChanged();
        }
    }

    public getEntityPosition(id: number, key: LayerKey) {
        return this.layers[key].getEntityPosition?.(id);
    }

    public getLayer<K extends LayerKey>(key: K): Layers[K] {
        return this.layers[key];
    }

    public setActive(id: number | null, key: LayerKey) {
        for (const layerKey of this.getLayerKeys()) {
            const layer = this.layers[layerKey];
            if (layerKey !== key) {
                layer.setActive?.(null);
                continue;
            }
            layer.setActive?.(id);
        }
    }

    public clearActive() {
        for (const layerKey of this.getLayerKeys()) {
            const layer = this.layers[layerKey];
            layer.setActive?.(null);
        }
    }

    public setWorkingMode(workingMode: WorkingMode) {
        if (workingMode === this.workingMode) return;
        const values = LayerInteractivityByWorkingMode[workingMode];
        for (const key of this.getLayerKeys()) {
            const layer = this.getLayer(key);
            layer.setInteractivity(values[key] ?? false);
        }
        this.workingMode = workingMode;
    }

    public getLayerKeys() {
        return Object.keys(this.layers) as LayerKey[];
    }

    public destroy() {
        for (const layer of this.getLayerList()) {
            return layer.onDestroy();
        }
    }

    private getLayerList() {
        return Object.values(this.layers);
    }
}
