import { reaction, toJS } from "mobx";
import { LayerKey, MapController } from "map-controller";
import { WarehouseEntity } from "app-domain/warehouse-entity";
import { LayerBinding } from "./layer-binding";
import { AppRouter, RouteName } from "app/routing";

export interface IWarehouseEntitiesService {
    isListFetched: boolean;
    filteredList: WarehouseEntity[];
    loadItems(): Promise<void>;
    getById(id: number): WarehouseEntity | void;
}

type WarehouseEntityLayerKey = LayerKey.Detectors | LayerKey.SpeedCameras | LayerKey.TVCameras;

export interface WarehouseEntityLayerBindingDeps {
    mapController: MapController;
    service: IWarehouseEntitiesService;
    pageNamesToTrack?: RouteName[];
    navigationPage?: RouteName;
}

export class WarehouseEntityLayerBinding extends LayerBinding {
    protected service: IWarehouseEntitiesService;
    protected layerKey: WarehouseEntityLayerKey;
    protected pageNamesToTrack?: RouteName[];
    protected navigationPage?: RouteName;

    constructor(layerKey: WarehouseEntityLayerKey, deps: WarehouseEntityLayerBindingDeps) {
        super(deps);
        this.layerKey = layerKey;
        this.service = deps.service;
        this.pageNamesToTrack = deps.pageNamesToTrack;
        this.navigationPage = deps.navigationPage;
    }

    public bind(): void {
        const layer = this.mapController.getLayer(this.layerKey);
        layer.on(layer.events.visibilityChange, (isVisible) => {
            if (isVisible && !this.service.isListFetched) this.service.loadItems();
        });
        this.addReaction(
            reaction(
                () => this.service.filteredList,
                // @ts-ignore
                (list) => layer.setData(toJS(list)),
                { fireImmediately: true }
            )
        );
        this.addReaction(
            reaction(
                () => AppRouter.getCurrentRoute(),
                (route) => {
                    if (!this.pageNamesToTrack?.includes(route.name) || !route.params?.id) return;
                    const id = Number(route.params.id);
                    this.mapController.setActive(id, this.layerKey);
                    this.mapController.flyToEntity(id, this.layerKey);
                },
                { fireImmediately: true }
            )
        );
        layer.on(layer.events.itemClick, this.handleClick);
        if (!this.service.isListFetched && layer.isVisible) this.service.loadItems();
    }

    public destroy(): void {
        super.destroy();
        const layer = this.mapController.getLayer(this.layerKey);
        layer.off(layer.events.itemClick, this.handleClick);
    }

    /** override */
    protected handleClick = (id: number) => {};
}
