import { makeAutoObservable } from "mobx";
import { BooleanProperty } from "lib";
import { Cycle } from "app-domain/coo-group";
import { AppRouter, Pages } from "app/routing";
import { CooGroupStore } from "../../store/coo-group";

type CooGroupListViewModelDeps = {
    store: CooGroupStore;
    onFilterOpen: VoidFunction;
};

export class CooGroupListViewModel {
    private store: CooGroupStore;
    private _isSearchOpen;
    private _onFilterOpen: VoidFunction;

    constructor(deps: CooGroupListViewModelDeps) {
        this.store = deps.store;
        this._isSearchOpen = new BooleanProperty(this.store.filter.search.length > 0);
        this._onFilterOpen = deps.onFilterOpen;
        makeAutoObservable<CooGroupListViewModel>(this);
    }

    public get activeItem() {
        const id = Number(AppRouter.getRouteParams(Pages.CooGroupList)?.id);
        return this.store.getById(id);
    }

    public getItemSearchResults(id: number) {
        const cooGroup = this.store.getById(id);
        if (!cooGroup) return null;
        const foundCycles =
            this.store.getSearchEntriesByCooGroupId(id)?.cycleIds.reduce<Cycle[]>((result, cycleId) => {
                const cycle = cooGroup.getCycleById(cycleId);
                if (!cycle) return result;
                result.push(cycle);
                return result;
            }, []) ?? [];
        if (!foundCycles.length) return null;
        return foundCycles;
    }

    public get hasSearchResults() {
        return this.store.hasSearchResults;
    }

    public get searchValue() {
        return this.store.filter?.search ?? "";
    }

    public get isSearchOpen() {
        return this._isSearchOpen.value;
    }

    public get isListLoading() {
        return this.store.isListLoading;
    }

    public get list() {
        return this.store.filteredList;
    }

    public get foundItemsCount() {
        return this.store.filteredList.length;
    }

    public get totalCount() {
        return this.store.total;
    }

    public onClick = (id: number) => {
        AppRouter.navigate(Pages.CooGroupList, { id: id.toString() });
    };

    public onDoubleClick = (id: number) => {
        AppRouter.navigate(Pages.CooGroupView, { id: id.toString() });
    };

    public onSecondaryClick = (id: number) => {
        this.onDoubleClick(id);
    };

    public onFilterOpen = () => {
        this._onFilterOpen();
    };

    public onSearchClear = () => {
        this.store.filter?.clearSearch();
    };
    public onSearchOpen = () => {
        this._isSearchOpen.setTruly();
    };
    public onSearchClose = () => {
        this._isSearchOpen.setFalsy();
        this.store.filter?.clearSearch();
    };
    public onSearchChange = (value: string) => {
        this.store.filter?.setSearch(value);
    };
}
