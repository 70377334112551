import { ReactNode, memo, useCallback, useEffect, useRef, useState } from "react";
import { Loader } from "@megapolis/uikit/loader";
import { CycleType } from "app-domain/traffic-light/enums/cycle-type";
import { SeparatedInlineList } from "../separated-inline-list";
import { Props } from "./traffic-light-cycle-visualizer.types";
import {
    Visualizer,
    StatusCycleVisualizer,
    DefaultCycleVisualizer,
    VisualizationData,
    RenderedPhaseData,
} from "./visualizers";
import { createVisualizationData } from "./traffic-light-cycle-visualizer.utils";
import { CanvasTooltip, CanvasTooltipProps } from "./canvas-tooltip";
import * as Markup from "./traffic-light-cycle-visualizer.styles";

// Пока не придумал, как от этого отказаться.
const OFFSET_CONTENT = 65;

export const TrafficLightCycleVisualizer = memo((props: Props) => {
    const [visualizationData, setVisualizationData] = useState<VisualizationData | null>(null);
    const container = useRef<HTMLDivElement>(null);
    const visualizer = useRef<Visualizer | null>(null);
    const [tooltipProps, setTooltipProps] = useState<CanvasTooltipProps | null>(null);

    const handlePhaseHover = useCallback(
        (data: RenderedPhaseData) => {
            let content: ReactNode = data.tPhase
                ? [`Ф${data.phaseNumber}`, `${data.tPhase}c`]
                : [`Ф${data.phaseNumber}`];

            let tooltipX = data.box.x1 + data.box.x2 * 0.5;

            if (props.renderTooltip) {
                content = props.renderTooltip(data);

                if (container.current && content) {
                    const { width } = container.current.getBoundingClientRect();
                    if (tooltipX < OFFSET_CONTENT) {
                        tooltipX = OFFSET_CONTENT;
                    } else if (tooltipX + OFFSET_CONTENT > width) {
                        tooltipX = width - OFFSET_CONTENT;
                    }
                }
            }

            setTooltipProps({
                x: tooltipX,
                y: data.box.y1 - 5,
                content: <SeparatedInlineList>{content}</SeparatedInlineList>,
            });
        },
        [props]
    );

    const handleMouseLeave = useCallback(() => {
        setTooltipProps(null);
    }, []);

    const { cycleType, cyclePhases, directions, passportPhases, cycleTime, areDirectionsVisible = false } = props;

    // Установка визуализатора в зависимости от типа цикла
    useEffect(() => {
        const containerEl = container.current;
        if (!containerEl) return;

        switch (cycleType) {
            case CycleType.YellowBlink:
            case CycleType.AllRed:
            case CycleType.Disabled:
                visualizer.current = new StatusCycleVisualizer({ containerEl, cycleType });
                break;
            default:
                visualizer.current = new DefaultCycleVisualizer({
                    containerEl,
                    onPhaseHover: handlePhaseHover,
                });
        }

        if (!!visualizer.current || !!visualizationData) {
            visualizer.current.areDirectionsVisible = areDirectionsVisible;
            visualizer.current.data = visualizationData ?? void 0;
            visualizer.current.visualize();
        }

        return () => {
            visualizer.current?.destroy();
            visualizer.current = null;
        };
    }, [cycleType, visualizationData, areDirectionsVisible, handlePhaseHover, handleMouseLeave]);

    // Маппинг входных данных, на данные визуализации
    useEffect(() => {
        let isMounted = true;
        const process = async () => {
            const data = await createVisualizationData({ directions, passportPhases, cyclePhases, cycleTime });
            if (isMounted) {
                setVisualizationData(data);
            }
        };
        process();
        return () => {
            isMounted = false;
        };
    }, [directions, passportPhases, cyclePhases, cycleTime]);

    useEffect(() => {
        if (!visualizer.current) return;
        visualizer.current.areDirectionsVisible = areDirectionsVisible;
    }, [areDirectionsVisible]);

    return (
        <Markup.Wrapper onMouseLeave={handleMouseLeave} widthPercent={props.widthPercent ?? 100}>
            <Markup.CanvasContainer ref={container} />
            {visualizationData === null && (
                <Markup.LoaderWrapper>
                    <Loader />
                </Markup.LoaderWrapper>
            )}
            {tooltipProps && <CanvasTooltip x={tooltipProps.x} y={tooltipProps.y} content={tooltipProps.content} />}
        </Markup.Wrapper>
    );
});
