import { useAppState } from "app/store";
import { DetectorListViewModelDeps } from "./detector-list-view-model";

export const useGetDeps = (): DetectorListViewModelDeps => {
    const { detectors, openFilter } = useAppState();

    return {
        store: detectors,
        onFilterOpen: () => openFilter("detector-list"),
        filter: detectors.filter,
    };
};
