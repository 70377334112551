import { TVCamera } from "app-domain/tv-camera";
import { WarehouseEntities, WarehouseEntitiesService } from "../common/warehouse-entities";
import { TVCamerasFilter } from "./tv-cameras-filter";

export class TVCameras extends WarehouseEntities<TVCamera> {
    public filter!: TVCamerasFilter;

    constructor(service: WarehouseEntitiesService<TVCamera>) {
        super({ service });
        this.setFilter(new TVCamerasFilter());
    }
}
