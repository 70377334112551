import { VMModule } from "../types";
import { AppSearchViewModel } from "./app-search-view-model";
import { useGetDeps } from "./use-get-deps";

export { AppSearchResult } from "./app-search-result";

export default {
    model: AppSearchViewModel,
    useGetDeps,
} as VMModule<typeof AppSearchViewModel>;
