import { TrafficLightSockets } from "api";
import { MapController, LayerKey } from "map-controller";
import { ITrafficLightStore } from "./interfaces";

type Message = TrafficLightSockets.DetectorStateStreamMessage;

export class TrafficLightDetectorStateStreamSubscriber implements TrafficLightSockets.DetectorStateStreamSubscriber {
    constructor(private trafficLightStore: ITrafficLightStore, private mapController: MapController) {}

    public onMessage(msg: Message) {
        const id = msg[0];
        const trafficLight = this.trafficLightStore.getById(id);
        const controller = this.mapController.getLayer(LayerKey.TrafficLightDetectors);
        if (!trafficLight || !controller) return;
        const processedMessage = this.processMessage(msg);
        const lane = trafficLight.getDirectionLaneByDetector(processedMessage.detector, processedMessage.channel);
        trafficLight.updateDirectionLaneMetrics(processedMessage);
        if (!lane) return;
        controller.updateDetectorState({
            trafficLightId: processedMessage.trafficLightId,
            channel: processedMessage.channel,
            detectorId: processedMessage.detector,
            lane,
            metrics: processedMessage.metrics,
        });
    }

    private processMessage(msg: TrafficLightSockets.DetectorStateStreamMessage) {
        return {
            trafficLightId: msg[0],
            detector: msg[1],
            channel: msg[2],
            metrics: {
                total: msg[3],
                car: msg[4],
                bus: msg[5],
                truck: msg[6],
                totalWaiting: msg[7],
                carWaiting: msg[8],
                busWaiting: msg[9],
                truckWaiting: msg[10],
            },
        };
    }
}
