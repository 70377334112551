import { BaseApi } from "api/utils";

import { AddFormatDataScenario, ScenariosData } from "../types";

export class ScenariosAPI extends BaseApi {
    private baseURL = "/storyplay";

    /** Получает список сценариев */
    public getScenarios(): Promise<ScenariosData[]> {
        const url = super.buildUrl(this.baseURL);
        return this.request({ url });
    }

    /** Получает сценарий по id */
    public getScenario(id: number): Promise<ScenariosData> {
        const url = super.buildUrl(`${this.baseURL}/${id}?includeState=true`);
        return this.request({ url });
    }

    /** Создаёт сценарий */
    public postScenarios(scenario: AddFormatDataScenario): Promise<ScenariosData> {
        const url = super.buildUrl(this.baseURL);
        return this.request({ url, method: "POST", body: JSON.stringify(scenario) });
    }

    /** Редактирует сценарий */
    public putScenarios(scenario: AddFormatDataScenario): Promise<ScenariosData> {
        const url = super.buildUrl(`${this.baseURL}/${scenario.id}`);
        return this.request({ url, method: "PUT", body: JSON.stringify(scenario) });
    }

    /** Удаляет сценарий */
    public deleteScenarios(id?: number): Promise<void> {
        const url = super.buildUrl(`${this.baseURL}/${id}`);
        return this.request({ url, method: "DELETE", as: "raw" });
    }

    protected buildUrl(endPoint: string) {
        return super.buildUrl(`${this.baseURL}${endPoint}`);
    }
}
