import { TrafficLightSockets } from "api";
import { LayerKey, MapController } from "map-controller";
import { ITrafficLightStore } from "./interfaces";

type Message = TrafficLightSockets.DetectorTriggerStreamMessage;

export class TrafficLightDetectorTriggerStreamSubscriber
    implements TrafficLightSockets.DetectorTriggerStreamSubscriber
{
    constructor(private trafficLightStore: ITrafficLightStore, private mapController: MapController) {}

    onMessage(msg: Message) {
        const id = msg[0];
        const trafficLight = this.trafficLightStore.getById(id);
        const controller = this.mapController.getLayer(LayerKey.TrafficLightDetectors);
        if (!trafficLight || !controller) return;
        const processedMessage = this.processMessage(msg);
        const lane = trafficLight.getDirectionLaneByDetector(processedMessage.detector, processedMessage.channel);
        trafficLight.updateDirectionLaneIsBusy(processedMessage);
        if (!lane) return;
        controller.updateDetectorLaneBusy({
            detectorId: processedMessage.detector,
            isBusy: processedMessage.isBusy,
            trafficLightId: trafficLight.id,
            lane,
        });
    }

    private processMessage(msg: TrafficLightSockets.DetectorTriggerStreamMessage) {
        return {
            detector: msg[1],
            channel: msg[2],
            isBusy: msg[3],
        };
    }
}
