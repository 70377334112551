import { reaction } from "mobx";
import { CooGroupDomain } from "app-domain";
import { AppStateManager } from "app/store";
import { MapController } from "map-controller";
import { Broker } from "../../broker";

export class CooGroupBroker extends Broker {
    private setDataEffect?: VoidFunction;

    constructor(protected readonly mapController: MapController, protected readonly uiStore: AppStateManager) {
        super(mapController, uiStore);
        this.subscribe();
    }

    private handleSetData = (list: CooGroupDomain.CooGroup[]) => {
        // TODO MAP
        // const cooGroupController = this.mapController.getLayer(LayerKey.cooGroups);
        // if (!cooGroupController) return;
        // cooGroupController.setData(list);
    };

    protected subscribe = () => {
        this.mapController.on(this.mapController.events.load, () => {
            this.setDataEffect = reaction(() => this.uiStore.cooGroupStore.filteredList, this.handleSetData, {
                fireImmediately: true,
            });
        });
    };

    public destroy = () => {
        this.mapController.off(this.mapController.events.load, () => {});

        this.setDataEffect?.();
    };
}
