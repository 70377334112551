import { TrafficLightSockets } from "api";
import { TrafficLightDomain } from "app-domain";
import { Store } from "app";
import { GovernanceInfo } from "app-domain/traffic-light";
import { ISubscriberService } from "./traffic-light-stream-subscriber.types";
import { ITrafficLightStore } from "../interfaces";

const {
    Constants: { malfunctionCodeDictionaryMap },
    Enums: { EventTypeCode },
} = TrafficLightDomain;

type Message = TrafficLightSockets.TrafficLightStreamMessage;

export class Subscriber implements TrafficLightSockets.TrafficLightStreamSubscriber {
    constructor(
        private trafficLightStore: ITrafficLightStore,
        private remoteControlStore: Store.TrafficLightRemoteControls,
        private trafficLightService: ISubscriberService
    ) {}

    // В идеале обработку пультов необходимо вынести ближе к пультам
    // Т.е сделать данный класс типа провайдера событий, а потребители будут отдельными
    // Обновление пультов желательно переделать на то, что приходит в MsgPack
    // Там в полях 26, 27 приходят 2 governance, 1 пользовательский и 1 актуальный
    // Однако требуется еще доработка событий на бекенде, т.к сейчас происходят местами странные вещи, например при завершении команды
    // в пульте приходит событие Release, когда должно по идее прийти событие Update для обновления пульта
    public async onMessage(msg: Message) {
        const trafficLightId = msg[0];
        const trafficLight = this.trafficLightStore.getById(trafficLightId);
        if (!trafficLight) return;
        const processedMessage = this.processMessage(msg, trafficLight);
        const oldGovernanceId = trafficLight.governanceInfo?.id;
        trafficLight.processSignalMessage(processedMessage);
        trafficLight.updateState(processedMessage.state);
        const actualGovernanceId = trafficLight.governanceInfo?.id;
        const { eventType } = processedMessage;

        if (
            eventType === EventTypeCode.ReleaseGovernanceEvent ||
            eventType === EventTypeCode.TakeGovernanceEvent ||
            eventType === EventTypeCode.UpdateGovernanceEvent
        ) {
            console.group("traffic-light-governance-event");
            console.log("traffic-light", trafficLight);
            console.log("processed message", processedMessage);
            console.log("original message", msg);
            console.groupEnd();
        }

        if (eventType === EventTypeCode.PlannedGovernanceTimeEvent) {
            this.trafficLightStore.loadGovernancePlanned(trafficLightId);
        }

        if (processedMessage.eventType === EventTypeCode.ReleaseGovernanceEvent) {
            await this.remoteControlStore.loadPlannedControls();
            // Если произошло удаление пульта другим юзером и нет актуального пульта
            if (typeof actualGovernanceId !== "number") {
                return this.remoteControlStore.removeTrafficLightRemoteControl(trafficLightId);
            }
            // Иначе просто обновляем информацию по пульту, т.к данное событие приходит и в случае завершения команды oO
            return this.remoteControlStore.loadById(actualGovernanceId);
        }

        if (processedMessage.eventType === EventTypeCode.TakeGovernanceEvent) {
            if (typeof actualGovernanceId !== "number") {
                return this.remoteControlStore.updateRemoteControlByTrafficLightId(trafficLightId);
            }
            // Случай, когда СО ранее не контролировался
            return this.remoteControlStore.loadById(actualGovernanceId);
        }

        // Случай обновления информации по пульту
        // Тут не используется actualGovernanceId, потому что бекенд при завершении команды
        // по какой-то причине ставит актуальный governanceId в null, хотя пульт еще в руках у юзера
        // поэтому мы используем для обновления информации по пульту текущий governanceId ассоциированный с светофором
        // Например: завершение команды
        if (processedMessage.eventType === EventTypeCode.UpdateGovernanceEvent) {
            this.remoteControlStore.loadPlannedControls();
            const control = trafficLight.governanceInfo
                ? this.remoteControlStore.getById(trafficLight.governanceInfo.id)
                : null;
            // Костыль для обновления данных открытого пульта у пользователя, у которого перехватили пульт
            // Костыль потому что фактически id объектов управления меняются при перехвате!!!
            // Так еще фактическая смена id происходит по событию UpdateGovernanceEvent, а не по TakeGovernanceEvent
            if (
                typeof oldGovernanceId === "number" &&
                typeof actualGovernanceId === "number" &&
                oldGovernanceId !== actualGovernanceId
            ) {
                const old = this.remoteControlStore.getById(oldGovernanceId);
                if (!old) return;
                const actual = await this.remoteControlStore.loadById(actualGovernanceId);
                if (actual && actual.isUnderUserControl) return old.updateGovernance(actual?.governance);
            }
            if (!trafficLight.governanceInfo || !control) {
                return this.remoteControlStore.updateRemoteControlByTrafficLightId(trafficLightId);
            }

            return this.remoteControlStore.loadById(trafficLight.governanceInfo.id);
        }
    }

    public async onReconnect() {
        const newStates = await this.trafficLightService.getTrafficLightsStates();
        for (const state of newStates) {
            const trafficLight = this.trafficLightStore.getById(state.id);
            if (!trafficLight) return;
            trafficLight.updateState(state);
        }
    }

    private processMessage(
        msg: Message,
        trafficLight: TrafficLightDomain.TrafficLight
    ): TrafficLightDomain.TrafficLightMessage {
        const eventType = msg[9];
        const governanceId = eventType === EventTypeCode.ExternalGovernanceUpdate ? 1 : msg[11];

        let governanceInfo = trafficLight.governanceInfo;
        // Изменяем governance только на таких эвентах, т.к. на них идёт смена governance,
        // другие могут не присылать его (даже при наличии), из-за этого он затирается.
        if (
            governanceId &&
            (eventType === EventTypeCode.TakeGovernanceEvent ||
                eventType === EventTypeCode.UpdateGovernanceEvent ||
                eventType === EventTypeCode.ReleaseGovernanceEvent ||
                eventType === EventTypeCode.ExternalGovernanceUpdate)
        ) {
            governanceInfo = new GovernanceInfo(governanceId, msg[24], msg[25]);
        }

        return {
            id: trafficLight.id,
            dateTime: msg[8],
            eventType,
            controllerDateTime: msg[20],
            state: {
                id: trafficLight.id,
                condition: trafficLight.condition,
                program: msg[1],
                phase: msg[2],
                phaseTime: msg[3],
                secondsGone: msg[4],
                isPromTime: msg[6],
                nextPhase: msg[7],
                malfunctionType: msg[10],
                governanceInfo,
                controlMode: msg[13],
                status: msg[14],
                isGuidedAdaptiveAllowed: msg[17],
                preStatus: msg[18],
                prePhase: msg[19],
                malfunctions: msg[21].map((malfunction) => ({
                    code: malfunction[0],
                    name:
                        malfunctionCodeDictionaryMap[malfunction[0] as TrafficLightDomain.Enums.MalfunctionType]
                            ?.name ?? "",
                    description: malfunction[1],
                    isCritical: malfunction[2],
                })),
                isLocalAdaptiveEnabled: msg[22],
                plannedGovernanceTime: msg[23] ? new Date(msg[23] * 1000).toString() : null,
            },
        };
    }
}
