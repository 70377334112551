import { useCallback, useEffect, useRef, useState, useMemo } from "react";
import { CooGroupDomain } from "app-domain";
import { useCooGroupEditor } from "app/store";
import { Editor as CycleEditor, CooGroupCycle } from "./core";
import { convertToEdit, hasDiff, convertCycleEditData, copyCycle } from "./coo-group-cycle-editor.utils";
import { CooGroupCycleEditorProps, Cycle } from "./coo-group-cycle-editor.types";

export const useCycleEditor = (props: CooGroupCycleEditorProps) => {
    const {
        cycle,
        trafficLights,
        facilities,
        context,
        isEditMode = false,
        hasBackwardDirection = false,
        onChange,
        isAdaptiveEnabled,
    } = props;
    const initialCycle = useRef(copyCycle(cycle));
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const cooGroupEditor = useRef<Nullable<CycleEditor>>();
    const [controlsSettings, setSelectSettings] = useState<Cycle[]>([]);
    const resizeObserver = useRef(new ResizeObserver(() => cooGroupEditor.current?.updateCanvasSizes()));
    const { getFreeRoadSpeed } = useCooGroupEditor();

    const cooGroupEditorData = useMemo(() => {
        if (!cycle.cycles?.length || !trafficLights.length || !facilities.length) return;
        return convertCycleEditData(cycle, trafficLights, facilities);
    }, [cycle, trafficLights, facilities]);

    const handleChange = useCallback(
        (data: CooGroupCycle) => {
            const cooGroupCycle: CooGroupDomain.CustomCycle = convertToEdit(data);

            if (cooGroupEditor.current) {
                setSelectSettings(cooGroupEditor.current.getControlsSettings());
            }

            const hasChanges = hasDiff(initialCycle.current, cooGroupCycle);

            onChange?.({ hasChanges, data: cooGroupCycle });
        },
        [onChange]
    );

    useEffect(() => {
        if (!canvasRef.current || !cooGroupEditorData) return;
        const currentResizeObserver = resizeObserver.current;
        currentResizeObserver.observe(canvasRef.current);

        const cycleEditor = new CycleEditor({
            facilities,
            context,
            canvas: canvasRef.current,
            cooGroupCycle: cooGroupEditorData,
            hasBackwardDirection,
            onChange: handleChange,
        });

        cooGroupEditor.current = cycleEditor;

        cycleEditor.setEditMode(isEditMode);

        setSelectSettings(cycleEditor?.getControlsSettings());

        return () => {
            cooGroupEditor.current?.destroy();
            currentResizeObserver.disconnect();
        };
    }, [context, cycle.id, facilities, isEditMode, handleChange, cooGroupEditorData, hasBackwardDirection]);

    useEffect(() => {
        return () => {
            cooGroupEditor.current?.destroy();
        };
    }, []);

    useEffect(() => {
        if (!cooGroupEditor.current || !cooGroupEditorData) return;
        cooGroupEditor.current.reset(cooGroupEditorData);
    }, [cooGroupEditorData]);

    useEffect(() => {
        if (!cooGroupEditor.current) return;
        cooGroupEditor.current.setEditMode(isEditMode);
    }, [isEditMode]);

    useEffect(() => {
        getFreeRoadSpeed().then((result) => {
            if (!cooGroupEditor.current) return;
            cooGroupEditor.current.setFreeRoadSpeed(result);
        });
    }, [getFreeRoadSpeed]);

    return { cooGroupEditor: cooGroupEditor.current, controlsSettings, canvasRef, isAdaptiveEnabled };
};
