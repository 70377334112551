import { runInAction } from "mobx";
import { ErrorsType, Scenario, AddScenarioType, LaunchData } from "app-domain/scenario";
import { BooleanProperty } from "lib";
import { Filter } from "./filter";
import { ScenarioService } from "./scenario.service";
import { getPropAction } from "./scenario.utils";

type ValidationErrorsType = "create" | "enable";

type ValidationErrors = {
    type: ValidationErrorsType;
    list: string[];
};
export class ScenariosStore {
    public readonly filter: Filter = new Filter();
    private _scenarioList: Map<number, Scenario> = new Map();
    private _errorsList: Map<number, ErrorsType> = new Map();
    private _isListLoading: boolean = false;
    private _isListFetched = new BooleanProperty(false);
    private _validationErrors: ValidationErrors | null = null;

    constructor(private scenariosService: ScenarioService) {}

    public get isListLoading() {
        return this._isListLoading;
    }

    public get list() {
        return this._scenarioList;
    }

    public get filteredList() {
        return Array.from(this._scenarioList.values()) as Scenario[];
    }

    public get isListFetched() {
        return this._isListFetched?.value;
    }

    public get validationErrors() {
        return this._validationErrors;
    }

    public scenarioSelected(id: NullableNumber) {
        return typeof id === "number" ? this._scenarioList.get(id) : undefined;
    }

    public get errors() {
        return Array.from(this._errorsList.values()) as ErrorsType[];
    }

    public getById(id: number) {
        return this._scenarioList.get(id) ?? null;
    }

    public addScenario = async (data: AddScenarioType) => {
        const scenario = await this.scenariosService.editScenario(data);
        if (Array.isArray(scenario)) {
            this._validationErrors = {
                type: "create",
                list: scenario,
            };
            return scenario;
        }
        this._scenarioList.set(scenario.id, { ...scenario, actions: scenario.actions.map(getPropAction) });
        return scenario;
    };

    public deleteScenario = async (id: number) => {
        await this.scenariosService.deleteScenario(id);
    };

    public finishScenario = async (id: number) => {
        await this.scenariosService.finishScenario(id);
    };

    public launchScenario = async (id: number, data: LaunchData) => {
        await this.scenariosService.launchScenario(id, data);
    };

    public enabledScenario = async (id: number, enabled: boolean) => {
        await this.scenariosService.enabledScenario(id, enabled);
    };

    public extendScenario = async (id: number, time: number) => {
        await this.scenariosService.extendScenario(id, time);
    };

    public loadItems = async () => {
        this.setIsListLoading(true);
        const scenarioMap = await this.scenariosService.getScenariosMap();
        this.setIsListLoading(false);

        runInAction(() => {
            this._scenarioList = scenarioMap;
        });
    };

    public loadItem = async (id: number) => {
        this.setIsListLoading(true);
        const scenario = await this.scenariosService.getScenario(id);
        this.setIsListLoading(false);

        runInAction(() => {
            this._scenarioList.set(id, scenario);
        });
    };

    private setIsListLoading(value: boolean) {
        this._isListLoading = value;
    }
}
