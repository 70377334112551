import { memo } from "react";
import { Day } from "./day";
import { useCalendarContext } from "../../calendar-context";
import * as Utils from "../../calendar.utils";
import * as Markup from "./days.styles";

const TOTAL_DAYS_PER_MONTH = 42;

const createDay = (value: Date) => <Day key={value.toString()} value={value} />;

const createDayDate = (origin: Date, day: number) => {
    const result = new Date(origin);
    result.setHours(0, 0, 0);
    result.setDate(day);
    return result;
};

export const Days = memo(() => {
    const days = [];
    const { viewState } = useCalendarContext();

    const { prevMonth, prevMonthDaysCount, currentMonth, nextMonth, currentMonthDaysCount, startDateFromPrevMonth } =
        Utils.getMonthCalendar(viewState.date);

    /** Дни с прошлого месяца */
    for (let i = startDateFromPrevMonth; i <= prevMonthDaysCount; i++) {
        const value = createDayDate(prevMonth, i);
        days.push(createDay(value));
    }

    /** Дни текущего месяца */
    for (let i = 1; i <= currentMonthDaysCount; i++) {
        const value = createDayDate(currentMonth, i);
        days.push(createDay(value));
    }

    /** Дни следующего месяца */
    if (days.length < TOTAL_DAYS_PER_MONTH) {
        for (let i = days.length, day = 1; i < TOTAL_DAYS_PER_MONTH; i++, day++) {
            const value = createDayDate(nextMonth, day);
            days.push(createDay(value));
        }
    }

    return <Markup.DayGrid>{days}</Markup.DayGrid>;
});
