import { BooleanStorageManager } from "lib";
import { BaseLayerController } from "../layer-controller";
import { storageKey } from "./ksodd-controller.constants";

export class KsoddController extends BaseLayerController {
    public isInteractive: boolean = false;
    private isLayerVisible = new BooleanStorageManager(storageKey);

    public get isVisible(): boolean {
        return this.isLayerVisible.value;
    }

    protected _setVisibility(value: boolean) {
        this.isLayerVisible.value = value;
        const style = this.map.getStyle();
        const visibility = this.isLayerVisible.value ? "visible" : "none";

        if (!style.layers) return;

        for (const layer of style.layers) {
            if (!this.isAssociatedLayerId(layer.id)) continue;
            this.map.setLayoutProperty(layer.id, "visibility", visibility);
        }
    }

    protected async _onMapSet() {
        this._setVisibility(this.isLayerVisible.value);
    }

    private isAssociatedLayerId(layerId: string) {
        return layerId.includes(`${this.id}_`);
    }
}
