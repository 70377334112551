import { reaction } from "mobx";
import { LayerKey, MapControllerTypes, WorkingMode } from "map-controller";
import { RouteDomain } from "app-domain";
import { AppRouter, Pages } from "app/routing";
import { CooGroupEditor } from "../../store/coo-group-editor";
import { LayerBinding, MapBindingDeps } from "./layer-binding";

const getMarkerViewType = (index: number, length: number): MapControllerTypes.MarkerViewType => {
    if (index === 0) return "first";
    if (index === length - 1) return "last";
    return "middle";
};

export const pointsMapper = (item: RouteDomain.Point, index: number, array: RouteDomain.Point[]) => ({
    id: item.id,
    lngLat: { lng: item.longitude, lat: item.latitude },
    viewType: getMarkerViewType(index, array.length),
});

export type RouteBuildingLayerBindingsDeps = MapBindingDeps & {
    cooGroupEditor: CooGroupEditor;
};

export class RouteBuildingLayerBinding extends LayerBinding {
    private _cooGroupEditor: CooGroupEditor;

    constructor(deps: RouteBuildingLayerBindingsDeps) {
        super(deps);
        this._cooGroupEditor = deps.cooGroupEditor;
    }

    private get layer() {
        return this.mapController.getLayer(LayerKey.RouteBuilding);
    }

    public bind() {
        const layer = this.layer;
        layer.on(layer.events.addMarker, this.handleLayerAddMarker);
        layer.on(layer.events.moveMarker, this.handleLayerMoveMarker);
        this.bindChangeWorkingMode();
        this.bindPointsChange();
        this.bindRouteSourceChange();
    }

    public destroy(): void {
        const layer = this.layer;
        layer.off(layer.events.addMarker, this.handleLayerAddMarker);
        layer.off(layer.events.moveMarker, this.handleLayerMoveMarker);
        super.destroy();
    }

    private bindChangeWorkingMode() {
        const effect = reaction(
            () => AppRouter.getCurrentRoute(),
            (route) => {
                if (route.name === Pages.RouteAnalysis) {
                    this.mapController.clearActive();
                    return this.mapController.setWorkingMode(WorkingMode.ROUTE_BUILDING);
                }
                return this.mapController.setWorkingMode(WorkingMode.DEFAULT);
            },
            { fireImmediately: true }
        );
        this.addReaction(effect);
    }

    private bindPointsChange() {
        const effect = reaction(
            () => this._cooGroupEditor.points,
            (points) => {
                const prepared = points.map(pointsMapper).filter((item) => item.id >= 0);
                this.layer.setMarkersData?.(prepared);
            },
            { fireImmediately: true }
        );
        this.addReaction(effect);
    }

    private bindRouteSourceChange() {
        const effect = reaction(
            () => this._cooGroupEditor.route,
            (data) => this.layer.setData(data),
            { fireImmediately: true }
        );
        this.addReaction(effect);
    }

    private handleLayerAddMarker = (point: LatLng) => {
        const center = this.mapController.getCenter();
        this._cooGroupEditor.setAddressByLngLat(point, center);
    };

    private handleLayerMoveMarker = (point: { id: number; lngLat: LatLng }) => {
        const center = this.mapController.getCenter();
        this._cooGroupEditor.handleMoveOnMap(point, center);
    };
}
