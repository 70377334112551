import { AppRouter, Pages } from "app/routing";
import { useAppState } from "../../store/hooks";
import { ScenarioViewModelDeps } from "./scenario-view-model";

export const useGetDeps = (): ScenarioViewModelDeps => {
    const { scenariosStore, trafficLightStore, cooGroupStore } = useAppState();
    const scenarioId = Number(AppRouter.getCurrentRoute<Pages.ScenarioDetails>().params?.id) ?? null;
    const scenarioSelected = scenariosStore.scenarioSelected(scenarioId) ?? null;

    return {
        cooGroupStore,
        scenarioSelected,
        trafficlightStore: trafficLightStore,
    };
};
