import { Detector } from "app-domain";
import { computed, makeObservable, override } from "mobx";
import { AppRouter, Pages } from "app/routing";
import { ListViewModel, ListViewModelDeps } from "../list-view-model";

export interface DetectorListViewModelDeps extends ListViewModelDeps<Detector.Detector> {}

export class DetectorListViewModel extends ListViewModel<Detector.Detector> {
    constructor(deps: DetectorListViewModelDeps) {
        super(deps);
        makeObservable(this, {
            onClick: override,
            onDoubleClick: override,
            onArrowClick: override,
            activeItem: computed,
        });
    }

    public get activeItem() {
        const id = Number(AppRouter.getCurrentRoute<Pages.DetectorList>().params?.id);
        return this.getById(id);
    }

    public onClick(id: number): void {
        AppRouter.navigate(Pages.DetectorList, { id: id.toString() });
    }

    public onDoubleClick(id: number): void {
        AppRouter.navigate(Pages.DetectorDetails, { id: id.toString() });
    }

    public onArrowClick(id: number): void {
        this.onDoubleClick(id);
    }
}
