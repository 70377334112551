import { ASUDDWarehouseAPI } from "./asudd-warehouse-api";
import { SpeedCamera } from "../types";

export class SpeedCameraAPI extends ASUDDWarehouseAPI {
    public async getCameraList(): Promise<SpeedCamera[]> {
        return this.request({ url: this.buildUrl() });
    }

    public async getCamera(id: number): Promise<SpeedCamera> {
        return this.request({ url: this.buildUrl(`/${id}`) });
    }

    protected buildUrl(endpoint = ""): string {
        return super.buildUrl(`/speedcam${endpoint}`);
    }
}
