import { reaction } from "mobx";
import { Store } from "app";
import { LayerKey, MapController } from "map-controller";
import { TrafficLightDomain } from "app-domain";

export class TrafficLightHistoryBroker {
    private selectedEventChangeEffect: VoidFunction = () => {};

    constructor(protected readonly mapController: MapController, private state: Store.TrafficLightHistoryStore) {
        this.subscribe();
    }

    private get layer() {
        return this.mapController.getLayer(LayerKey.TrafficLights);
    }

    public destroy() {
        this.mapController.off(this.mapController.events.load, this.initSubs);
        this.selectedEventChangeEffect?.();
    }

    private subscribe() {
        this.mapController.on(this.mapController.events.load, this.initSubs);
    }

    private handleSelectedEventChange = (value: Nullable<TrafficLightDomain.HistoryEvent>) => {
        this.layer.setSelectedEvent(value);
    };

    private initSubs = () => {
        this.selectedEventChangeEffect = reaction(() => this.state.selectedEvent, this.handleSelectedEventChange);
    };
}
