import { makeObservable, runInAction, reaction, computed, action, observable } from "mobx";
import { WarehouseEntity } from "app-domain/warehouse-entity";
import { BooleanProperty } from "lib";
import { delayToLast } from "shared/utils";
import { WarehouseEntitiesDeps, WarehouseEntitiesService, WarehouseEntitiesFilter } from "./warehouse-entities.types";

export class WarehouseEntities<
    Entity extends WarehouseEntity = WarehouseEntity,
    Service extends WarehouseEntitiesService<Entity> = WarehouseEntitiesService<Entity>
> {
    public filter?: WarehouseEntitiesFilter;
    protected service: Service;
    protected items: Map<number, Entity> = new Map();
    protected filteredIds: Nullable<number[]> = null;
    protected _isListFetched = new BooleanProperty(false);
    protected _isListLoading = new BooleanProperty(false);
    private filterChangeEffect?: VoidFunction;

    constructor(deps: WarehouseEntitiesDeps<Entity, Service>) {
        this.service = deps.service;
        if (deps.filter) this.setFilter(deps.filter);
        makeObservable<WarehouseEntities, "items" | "filteredIds">(this, {
            isListFetched: computed,
            list: computed,
            filteredCount: computed,
            filteredList: computed,
            isListLoading: computed,
            loadItems: action,
            loadItem: action,
            filter: observable,
            items: observable,
            filteredIds: observable.ref,
        });
    }

    public get isListFetched() {
        return this._isListFetched?.value;
    }

    public get list() {
        return Array.from(this.items.values());
    }

    public get filteredCount() {
        return this.filteredList.length;
    }

    public get filteredList() {
        if (this.filteredIds === null) return this.list;
        const items = [];
        for (const id of this.filteredIds) {
            const item = this.items.get(id);
            if (!item) continue;
            items.push(item);
        }
        return items;
    }

    public get isListLoading() {
        return this._isListLoading.value;
    }

    public getById(id: number) {
        return this.items.get(id);
    }

    public destroy() {
        this.filterChangeEffect?.();
    }

    public async loadItems() {
        if (this._isListLoading.value) return;
        this._isListLoading.setTruly();
        const items = await this.service.fetchList(this.filter?.shape);
        runInAction(() => {
            this.items = items;
            this._isListLoading.setFalsy();
            this._isListFetched.setTruly();
        });
    }

    public async loadItem(id: number) {
        const item = await this.service.fetchItem(id);
        runInAction(() => {
            this.items.set(id, item);
        });
        return item;
    }

    protected setFilter(filter: WarehouseEntitiesFilter) {
        this.filter = filter;
        this.filterChangeEffect?.();
        this.filterChangeEffect = reaction(() => this.filter?.shape, this._loadFilteredItems);
    }

    private _loadFilteredItems = delayToLast(async () => {
        if (this.filter?.isEmpty) return this._clearFilteredIds();
        this._isListLoading.setTruly();
        const items = await this.service.fetchList(this.filter?.shape);
        runInAction(() => {
            this.filteredIds = Array.from(items.keys());
            this._isListLoading.setFalsy();
        });
    }, 500);

    private _clearFilteredIds() {
        this.filteredIds = null;
    }
}
