import { makeAutoObservable, action, observable, reaction, runInAction } from "mobx";
import { CooGroup, DtmRouter, YandexGeocode, ServiceRegistry } from "services";
import { RouteDomain, CooGroupDomain, TrafficLightDomain, Common } from "app-domain";
import { Utils } from "shared";
import * as constants from "./coo-group-editor.constants";
import * as utils from "./coo-group-editor.utils";
import { CycleEditingState } from "./cycle-editor";
import { TrafficLightStore } from "../traffic-light-store";
import { TrafficLightManager } from "../traffic-light-manager";
import { getCycleEnableStateChanges, getUpdateData } from "./coo-group-editor.utils";
import { FreeRoadSpeed } from "./coo-group-editor.types";

export class CooGroupEditor {
    /** Название ЛК */
    public cooGroupName = "";
    /** Сообщение об ошивке связанное с названием ЛК */
    public cooGroupNameError: Nullable<constants.CooGroupNameErrorMessages> = null;
    /** Адреса точек ЛК */
    public points: RouteDomain.Point[] = [...constants.initialRoutes];
    /** id адреса на котором фокус */
    public focusedPointId: number | null = this.points[0].id;
    /** Признак есть ли пустое поле адреса */
    public isEmptyPoint = true;
    /** Сообщения об ошивке */
    public validationMessages: string[] = [];
    /** Светофоры ЛК */
    public trafficLights: TrafficLightDomain.TrafficLight[] = [];
    /** Группа координации */
    public cooGroup: CooGroupDomain.CooGroup | null = null;
    /** Светофоры необходимые для создания ЛК */
    public facilities?: CooGroupDomain.Facility[];
    /** Маршрут ЛК */
    public route?: RouteDomain.MapRouteFeatures;
    public serverInfo?: Common.ServerInfo;
    /** TODO В дальнейшим надо вынести логику редактирования циклов */
    public cycleEditingState: CycleEditingState;
    public isLoading = false;
    public isSubmitPending = false;
    public hasBackwardDirection = false;
    public cyclesEnableState: Map<number, boolean> = new Map();
    /** Временно */
    private cooGroupInitialization = false;
    private readonly pointChangeEffect: VoidFunction;
    private readonly routeChangeEffect: VoidFunction;

    constructor(
        private cooGroupService: CooGroup.CooGroupService,
        private dtmRouterService: DtmRouter.DtmRouterService,
        private yandexGeocodeService: YandexGeocode.YandexGeocodeService,
        private trafficLightStore: TrafficLightStore,
        private trafficLightManager: TrafficLightManager
    ) {
        makeAutoObservable(this, {
            cooGroup: observable.ref,
            cycleEditingState: observable.ref,
            points: observable.deep,
            addRoute: action.bound,
            onPointSelect: action.bound,
            trafficLights: observable.ref,
        });
        this.cycleEditingState = new CycleEditingState(ServiceRegistry.CooGroupCycleService);
        this.pointChangeEffect = reaction(() => this.points, this.onAddressChange);
        this.routeChangeEffect = reaction(() => this.route, this.onRouteChange);
    }

    /** Признак является ли ЛК новым */
    public get isNew(): boolean {
        return this.cooGroup === null;
    }

    public get isSubmitDisabled() {
        if (this.isNew) {
            return (
                this.isEmptyPoint ||
                !this.facilities?.length ||
                !this.route ||
                !this.cooGroupName ||
                !!this.cooGroupNameError
            );
        }

        if (!this.facilities?.length || !this.route || !this.cooGroup) return true;

        const updateData = getUpdateData(this.cooGroup, {
            points: this.points,
            cooGroupName: this.cooGroupName,
            facilities: this.facilities,
            route: this.route,
        });

        const cycleEnableStateChanges = getCycleEnableStateChanges(this.cooGroup.cycles, this.cyclesEnableState);

        return !updateData && !cycleEnableStateChanges.length;
    }

    /** Признак видемости кнопки сброса адресов */
    public get isResetButtonVisible() {
        if (this.points.length > 2) return true;
        return this.points.some((item) => !!item.fullAddress);
    }

    public cycleEnableStateChange = (id: number, value: boolean) => {
        this.cyclesEnableState.set(id, value);
    };

    public getFacilityById = (facilityId: number) => {
        return this.facilities?.find((facility) => facility.facilityId === facilityId);
    };

    /** Иницализация группы координации для редактирования */
    /** @note Функция асинхронная, потому что позже она будет подгружать необходимые данные */
    public readonly setCooGroup = async (id: number) => {
        this.reset();
        this.setIsLoading(true);
        const cooGroup = await this.cooGroupService.getCooGroupById(id);

        const trafficLights = await this.getTrafficLightsByFacilities(cooGroup.facilities);

        const addresses = cooGroup.points.map(
            (item, index) =>
                new RouteDomain.Point({
                    id: index,
                    latitude: item.lat,
                    longitude: item.lng,
                    fullAddress: item.address,
                })
        );

        runInAction(() => {
            cooGroup.cycles.forEach((cycle) => {
                this.cyclesEnableState.set(cycle.id, cycle.isEnabled);
            });
            this.hasBackwardDirection = cooGroup.geometry.isReverseRouteSameAsDirect;
            this.cooGroupInitialization = true;
            this.cooGroup = cooGroup;
            this.facilities = cooGroup.facilities;
            this.route = cooGroup.geometry;
            this.cooGroupName = cooGroup.name;
            this.points = addresses;
            this.trafficLights = trafficLights;
            this.cycleEditingState.setCooGroup(cooGroup);
            this.isLoading = false;
        });
    };

    public readonly handleCooGroupNameChange = (value: string): void => {
        if (this.cooGroupNameError) {
            this.cooGroupNameError = null;
        }
        this.cooGroupName = value;
    };

    public readonly createCooGroup = async (): Promise<NullableNumber> => {
        if (!this.facilities?.length || !this.route) {
            this.isSubmitPending = false;
            return null;
        }

        this.handleCooGroupNameChange(Utils.transformEntityName(this.cooGroupName));
        this.validateCooGroupName();

        if (this.cooGroupNameError) {
            this.isSubmitPending = false;
            return null;
        }

        const result = await this.cooGroupService.createCooGroup({
            points: this.points,
            name: this.cooGroupName,
            facilities: this.facilities,
            route: this.route,
        });

        if (Array.isArray(result)) {
            runInAction(() => {
                this.validationMessages = result;
                this.isSubmitPending = false;
            });
            return null;
        }
        this.isSubmitPending = false;
        return result.id;
    };

    public handleSubmit = async (): Promise<NullableNumber | void> => {
        this.isSubmitPending = true;

        if (this.isNew) return await this.createCooGroup();

        return await this.updateCooGroup();
    };

    public readonly updateCooGroup = async (): Promise<void> => {
        if (!this.facilities?.length || !this.route || !this.cooGroup) {
            this.isSubmitPending = false;
            return;
        }
        this.validationMessages = [];
        this.handleCooGroupNameChange(Utils.transformEntityName(this.cooGroupName));
        this.validateCooGroupName();

        if (this.cooGroupNameError) {
            this.isSubmitPending = false;
            return;
        }

        const updateData = getUpdateData(this.cooGroup, {
            points: this.points,
            cooGroupName: this.cooGroupName,
            facilities: this.facilities,
            route: this.route,
        });

        const cycleEnableStateChanges = getCycleEnableStateChanges(this.cooGroup.cycles, this.cyclesEnableState);

        if (!updateData && cycleEnableStateChanges.length) {
            try {
                await this.cooGroupService.enableCooGroupCycles(this.cooGroup.id, cycleEnableStateChanges);
                for (let cycle of this.cooGroup.cycles) {
                    cycle.isEnabled = this.cyclesEnableState.get(cycle.id) ?? false;
                }
                this.cyclesEnableState = new Map(this.cyclesEnableState);
            } catch {
                /** В дальнейшем надо как-то обработать ошибки */
            }
            this.isSubmitPending = false;
            return;
        }

        const result = await this.cooGroupService.updateCooGroup({
            id: this.cooGroup.id,
            points: this.points,
            cooGroupName: this.cooGroupName,
            facilities: this.facilities,
            route: this.route,
        });

        const hasValidationMessages = Array.isArray(result);

        if (hasValidationMessages) {
            runInAction(() => {
                this.isSubmitPending = false;
                this.validationMessages = result;
            });
            return;
        }

        if (cycleEnableStateChanges.length) {
            try {
                await this.cooGroupService.enableCooGroupCycles(this.cooGroup.id, cycleEnableStateChanges);
                for (let cycle of this.cooGroup.cycles) {
                    cycle.isEnabled = this.cyclesEnableState.get(cycle.id) ?? false;
                }
                this.cyclesEnableState = new Map(this.cyclesEnableState);
            } catch {
                /** В дальнейшем надо как-то обработать ошибки */
            }
        }

        result.cycles = this.cooGroup.cycles;

        runInAction(() => {
            this.cooGroup = result;
            this.facilities = result.facilities;
            this.route = result.geometry;
            this.cooGroupName = result.name;
            this.points = result.points.map(
                (item, index) =>
                    new RouteDomain.Point({
                        id: index,
                        latitude: item.lat,
                        longitude: item.lng,
                        fullAddress: item.address,
                    })
            );
            this.cycleEditingState.setCooGroup(result);
            this.isSubmitPending = false;
        });
        return;
    };

    public readonly onFocusedRouteChange = (id?: number): RouteDomain.Point[] | void => {
        if (id) {
            const step = this.points.find((item) => item.id === id);
            if (!step) return this.points;
        }
        this.focusedPointId = id ?? null;
    };

    public readonly addressRemove = (id: number): void => {
        this.points = this.points.filter((item) => item.id !== id);
        this.isEmptyPoint = this.points.some((item) => item.id < 0);
    };

    public readonly setAddressByLngLat = async (point: LatLng, center?: LatLng): Promise<void> => {
        const result = await this.yandexGeocodeService.getAddressesByLngLat(point, center);

        if (!result) return;

        const emptyAddress = this.points.find((item) => item.id < 0);

        if (!emptyAddress) return;
        result.latitude = point.lat;
        result.longitude = point.lng;
        await this.onPointSelect(emptyAddress.id, result);
    };

    public readonly addRoute = (): void => {
        const id = utils.generateIdFromAddresses(this.points);
        this.points[this.points.length] = this.points[this.points.length - 1];
        this.points[this.points.length - 2] = new RouteDomain.Point({ id });
        this.isEmptyPoint = true;
    };

    public readonly resetAddresses: VoidFunction = (event?: React.MouseEvent<HTMLButtonElement>) => {
        event?.stopPropagation();
        if (this.validationMessages.length) {
            this.validationMessages = [];
        }
        this.trafficLights = [];
        this.facilities = [];
        this.points = [...constants.initialRoutes];
        this.route = undefined;
        this.isEmptyPoint = true;
    };

    public readonly reset: VoidFunction = () => {
        this.resetAddresses();
        this.cooGroup = null;
        this.cooGroupName = "";
        this.cooGroupNameError = null;
    };

    public readonly updateRoute = (points: RouteDomain.Point[]): void => {
        this.points = points;
    };

    public readonly handleMoveOnMap = async (
        { id, lngLat }: { id: number; lngLat: LatLng },
        center?: LatLng
    ): Promise<void> => {
        const result = await this.yandexGeocodeService.getAddressesByLngLat(lngLat, center);
        if (!result) return;
        result.latitude = lngLat.lat;
        result.longitude = lngLat.lng;
        this.onPointSelect(id, result);
    };

    public readonly onPointSelect = async (id: number, selected: RouteDomain.Point): Promise<void> => {
        const routeIndex = this.points.findIndex((item) => item.id === id);
        if (routeIndex === -1) return;

        this.points[routeIndex] = selected;
        this.points = [...this.points];
        this.isEmptyPoint = this.points.some((item) => item.id < 0);
    };

    public readonly getFreeRoadSpeed = async (): Promise<FreeRoadSpeed | undefined> => {
        if (!this.cooGroup?.id) return;
        try {
            const result = await this.cooGroupService.postCooGroupFreeRoadSpeed(this.cooGroup.id);
            return result;
        } catch {
            /** В дальнейшем надо как-то обработать ошибки */
        }
    };

    private validateCooGroupName: VoidFunction = () => {
        this.cooGroupNameError = utils.getCooGroupNameError(this.cooGroupName);
    };

    private readonly onRouteChange: VoidFunction = () => {
        if (!(!this.cooGroupName && this.route)) return;
        /** При построении маршрута если нет названия то ставим первый и последний адрес через дефис */
        const firstAddress = this.points[0].fullAddress;
        const lastAddress = this.points[this.points.length - 1].fullAddress;
        this.handleCooGroupNameChange(`${firstAddress} - ${lastAddress}`);
    };

    private async getTrafficLightsByFacilities(
        facilities: CooGroupDomain.Facility[]
    ): Promise<TrafficLightDomain.TrafficLight[]> {
        const result: TrafficLightDomain.TrafficLight[] = [];
        for (const facility of facilities) {
            const trafficLight = this.trafficLightStore.getById(facility.facilityId!);
            if (!trafficLight) continue;
            await this.trafficLightManager.initTrafficLight(trafficLight);
            await this.trafficLightManager.initTrafficCycles(trafficLight);
            result.push(trafficLight);
        }
        return result;
    }

    /** При изменении адресов запрашивается маршрут, светафоры и делается запрос на валидацыю */
    private readonly onAddressChange = async (): Promise<void> => {
        if (this.cooGroupInitialization) {
            this.cooGroupInitialization = false;
            return;
        }
        const isEmptyAddress = this.points.some((item) => item.id < 0);
        if (isEmptyAddress) return;

        const { route, facilities } = await this.cooGroupService.getCooGroupRoute(this.points);

        const trafficLights = facilities.reduce((acc: TrafficLightDomain.TrafficLight[], item) => {
            if (!item.facilityId) return acc;
            const trafficLight = this.trafficLightStore.getById(Number(item.facilityId));
            if (!trafficLight) return acc;
            this.trafficLightManager.initTrafficLight(trafficLight);
            acc.push(trafficLight);
            return acc;
        }, []);

        const validationMessages = await this.cooGroupService.validateCooGroup({
            route,
            facilities,
            points: this.points,
            name: this.cooGroupName,
        });

        runInAction(() => {
            this.route = route;
            this.hasBackwardDirection = route.isReverseRouteSameAsDirect;
            this.trafficLights = trafficLights ?? [];
            this.facilities = facilities;
            this.validationMessages = validationMessages;
        });
    };

    private setIsLoading(value: boolean) {
        this.isLoading = value;
    }
}
