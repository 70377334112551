import { AppRouter, Pages } from "app/routing";
import { useAppState } from "../../store/hooks";
import { ScenarioDetailsViewModelDeps } from "./scenario-details-view-model";

export const useGetDeps = (): ScenarioDetailsViewModelDeps => {
    const { scenariosStore } = useAppState();
    const scenarioId = Number(AppRouter.getCurrentRoute<Pages.ScenarioDetails>().params?.id) ?? null;
    const scenarioSelected = scenariosStore.scenarioSelected(scenarioId) ?? null;

    return {
        scenarioSelected,
        errors: scenariosStore.errors,
        scenariosStore,
    };
};
