import { EditorData } from "./cycle-editor-view-model.types";
import { ChangeHistory } from "./change-history";

class RemoteControlEditorChangeHistoryStore {
    private data = new Map<number, ChangeHistory<EditorData>>();

    public save(remoteControlId: number, data: ChangeHistory<EditorData>) {
        this.data.set(remoteControlId, data);
    }
    public read(remoteControlId: number) {
        return this.data.get(remoteControlId) ?? null;
    }
}

export const ChangeHistoryStore = new RemoteControlEditorChangeHistoryStore();
