import { ThemeProvider } from "shared/globals";
import { CycleType } from "../enums";

const themeProvider = ThemeProvider.getInstance();

export const getCycleColor = (cycle: number | undefined) => {
    const theme = themeProvider.theme;

    return cycle === CycleType.YellowBlink
        ? theme.colors.yellow.basic
        : cycle === CycleType.AllRed
        ? theme.colors.red.basic
        : cycle === CycleType.Disabled
        ? theme.colors.black.basic
        : theme.colors.gray.basic;
};
