import { BooleanStorageManager } from "lib";
import { CooGroupDomain } from "app-domain";
import { getCoords } from "@turf/turf";
import RouteArrowIcon from "assets/icons/route-arrow-icon.svg";
import { BaseLayerController } from "../layer-controller";
import { CooGroupLayer } from "./coo-group-layer";
import { RoutePointsMarker } from "./markers";
import { pointsMapper } from "./coo-group-controller.utils";

export class CooGroupController extends BaseLayerController {
    public isInteractive: boolean = true;
    protected _layer!: CooGroupLayer;
    private _visibilityStore: BooleanStorageManager;
    private _routePointsMarker!: RoutePointsMarker;
    private _cooGroups: CooGroupDomain.CooGroup[] = [];
    private _trafficLightsLayerId: string;

    constructor(id: string, trafficLightsLayerId: string) {
        super(id);
        this._trafficLightsLayerId = trafficLightsLayerId;
        this._visibilityStore = new BooleanStorageManager(this._visibilityKey);
    }

    public get isVisible(): boolean {
        return this._layer.isVisible;
    }

    private get _visibilityKey() {
        return `${this.id}_visibility`;
    }

    public setActive = (id: NullableNumber) => {
        if (!this._cooGroups.length) return;
        this._layer.setActive(id);
        const active = this._cooGroups.find((cooGroup) => cooGroup.id === id);
        const points = active?.points.map(pointsMapper) ?? [];
        this._routePointsMarker.setData([points]);
    };

    public getEntityPosition(id: number): GeoJSON.Position[] | void {
        const cooGroup = this._cooGroups.find((cooGroup) => cooGroup.id === id);
        const features = cooGroup?.geometry.routeFeatures?.features;
        if (!features) return;
        const coords = features.reduce((res, feature) => {
            res.push(...getCoords(feature));
            return res;
        }, [] as GeoJSON.Position[]);
        return coords;
    }

    public readonly _onBeforeDestroy = async () => {
        this._layer.removeLayers();
        this._routePointsMarker.destroy();
    };

    public resetActive() {
        this._layer.resetActive();
        this._routePointsMarker.setData([]);
    }

    public setData(data: CooGroupDomain.CooGroup[]) {
        this._cooGroups = data;
        this._layer.setCooGroups(data);
    }

    protected _setVisibility(value: boolean): void {
        this._visibilityStore.value = value;
        this._setLayerVisibility(value);
    }

    protected async _onMapThemeChanged() {
        this._layer.reAttachLayers();
    }

    protected async _onMapSet() {
        this._initIcons();
        const visibility = this._visibilityStore.value;
        this._layer = new CooGroupLayer(this.map, {
            id: this.id,
            beforeId: this._trafficLightsLayerId,
        });
        this._routePointsMarker = new RoutePointsMarker(this.map);
        this._layer.setVisibility(visibility);
    }

    private _setLayerVisibility(value: boolean) {
        this._layer.setVisibility(value);
    }

    private _initIcons = () => {
        const imageArrow = new Image();
        imageArrow.src = RouteArrowIcon;
        imageArrow.onload = () => {
            this.map.addImage("actualRouteArrow", imageArrow);
        };
    };
}
