import { VMModule } from "../types";
import { ScenarioEditViewModel } from "./scenario-edit-view-model";
import { useGetDeps } from "./use-get-deps";

export { ScenarioEditViewModel };

export default {
    model: ScenarioEditViewModel,
    useGetDeps,
} as VMModule<typeof ScenarioEditViewModel>;
