import { useCallback } from "react";
import { AddScenarioType, launchTabs } from "app-domain/scenario";
import { AppRouter, Pages } from "app/routing";
import { useAppState, useSetActiveScenario } from "../../store/hooks";
import { ScenarioEditViewModelDeps } from "./scenario-edit-view-model";

export const useGetDeps = (): ScenarioEditViewModelDeps => {
    const setActiveScenario = useSetActiveScenario();
    const { scenariosStore, trafficLightStore, cooGroupStore } = useAppState();
    const scenarioId = Number(AppRouter.getCurrentRoute<Pages.ScenarioDetails>().params?.id) ?? null;
    const scenarioSelected = scenariosStore.scenarioSelected(scenarioId) ?? null;

    const handleEditScenario = useCallback(
        async (data: AddScenarioType) => {
            const response = await scenariosStore.addScenario(data);
            if (Array.isArray(response)) return response;
            response && setActiveScenario(response.id);
        },
        [scenariosStore, setActiveScenario]
    );

    return {
        launchTabs,
        cooGroupStore,
        scenarioSelected,
        trafficlightStore: trafficLightStore,
        editScenario: handleEditScenario,
    };
};
