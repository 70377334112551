import { Map, MapStyleDataEvent } from "mapbox-gl";

type Options = {
    endpointURL: string;
    map?: Map;
    /** @default png */
    imageFormat?: string;
    filter?: string;
};

export class MapImageLoader {
    private _endpointURL: string;
    private _imageFormat: string;
    private _map?: Map;
    private _filter?: string;

    constructor(options: Options) {
        this._endpointURL = options.endpointURL;
        this._imageFormat = options.imageFormat ?? "png";
        this._filter = options.filter;
        if (options.map) this.setMap(options.map);
    }

    public setMap(map: Map) {
        this._map = map;
        return this;
    }

    public subscribe() {
        this._map?.on("styleimagemissing", this._loadImage);
    }

    public unsubscribe() {
        this._map?.off("styleimagemissing", this._loadImage);
    }

    private _includesInFilter(id: string) {
        return typeof this._filter === "string" && id.includes(this._filter);
    }

    private _checkImagePresence = (id: string) => {
        return this._map?.hasImage(id);
    };

    private _getImageURL(id: string) {
        return `${this._endpointURL}/${id}.${this._imageFormat}`;
    }

    private _loadImage = (event: MapStyleDataEvent & { id: string }) => {
        if (!this._includesInFilter(event.id)) return;
        if (this._checkImagePresence(event.id)) return;
        this._map?.loadImage(this._getImageURL(event.id), (err: string, image: ImageBitmap) =>
            this._handleImageLoad(event.id, err, image)
        );
    };

    private _handleImageLoad = (id: string, error: string, image: ImageBitmap) => {
        if (this._checkImagePresence(id)) return;
        this._map?.addImage(id, image);
    };
}
