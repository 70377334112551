import { ControlModeCode, StatusCode, ControlSource } from "../../enums";

export type GovernancePhase = {
    number: number;
    tPhase: number;
    tProm: number;
};

export enum ScenarioType {
    Status = "StatusSwitchScenarioInfo",
    Phase = "PhaseSwitchScenarioInfo",
    Cycle = "CustomCycleScenarioInfo",
}

export type ScenarioInfo =
    | {
          $type: ScenarioType.Phase;
          phase: number;
      }
    | {
          $type: ScenarioType.Status;
          status: StatusCode;
      }
    | {
          $type: ScenarioType.Cycle;
          // Наименование кастомного цикла
          name: string;
          cycle: {
              phases: GovernancePhase[];
          };
          startCustomCycleInfo: {
              cycleId: number;
              cycleSource: number;
              phases: { number: number; phaseTime: number }[];
          };
      };

/** Данные для создания объекта управления */
export type GovernanceData = {
    id: number;
    /** Идентификатор СО */
    facilityId: number;
    /** Была ли применена команда */
    isApplied?: boolean;
    /** id юзера */
    userId?: string;
    /** фио юзера */
    username?: string;
    /** Возвращает идентификатор пользователя для создания ссылки не его профиль на фронте */
    profileId?: string;
    /** дата взятия управления */
    start?: Date | string;
    /** режим управления СО */
    mode?: ControlModeCode;
    /** дата окончания управления */
    finish?: NullableDate | string;
    /** номер программы */
    program?: number;
    /** дата истечения удержания */
    holdExpireDate?: NullableDate | string;
    /** длительность удержания в секундах */
    holdExpireSeconds?: number;
    /** Дата начала команды */
    commandStart?: NullableDate | string;
    /** Источник управления */
    controlSource?: ControlSource;
    /** Данные для выполнения сценариев */
    scenarioInfo?: ScenarioInfo;
    /** Признак того, что пульт был создан через механизм отложенного выполнения команд */
    isPlanned?: boolean;
};

export type FormattedGovernanceData = GovernanceData & {
    commandStart?: NullableDate;
    holdExpireDate?: NullableDate;
    finish?: NullableDate;
    start?: Date;
};
