import { useEffect, useState } from "react";
import MapView from "./map-view-model";
import MapControls from "./map-controls-view-model";
import TVCamerasList from "./tv-cameras-list-view-model";
import ScenariosList from "./scenario-list-view-model";
import ScenarioDetails from "./scenario-details-view-model";
import ScenarioEdit from "./scenario-edit-view-model";
import Scenario from "./scenario-view-model";
import AppSearch from "./app-search-view-model";
import DetectorList from "./detector-list-view-model";
import { VMModule } from "./types";

export enum ViewModelType {
    Map,
    MapControls,
    TVCamerasList,
    ScenariosList,
    ScenarioDetails,
    ScenarioEdit,
    Scenario,
    AppSearch,
    DetectorList,
}

export type ViewModelByType<Type> = Type extends ViewModelType ? typeof ModuleByType[Type]["model"] : never;

type Modules = {
    [ViewModelType.Map]: VMModule<typeof MapView["model"]>;
    [ViewModelType.MapControls]: VMModule<typeof MapControls["model"]>;
    [ViewModelType.TVCamerasList]: VMModule<typeof TVCamerasList["model"]>;
    [ViewModelType.ScenariosList]: VMModule<typeof ScenariosList["model"]>;
    [ViewModelType.ScenarioDetails]: VMModule<typeof ScenarioDetails["model"]>;
    [ViewModelType.ScenarioEdit]: VMModule<typeof ScenarioEdit["model"]>;
    [ViewModelType.Scenario]: VMModule<typeof Scenario["model"]>;
    [ViewModelType.AppSearch]: VMModule<typeof AppSearch["model"]>;
    [ViewModelType.DetectorList]: VMModule<typeof DetectorList["model"]>;
};

const ModuleByType = {
    [ViewModelType.Map]: MapView,
    [ViewModelType.MapControls]: MapControls,
    [ViewModelType.TVCamerasList]: TVCamerasList,
    [ViewModelType.ScenariosList]: ScenariosList,
    [ViewModelType.ScenarioDetails]: ScenarioDetails,
    [ViewModelType.ScenarioEdit]: ScenarioEdit,
    [ViewModelType.Scenario]: Scenario,
    [ViewModelType.AppSearch]: AppSearch,
    [ViewModelType.DetectorList]: DetectorList,
} as Modules;

export const useCreateViewModel = <Type extends ViewModelType>(type: Type): InstanceType<Modules[Type]["model"]> => {
    const module = ModuleByType[type] as Modules[Type];
    const deps = module.useGetDeps();
    const model = module.model as Modules[Type]["model"];

    // @ts-ignore
    const [vm] = useState(() => new model(deps));
    useEffect(() => {
        // @ts-ignore
        return () => vm.destroy?.();
    }, [vm]);
    // @ts-ignore
    return vm;
};
