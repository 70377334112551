import { makeAutoObservable } from "mobx";

export class Filter {
    public search: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    public get shape() {
        return {
            search: this.search,
        };
    }

    public clearSearch = () => {
        this.search = "";
    };

    public setSearch = (value: string) => {
        this.search = value;
    };

    public reset() {}
}
