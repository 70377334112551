import { VMModule } from "../types";
import { ScenariosListViewModel } from "./scenarios-list-view-model";
import { useGetDeps } from "./use-get-deps";

export { ScenariosListViewModel };

export default {
    model: ScenariosListViewModel,
    useGetDeps,
} as VMModule<typeof ScenariosListViewModel>;
