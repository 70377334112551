import { action, computed, makeObservable, observable } from "mobx";
import cloneDeep from "lodash/cloneDeep";
import { FormTextField, ScheduleForm } from "lib";
import { CooGroupDomain } from "app-domain";
import { Form } from "./abstract-form";

export class CoordinationForm extends Form {
    public schedules: ScheduleForm[] = [];
    public editableCycle: CooGroupDomain.CustomCycle | null = null;
    public hasCycleChanges: boolean = false;

    public hasAdaptiveChanges: boolean = false;

    public isAdaptiveEnabled: boolean;
    public adaptivePeriod: number;
    public adaptiveMaxSpeed: number;
    public adaptiveMinSpeed: number;

    constructor(private cooGroupId: number, cycle: CooGroupDomain.CustomCycle) {
        super(cycle);
        this.cycle = cloneDeep(cycle);
        this.editableCycle = {
            ...this.cycle,
            cycles: this.cycle.cycles ? Array.from(this.cycle.cycles) : [],
        };

        this.isAdaptiveEnabled = cycle.adaptiveEnabled;
        this.adaptiveMinSpeed = cycle.adaptiveMinSpeed === 0 ? 30 : cycle.adaptiveMinSpeed;
        this.adaptiveMaxSpeed = cycle.adaptiveMaxSpeed === 0 ? 80 : cycle.adaptiveMaxSpeed;
        this.adaptivePeriod = cycle.adaptivePeriod === 0 ? 15 : cycle.adaptivePeriod;

        makeObservable(this, {
            name: observable.ref,
            reset: action.bound,
            schedules: observable,
            cycle: observable,
            hasChanges: computed,
            isSubmitDisabled: computed,
            createSchedule: action.bound,
            editableCycle: observable.ref,
            updateCycle: action,
            setCycles: action,
            hasCycleChanges: observable,
            hasAdaptiveChanges: observable,
            isAdaptiveEnabled: observable,
            adaptivePeriod: observable,
            adaptiveMaxSpeed: observable,
            adaptiveMinSpeed: observable,
        });
        this.updateFields();
    }

    public get isSubmitDisabled() {
        if (this.isNew) return this.name.value.trim().length === 0;
        return !this.hasChanges;
    }

    public get hasChanges() {
        return this.isNameChanged || this.hasCycleChanges || this.hasSchedulesChanges() || this.hasAdaptiveChanges;
    }

    public readonly createSchedule = () => {
        this.schedules.push(new ScheduleForm());
    };

    public readonly deleteSchedule = (scheduleForm: ScheduleForm) => {
        this.schedules = this.schedules.filter((schedule) => schedule !== scheduleForm);
    };

    public reset(): void {
        if (!this.editableCycle || !this.cycle) return;
        this.editableCycle = {
            ...this.cycle,
            cycles: this.cycle.cycles?.map((cycle) => ({
                ...cycle,
                phases: cycle.phases?.map((phase) => ({ ...phase })),
            })),
        };
    }

    public readonly setCycles = (cycles: CooGroupDomain.FacilityCycle[]) => {
        this.cycle.cycles = cycles;
        if (this.editableCycle) {
            this.editableCycle = {
                ...this.editableCycle,
                cycles: Array.from(cycles),
            };
        }
    };

    public updateCycle(hasChanges: boolean, cycle: CooGroupDomain.CustomCycle) {
        if (!this.editableCycle) return;
        this.editableCycle.cycles = cycle.cycles;
        this.hasCycleChanges = hasChanges;
    }

    public build(): CooGroupDomain.CustomCycle | null {
        if (!this.editableCycle) return null;
        return {
            ...this.editableCycle,
            cooGroupId: this.cooGroupId,
            name: this.name.value,
            schedules: this.schedules.map((scheduleForm) => scheduleForm.toSchedule()),

            adaptiveEnabled: this.isAdaptiveEnabled,
            adaptiveMinSpeed: this.adaptiveMinSpeed,
            adaptiveMaxSpeed: this.adaptiveMaxSpeed,
            adaptivePeriod: this.adaptivePeriod,
        };
    }

    private hasSchedulesChanges() {
        return (
            this.schedules.length !== this.cycle.schedules?.length ||
            this.schedules.some((schedule) => schedule.hasChanges)
        );
    }

    private updateFields() {
        const cycle = this.cycle;
        this.name = new FormTextField(cycle.name);
        this.schedules = cycle.schedules?.map((schedule) => new ScheduleForm(schedule)) ?? [];
        if (cycle.cycles) this.setCycles(cycle.cycles);
    }

    public toggleAdaptiveEnabled(value: boolean) {
        this.isAdaptiveEnabled = value;
        this.hasAdaptiveChanges = true;
    }

    public setAdaptivePeriod(value: number) {
        this.adaptivePeriod = value;
        this.hasAdaptiveChanges = true;
    }

    public setAdaptiveMaxSpeed(value: number) {
        this.adaptiveMaxSpeed = value;
        this.hasAdaptiveChanges = true;
    }

    public setAdaptiveMinSpeed(value: number) {
        this.adaptiveMinSpeed = value;
        this.hasAdaptiveChanges = true;
    }
}
