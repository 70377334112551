import styled from "styled-components";

export const Wrapper = styled.div<{ widthPercent: number }>`
    position: relative;
    width: ${(props) => `${props.widthPercent}%`};
    height: 100%;
`;

export const LoaderWrapper = styled.div`
    background-color: ${(props) => props.theme.colors.containerBackground.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

export const CanvasContainer = styled.div`
    width: 100%;
    height: 100%;
`;
