import { useAppState } from "../../store/hooks";
import { ScenariosListViewModelDeps } from "./scenarios-list-view-model";

export const useGetDeps = (): ScenariosListViewModelDeps => {
    const { scenariosStore, openFilter } = useAppState();

    return {
        store: scenariosStore,
        onFilterOpen: () => openFilter("scenario-list"),
    };
};
