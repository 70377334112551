import { makeAutoObservable } from "mobx";
import { WindowOptions, WindowInstance } from "./window-instance";

export type SpawnWindowParams<Content = unknown> = Pick<
    WindowOptions<Content>,
    "content" | "x" | "y" | "isCollapsed" | "width" | "height" | "minWidth" | "minHeight" | "isResizable"
> & {
    id: number;
};

export interface WindowManagerService {
    spawn(options: SpawnWindowParams): WindowInstance;
    kill(id: number): void;
}

export class WindowManager {
    public static unFocusedIndex = 5;
    public static focusedIndex = WindowManager.unFocusedIndex + 1;
    public static windowOffset = 10;
    private _windows: Map<number, WindowInstance> = new Map();

    constructor() {
        makeAutoObservable(this);
    }

    public get windows() {
        return Array.from(this._windows.values());
    }

    public get expandedWindows() {
        let result = [];
        for (const window of this._windows.values()) {
            if (window.isCollapsed) continue;
            result.push(window);
        }
        return result;
    }

    public spawn<Content = unknown>(params: SpawnWindowParams<Content>): WindowInstance<Content> {
        const existingWindow = this._windows.get(params.id);
        if (existingWindow) {
            existingWindow.focusIn();
            return existingWindow as WindowInstance<Content>;
        }

        const window = new WindowInstance<Content>(params.id, {
            x: params.x,
            y: params.y,
            width: params.width,
            height: params.height,
            minHeight: params.minHeight,
            minWidth: params.minWidth,
            content: params.content,
            onClose: this.kill,
            onFocus: this.onWindowFocusChange,
            isCollapsed: params.isCollapsed,
            isResizable: params.isResizable,
        });
        this.correctPosition(window);
        this._windows.set(params.id, window);
        window.focusIn();
        return window;
    }

    public kill = (id: number) => {
        this._windows.delete(id);
    };

    private correctPosition(newWindow: WindowInstance) {
        const windowList = Array.from(this.windows.values());
        const existing = windowList[windowList.length - 1];
        if (!existing || existing.isCollapsed) return;
        let x = existing.x + WindowManager.windowOffset;
        let y = existing.y + WindowManager.windowOffset;
        newWindow.setPosition(x, y);
    }

    private onWindowFocusChange = (id: number) => {
        const changed = this._windows.get(id);
        if (!changed) return;
        changed.zIndex = WindowManager.focusedIndex;
        for (const window of this._windows.values()) {
            if (window.id === changed.id) continue;
            window.zIndex = WindowManager.unFocusedIndex;
        }
    };
}
