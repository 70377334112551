import { TrafficLightDomain } from "app-domain";
import { APITypes } from "api";

export const mapCycleToServer = (cycle: TrafficLightDomain.CustomCycle) => ({
    ...cycle,
    syncDate: cycle.syncDate.toISOString(),
    vDate: cycle.vDate?.toISOString(),
});

interface MapCycleToCustomCycleInput {
    cycle: APITypes.FacilityCycle;
    syncDate: string;
    userDisplayName?: string;
}

export const mapCycleToCustomCycle = (input: MapCycleToCustomCycleInput) =>
    new TrafficLightDomain.CustomCycle({
        ...input.cycle,
        source: TrafficLightDomain.CycleSource.Passport,
        syncDate: new Date(input.syncDate),
        externalId: 0,
        isWarning: false,
        warningDescription: "",
        shift: 0,
        userDisplayName: input.userDisplayName,
    });
