import { URLBuilder } from "./url-builder";

export enum TrafficLightDetailsTab {
    Info = "info",
    Indicators = "indicators",
    History = "history",
    Params = "params",
    Cycles = "cycles",
    Issues = "issues",
    PlannedGovernance = "planned-governance",
}

export enum CooGroupViewTab {
    Info = "info",
    Cycles = "Cycles",
}

export enum CooGroupCycleType {
    GreenStreet = "green-street",
    Coordination = "coordination",
}

export enum ScenariosViewTab {
    Info = "info",
    History = "history",
}

export enum DetectorDetailsTab {
    Info = "info",
    Issues = "issues",
}

export const enum Pages {
    Dashboard = "dashboard",
    TrafficLightList = "traffic-lights",
    TrafficLightDetails = "traffic-light",
    CooGroupList = "coo-groups",
    CooGroupView = "coo-group/view",
    CooGroupEdit = "coo-group/edit",
    RouteAnalysis = "route-analysis",
    ScenarioList = "scenarios",
    ScenarioDetails = "scenario/view",
    ScenarioEdit = "scenario/edit",
    DetectorList = "detectors",
    DetectorDetails = "detector",
    TVCameraList = "tv-cameras",
    TVCameraDetails = "tv-camera",
    Upload = "upload",
}

const builder = new URLBuilder();

export const routes = {
    [Pages.Dashboard]: {
        path: "/dashboard",
        createURL: () => "/dashboard",
    },
    [Pages.ScenarioList]: {
        path: "/scenarios/:id?",
        createURL: (params?: { id?: string }) => builder.baseURL("/scenarios").param(params?.id).path,
    },
    [Pages.ScenarioDetails]: {
        path: "/scenario/view/:id/:tab?",
        createURL: (params?: { id: string; tab?: string }) =>
            builder
                .baseURL("/scenario/view")
                .param(params?.id)
                .param(params?.tab || ScenariosViewTab.Info).path,
    },
    [Pages.ScenarioEdit]: {
        path: "/scenario/edit/:id?",
        createURL: (params?: { id: string }) => builder.baseURL("/scenario/edit").param(params?.id).path,
    },
    [Pages.TrafficLightList]: {
        path: "/traffic-lights/:id?",
        createURL: (params?: { id?: string }) => builder.baseURL("/traffic-lights").param(params?.id).path,
    },
    [Pages.TrafficLightDetails]: {
        path: "/traffic-light/:id/:tab?",
        createURL: (params?: { id: string; tab?: TrafficLightDetailsTab }) =>
            builder
                .baseURL("/traffic-light")
                .param(params?.id)
                .param(params?.tab ?? TrafficLightDetailsTab.Info).path,
    },
    [Pages.CooGroupList]: {
        path: "/coo-groups/:id?",
        createURL: (params?: { id?: string }) => builder.baseURL("/coo-groups").param(params?.id).path,
    },
    [Pages.CooGroupView]: {
        path: "/coo-group/view/:id/:tab?",
        createURL: (params?: { id: string; tab?: CooGroupViewTab }) =>
            builder
                .baseURL("/coo-group")
                .param("view")
                .param(params?.id)
                .param(params?.tab ?? CooGroupViewTab.Info).path,
    },
    [Pages.CooGroupEdit]: {
        path: "/coo-group/edit/:id/:cycleType?/:cycleId?",
        createURL: (params?: { id: string; cycleId?: string; cycleType?: CooGroupCycleType }) =>
            builder
                .baseURL("/coo-group")
                .param("edit")
                .param(params?.id)
                .param(params?.cycleType)
                .param(params?.cycleId).path,
    },
    [Pages.RouteAnalysis]: {
        path: "/route-analysys",
        createURL: () => "/route-analysys",
    },
    [Pages.TVCameraList]: {
        path: "/tv-cameras/:id?",
        createURL: (params?: { id?: string }) => builder.baseURL("/tv-cameras").param(params?.id).path,
    },
    [Pages.TVCameraDetails]: {
        path: "/tv-camera/:id",
        createURL: (params?: { id: string }) => builder.baseURL("/tv-camera").param(params?.id).path,
    },
    [Pages.DetectorList]: {
        path: "/detectors/:id?",
        createURL: (params?: { id?: string }) => builder.baseURL("/detectors").param(params?.id).path,
    },
    [Pages.DetectorDetails]: {
        path: "/detector/:id/:tab?",
        createURL: (params?: { id: string; tab?: DetectorDetailsTab }) =>
            builder
                .baseURL("/detector")
                .param(params?.id)
                .param(params?.tab ?? DetectorDetailsTab.Info).path,
    },
    [Pages.Upload]: {
        path: "/upload",
        createURL: () => "/upload",
    },
} as const;

export type RouteName = keyof typeof routes;
