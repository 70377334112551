import { VMModule } from "../types";
import { DetectorListViewModel } from "./detector-list-view-model";
import { useGetDeps } from "./use-get-deps";

export { DetectorListViewModel };

export default {
    model: DetectorListViewModel,
    useGetDeps,
} as VMModule<typeof DetectorListViewModel>;
