import mapboxgl from "mapbox-gl";
import { BooleanStorageManager } from "lib";
import { BaseLayerController } from "./layer-controller";
import { IMapBoxLikeLayer } from "./layer";
import { LayerControllerHelper } from "./layer-controller-helper";
import { BaseEvents } from "./layer-emitter";

export class MapBoxLikeLayerController<Events extends BaseEvents = BaseEvents> extends BaseLayerController<Events> {
    // @ts-expect-error
    protected layer: IMapBoxLikeLayer;
    protected _visibilityStore: BooleanStorageManager;

    constructor(id: string) {
        super(id);
        this._visibilityStore = new BooleanStorageManager(this._visibilityKey);
    }

    public get isVisible(): boolean {
        return LayerControllerHelper.getVisibilityAsBool(this.layer.layout.visibility);
    }

    public get isInteractive() {
        return this.layer.interactive ?? false;
    }

    protected get _visibilityKey() {
        return `${this.id}_visibility`;
    }

    protected _setVisibility(value: boolean): void {
        this._visibilityStore.value = value;
        this._setLayerVisibility(value);
    }

    protected _setInteractivity(value: boolean) {
        this.layer.interactive = value;
    }

    protected _onMapThemeChanged() {
        if (this.map.getLayer(this.layer.id)) return;
        this._onBeforeAddLayer();
        this._addLayer();
    }

    protected _onBeforeDestroy() {
        this._removeLayer();
    }

    protected _onMapSet() {
        this._onBeforeAddLayer();
        this._addLayer();
        const visibility = this._readLayerVisibilityFromStore();
        this._setLayerVisibility(visibility);
    }

    protected _addLayer() {
        this.map?.addLayer(this.layer as mapboxgl.AnyLayer);
    }

    protected _removeLayer() {
        this.map?.removeLayer(this.layer.id);
    }

    protected _setLayerVisibility(value: boolean) {
        this.layer.layout.visibility = LayerControllerHelper.getBoolAsVisibility(value);
        this.map.setLayoutProperty(this.layer.id, "visibility", LayerControllerHelper.getBoolAsVisibility(value));
    }

    protected _readLayerVisibilityFromStore() {
        return this._visibilityStore.value;
    }

    protected _isLayerMounted() {
        return this.map.getLayer(this.layer.id);
    }

    /** @override */
    protected _onBeforeAddLayer() {}
}
