import { makeAutoObservable } from "mobx";
import { WarehouseIssueStatus, WarehouseMonitoringStatus } from "app-domain/warehouse-entity";
import { CodeListFilter } from "lib";
import { WarehouseEntitiesFilter } from "../common/warehouse-entities";

export class DetectorsFilter implements WarehouseEntitiesFilter {
    public readonly issueStatuses = new CodeListFilter<WarehouseIssueStatus>();
    public readonly monitoringStatuses = new CodeListFilter<WarehouseMonitoringStatus>();
    public search: string = "";

    constructor() {
        makeAutoObservable(this);
    }

    public get shape() {
        return {
            issueStatuses: this.issueStatuses.list,
            monitoringStatuses: this.monitoringStatuses.list,
            search: this.search,
        };
    }

    public get isEmpty() {
        return this.issueStatuses.isEmpty && this.monitoringStatuses.isEmpty && this.search.length === 0;
    }

    public clearSearch = () => {
        this.search = "";
    };

    public setSearch = (value: string) => {
        this.search = value;
    };
}
