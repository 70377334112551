import { EntityType, ScenarioStart, ScenarioStatus } from "./";

export type EventType = {
    status?: number;
    phase?: number;
    cycle?: number;
};

export type ObjectType = {
    type?: EntityType;
    id: NullableNumber;
};

// Текущий режим управления сценарием
export enum ControlMode {
    // Отключён
    Disabled = 0,
    // Не активен
    Inactive = 1,
    // По-расписанию
    Schedule = 2,
    // Ручной пользователем
    User = 3,
    // Триггер
    Trigger = 4,
}

// Статус сценария
export enum Status {
    // Неактивна
    Inactive = 0,
    // Активна
    Active = 1,
}

// Тип запуска сценария
export enum StartType {
    // Ручной
    Manual = 0,
    // По расписанию
    Scheduled = 1,
    // При наступлении условий
    OnConditions = 2,
}

export type LaunchData = { duration?: number; stopAt?: string };

export type StateStreamData = {
    controlMode: ControlMode;
    date: number;
    eventType: StartType;
    type: StartType;
    governanceId: number;
    governanceUserId: string;
    governanceUsername: string;
    id: number;
    status: Status;
};

export type StateType = {
    controlMode: ControlMode;
    id: number;
    isEnabled: boolean;
    stateDate: string;
    status: Status;
    type: StartType;
    governance: {
        controlSource: number;
        holdExpireDate: string;
        id: number;
        start: string;
        storyplayId: number;
        userId: string;
        username: string;
    };
};

export type ActionType = ObjectType & EventType;

export type Scenario = {
    id: number;
    // priority: number;
    startType: ScenarioStart;
    // allowManualStart: boolean
    // isDeleted: boolean
    updated: string;
    name: string;
    description: string;
    userId: number;
    userDisplayName: string;
    // startConditions: string
    actions: ActionType[];
    state: StateType;
    // finishConditions: string
    // schedules:
    status: ScenarioStatus;
    // isActive: boolean;
    // workDuration: string;
};

export type AddScenarioType = {
    id?: number;
    startType: number;
    name: string;
    description: string;
    actions: ActionType[];
};
