import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
import { TrafficLightDomain } from "app-domain";
import { LayerCollection } from "../layers";

interface InitParams {
    map: mapboxgl.Map;
    layers: LayerCollection;
}

/** @deprecated */
export class FlyManager {
    private readonly duration = 800;
    private map?: mapboxgl.Map;

    public init(params: InitParams) {
        this.map = params.map;
    }

    public handleTrafficLightPosition = (trafficLight: TrafficLightDomain.TrafficLight) => {
        this.setViewPort(trafficLight.location);
    };

    public handleCooGroupPosition = (coordinates: turf.Position[]) => {
        this.setViewPort(coordinates as [number, number][]);
    };

    public forceSetViewPort = (coordinates: LatLng | [number, number][]) => {
        if (!this.map) return;

        if (Array.isArray(coordinates)) {
            const bounds = new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]);

            for (const coord of coordinates) {
                bounds.extend(coord);
            }

            try {
                this.map.fitBounds(bounds, {
                    padding: 200,
                    maxDuration: this.duration,
                    essential: true,
                });
            } catch (e) {
                console.error("Can not set bounds");
            }
            return;
        }

        this.map.once("moveend", () => {
            window.dispatchEvent(new CustomEvent("cypress__map__centred", { detail: { map: this.map, coordinates } }));
        });

        this.map.flyTo({
            center: coordinates,
            maxDuration: this.duration,
            essential: true,
        });
    };

    private setViewPort = (coordinates: LatLng | [number, number][]) => {
        if (Array.isArray(coordinates)) {
            return this.forceSetViewPort(coordinates);
        }
        if (this.map?.getBounds().contains(coordinates)) return;
        this.forceSetViewPort(coordinates);
    };
}
