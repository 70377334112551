import styled, { css } from "styled-components/macro";
import { Button as UiKitButton } from "@megapolis/uikit/button";

export const Button = styled(UiKitButton)<{ front: "right" | "left" } & React.ComponentProps<typeof UiKitButton>>`
    position: absolute;
    width: 32px;
    height: 32px;
    top: calc(50% - 16px);
    left: ${({ front, theme }) =>
        front === "right" ? `calc(100% - 32px - ${theme.spacings[3].str})` : theme.spacings[3].str};
    border-radius: 50%;
    transition: 0.2s;
`;

export const Container = styled.div`
    position: relative;
    width: 100%;
    ${Button} {
        opacity: 0;
    }
    &:hover {
        ${Button} {
            opacity: 1;
        }
    }
`;

export const ListContainerWrapper = styled.div<{ isOverflowHidden?: boolean }>`
    overflow: ${({ isOverflowHidden = false }) => (isOverflowHidden ? "hidden" : "visible")};
`;

export const ListContainer = styled.div<{ gap?: number; padding?: string }>(
    ({ gap = 0, padding = 0 }) => css`
        width: fit-content;
        display: flex;
        gap: ${gap}px;
        padding: ${padding};
        will-change: transform;
        transition: all 450ms ease-out 0s;
        user-select: none;
        &.draggable {
            cursor: grabbing;
            * {
                cursor: grabbing;
            }
        }
    `
);
