import { MouseEvent } from "react";
import { makeAutoObservable, runInAction } from "mobx";
import isEqual from "lodash/isEqual";
import { ActionType, AddScenarioType, Scenario, EntityType, LaunchTabsType, ObjectType } from "app-domain/scenario";
import { CustomCycle, CycleType, TrafficLight } from "app-domain/traffic-light";
import { CooGroup } from "app-domain/coo-group";
import { CooGroupForm } from "./coo-group-form";
import { CooGroupStore, TrafficLightStore } from "app/store";
import { CycleEditorState } from "../scenario-view-model/cycle-editor-state";
import { AppRouter, Pages } from "app/routing";

export type ScenarioEditViewModelDeps = {
    launchTabs: LaunchTabsType[];
    cooGroupStore: CooGroupStore;
    scenarioSelected: Scenario | null;
    trafficlightStore: TrafficLightStore;
    editScenario: (scenario: AddScenarioType) => void;
};

type State = Pick<Scenario, "startType" | "name" | "description" | "actions"> & { id?: number };

export class ScenarioEditViewModel {
    public launchTabs: LaunchTabsType[];
    public scenarioSelected: Scenario | null;
    public viewState?: any;
    public originalState: State;
    public editState: State;
    public errors?: string[];

    private _trafficlightStore: TrafficLightStore;
    private _cooGroupStore: CooGroupStore;
    private _cooGroupForm?: CooGroupForm;
    private _openSelectMode?: {
        index: number;
        objectType: EntityType;
        [EntityType.TrafficLight]?: TrafficLight;
        [EntityType.CooGroup]?: CooGroup;
        // TODO, когда добавится ТОИ
        // [EntityType.Board]?: any;
    };
    private _isVisibleEditConfirm?: boolean = false;
    private _openIndexSelectObject?: number;
    private _openTopSelectObject?: number;
    private _editScenario: ScenarioEditViewModelDeps["editScenario"];

    constructor(deps: ScenarioEditViewModelDeps) {
        const newState = {
            id: deps.scenarioSelected?.id,
            startType: deps.scenarioSelected?.startType || 0,
            name: deps.scenarioSelected?.name || "",
            description: deps.scenarioSelected?.description || "",
            actions: deps.scenarioSelected?.actions || [],
        };

        this.originalState = newState;
        this.editState = newState;

        this.launchTabs = deps.launchTabs;
        this.scenarioSelected = deps.scenarioSelected;
        this._trafficlightStore = deps.trafficlightStore;
        this._cooGroupStore = deps.cooGroupStore;
        this._editScenario = deps.editScenario;

        makeAutoObservable(this);
    }

    public get eventList() {
        return Object.values(EntityType);
    }

    public get selectionOffsetTop() {
        return this._openTopSelectObject ?? 0;
    }

    public get selectionObject() {
        return typeof this._openIndexSelectObject === "number"
            ? this.editState.actions[this._openIndexSelectObject]
            : undefined;
    }

    public get selectionObjectIndex() {
        return this._openIndexSelectObject;
    }

    public get selectionMode() {
        return this._openSelectMode && this._openSelectMode[this._openSelectMode.objectType];
    }

    public get selectingModeType() {
        return this._openSelectMode?.objectType;
    }

    public get selectingModeIndex() {
        return this._openSelectMode?.index;
    }

    public get cooGroupForm() {
        return this._cooGroupForm;
    }

    public get isVisibleEditConfirm() {
        return this._isVisibleEditConfirm;
    }

    public get isEditConfirmDisabled() {
        if (!this.editState.id) return !this.editState.name || !this.editState.actions.length;
        return isEqual(this.editState, this.originalState);
    }

    public get validationErrors() {
        return this.errors;
    }

    public clearValidationErrors = () => {
        this.errors = undefined;
    };

    public toggleVisibleConfirmEditScenario() {
        this._isVisibleEditConfirm = !this._isVisibleEditConfirm;
    }

    public setCooGroupForm = (id: number) => {
        this._cooGroupForm = new CooGroupForm(id);
    };

    public addActions = () => {
        this.editState.actions.push({
            id: null,
        });
    };

    public clearActions = () => {
        this.editState.actions = [];
    };

    public onEditScenario = async () => {
        const response = await this._editScenario(this.editState);
        if (Array.isArray(response)) {
            this.errors = response;
        }
    };

    public onCancel = () => {
        this.editState.id
            ? AppRouter.navigate(Pages.ScenarioDetails, { id: String(this.editState.id) })
            : AppRouter.navigate(Pages.ScenarioList);
    };

    public changeObject = (object: ObjectType) => {
        const isValid = this.editState.actions.find((action) => action.id === object.id);
        if (isValid) return alert("Такой объект уже присутствует в данном сценарии");

        runInAction(() => {
            if (typeof this._openIndexSelectObject === "number") {
                this.editState.actions[this._openIndexSelectObject] = object;
            }
            this.onCloseSelect();
        });
    };

    public changeMode = (newStateMode: Pick<ActionType, "cycle" | "phase" | "status">) => {
        runInAction(() => {
            if (this._openSelectMode) {
                const prev = this.editState.actions[this._openSelectMode.index];
                this.editState.actions[this._openSelectMode.index] = { id: prev.id, type: prev.type, ...newStateMode };
            }
            this.onCloseSelect();
        });
    };

    public onChangeName = (newName: string) => {
        this.editState.name = newName;
    };

    public onChangeDescription = (newDescription: string) => {
        this.editState.description = newDescription;
    };

    public onTabClick = (tab: string | number) => {
        this.editState.startType = tab as number;
    };

    public onRemoveObject = (index: number) => {
        this.editState.actions.splice(index, 1);
        this.onCloseSelect();
    };

    public onOpenObject = (index: number, e: MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        this._openSelectMode = undefined;
        this._openIndexSelectObject = this.onToggleSelect(index, this._openIndexSelectObject);
        const { height, top } = e.currentTarget.getBoundingClientRect();
        this._openTopSelectObject = height + top;
    };

    public onOpenMode = async (index: number) => {
        this._openIndexSelectObject = undefined;
        this._openTopSelectObject = undefined;
        const openedType = this._openSelectMode?.index;

        if (openedType && index) return (this._openSelectMode = undefined);

        const { type, id, cycle } = this.editState.actions[index];
        if (type === EntityType.TrafficLight && id) {
            this.viewState = new CycleEditorState();
            const trafficlight = (await this._trafficlightStore.loadById(id)) || undefined;
            this._openSelectMode = {
                index,
                objectType: EntityType.TrafficLight,
                [EntityType.TrafficLight]: trafficlight,
            };

            if (trafficlight) {
                const findedCycle = trafficlight?.cycles.find((item) => item.id === cycle);
                if (!findedCycle) return;
                const { directions, phases: passportPhases } = trafficlight as TrafficLight;
                const { phases: cyclePhases } = findedCycle as CustomCycle;

                const cycleTime = cyclePhases?.reduce((acc, cur) => (acc += cur.tPhase), 0);
                this.viewState = {
                    selectedCycleId: findedCycle?.id,
                    cycleTime,
                    cycleType: CycleType.Basic,
                    cyclePhases,
                    passportPhases,
                    directions,
                    areDirectionsVisible: true,
                };
            }
        }
        if (type === EntityType.CooGroup && id) {
            const cooGroup = (await this._cooGroupStore.getById(id)) || undefined;
            this._openSelectMode = {
                index,
                objectType: EntityType.CooGroup,
                [EntityType.CooGroup]: cooGroup,
            };
        }
    };

    public onCloseSelect = () => {
        this._openIndexSelectObject = undefined;
        this._openSelectMode = undefined;
        this._openTopSelectObject = undefined;
    };

    public onToggleSelect = (index: number, value?: number) => {
        if (typeof index === "number") {
            return value === index ? undefined : index;
        } else {
            return undefined;
        }
    };

    public onSelectCycle = (cycle: CustomCycle) => {
        if (!cycle || cycle.time === 0) return;

        this.viewState = {
            selectedCycleId: cycle.id,
            cycleTime: cycle.time,
            cycleType: cycle.type,
            cyclePhases: cycle.phases ?? [],
            name: cycle.name,
            source: cycle.source,
            areDirectionsVisible: true,
        };
    };
}
