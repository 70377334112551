import { VMModule } from "../types";
import { MapViewModel } from "./map-view-model";
import { useGetDeps } from "./use-get-deps";

export { MapViewModel };

export default {
    model: MapViewModel,
    useGetDeps,
} as VMModule<typeof MapViewModel>;
