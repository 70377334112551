import mapboxgl, { LineLayout, LineLayer } from "mapbox-gl";
import { lineColor, lineOffset, lineWidth } from "./dtm-traffic-layer.constants";

type Layout = LineLayout & {
    visibility: mapboxgl.Visibility;
};

export class DtmTrafficLayer implements LineLayer {
    public "id": string;
    public "type": "line" = "line";
    public "source";
    public "source-layer" = "traffic";
    public "layout": Layout = {
        "line-join": "round",
        "line-cap": "round",
        "visibility": "none",
    };
    public "paint" = {
        "line-color": lineColor,
        "line-offset": lineOffset,
        "line-width": lineWidth,
    };

    "constructor"(id: string) {
        this.id = id;
        this.source = id;
    }
}
