import { REACT_APP_KSODD_LAYER } from "env-data";

export enum LayerKey {
    KSODD = "ksodd",
    TrafficLights = "trafficLights",
    TrafficLightDetectors = "trafficLightDetectors",
    CooGroups = "cooGroups",
    // RadarDetectors = "radarDetectors",
    // RadarDetectorRadar = "radarDetectorRadar",
    SpeedCameras = "speedCameras",
    DtmTraffic = "dtmTraffic",
    Detectors = "detectors",
    RouteBuilding = "routeBuilding",
    TVCameras = "tvCameras",
}

export const LayerIds = {
    [LayerKey.TrafficLights]: "TRAFFICLIGHT",
    [LayerKey.TrafficLightDetectors]: "TRAFFICLIGHT_DETECTORS",
    [LayerKey.CooGroups]: "TRAFFICLIGHT_GROUP",
    // [LayerKey.RadarDetectors]: "RADAR_DETECTOR",
    // [LayerKey.RadarDetectorRadar]: "RADAR_DETECTOR_radar",
    [LayerKey.DtmTraffic]: "DTM_TRAFFIC",
    [LayerKey.KSODD]: REACT_APP_KSODD_LAYER,
    [LayerKey.Detectors]: "DETECTORS",
    [LayerKey.RouteBuilding]: "ROUTE_BUILDING",
    [LayerKey.TVCameras]: "TV_CAMERAS",
    [LayerKey.SpeedCameras]: "SPEED_CAMERAS",
    // warehouseDetector: "WAREHOUSE_DETECTOR",
} as Record<LayerKey, string>;

/** Режим работы слоев */
export enum WorkingMode {
    /** Режим по-умолчанию: все слои доступны для отображения и кликабельны */
    DEFAULT,
    /** Режим анализа маршрута: остальные слои не кликабельны */
    ROUTE_BUILDING,
}

/** Правила работы слоев в зависимости от режима отображения */
export const LayerInteractivityByWorkingMode = {
    [WorkingMode.DEFAULT]: {
        [LayerKey.TrafficLights]: true,
        [LayerKey.TrafficLightDetectors]: true,
        // [LayerKey.RadarDetectors]: true,
        [LayerKey.CooGroups]: true,
        [LayerKey.Detectors]: true,
    },
    [WorkingMode.ROUTE_BUILDING]: {
        [LayerKey.RouteBuilding]: true,
    },
} as Record<WorkingMode, Record<LayerKey, boolean>>;

export const getLayerId = (key: LayerKey) => {
    return LayerIds[key];
};

export const getLayerIds = (keys: LayerKey[]) => keys.map(getLayerId);
