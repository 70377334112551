import { WarehouseEntity, WarehouseEntityInfo, WarehouseEntityParams } from "../warehouse-entity/warehouse-entity";
import { Direction } from "./direction";

export interface DetectorInfo extends WarehouseEntityInfo {
    directions: Direction[];
    stageLifecycleId?: number;
}

export interface DetectorParams extends WarehouseEntityParams {
    stageLifecycleId?: number;
    directions?: Direction[];
}

export class Detector extends WarehouseEntity implements DetectorInfo {
    public directions: Direction[];
    public stageLifecycleId?: number;

    constructor(id: number, data: DetectorParams) {
        super(id, data);
        this.directions = data.directions ?? [];
        this.stageLifecycleId = data.stageLifecycleId;
    }
}
