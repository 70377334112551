import { GeoJSONSourceRaw } from "mapbox-gl";

export type GeoJSONFeatureCollectionData<Feature extends GeoJSON.Feature> = GeoJSON.FeatureCollection & {
    features: Feature[];
};
export class GeoJSONFeatureCollection<Feature extends GeoJSON.Feature> implements GeoJSONSourceRaw {
    public type: "geojson" = "geojson";
    public data: GeoJSONFeatureCollectionData<Feature> = {
        type: "FeatureCollection",
        features: [],
    };
}
