import { ControlSource } from "../../enums";
import { FormattedGovernanceData, ScenarioType } from "./governance-data";
import { HoldCycleCommand, HoldPhaseCommand, HoldStatusCommand } from "./governance-command";

/** Объект, описывающий управление СО */
export class Governance {
    public id: number;
    public facilityId: number;
    public readonly data: FormattedGovernanceData | null;
    private _command: HoldCycleCommand | HoldPhaseCommand | HoldStatusCommand | null = null;
    public isPlanned?: boolean;

    constructor(id: number, facilityId: number, data?: FormattedGovernanceData) {
        this.id = id;
        this.facilityId = facilityId;
        this.data = data ?? null;
        this.isPlanned = data?.isPlanned ?? false;
        this.createCommand();
    }

    public get command() {
        return this._command;
    }

    public get isUnderUserControl() {
        return this.data?.controlSource === ControlSource.User;
    }

    private createCommand() {
        if (!this.data) return;
        const {
            commandStart: start,
            holdExpireDate: expiresAt,
            holdExpireSeconds: expireSeconds,
            isApplied,
            scenarioInfo,
        } = this.data;

        if (!start || !expiresAt || !expireSeconds) return;

        if (!scenarioInfo) return;

        if (scenarioInfo.$type === ScenarioType.Phase) {
            const { phase } = scenarioInfo;
            return (this._command = new HoldPhaseCommand({ start, expiresAt, expireSeconds, isApplied, phase }));
        }

        if (scenarioInfo.$type === ScenarioType.Status) {
            const { status } = scenarioInfo;

            return (this._command = new HoldStatusCommand({ start, expiresAt, expireSeconds, isApplied, status }));
        }

        if (scenarioInfo.$type === ScenarioType.Cycle) {
            const { name, cycle, startCustomCycleInfo } = scenarioInfo;

            return (this._command = new HoldCycleCommand({
                start,
                expiresAt,
                expireSeconds,
                isApplied,
                phases: cycle.phases,
                cycleId: startCustomCycleInfo?.cycleId,
                cycleSource: startCustomCycleInfo?.cycleSource,
                name,
            }));
        }
    }
}
