import { TrafficLightSockets } from "api";
import { CooGroupDomain } from "app-domain";
import { ICooGroupStore, ICooGroupRemoteControlStore, ISubscriberService } from "./coo-group-stream-subscriber.types";
import { CooGroupEditor } from "app/store/coo-group-editor";

const { EventType } = CooGroupDomain;

export class Subscriber implements TrafficLightSockets.CooGroupStreamSubscriber {
    constructor(
        private cooGroupStore: ICooGroupStore,
        private remoteControlStore: ICooGroupRemoteControlStore,
        private cooGroupEditor: CooGroupEditor,
        private service: ISubscriberService
    ) {}

    public async onMessage(message: TrafficLightSockets.CooGroupStreamMessage) {
        const processedMessage = this.processMessage(message);
        const cooGroup = this.cooGroupStore.getById(processedMessage.cooGroupId);
        if (!cooGroup) return;

        cooGroup.updateState(processedMessage.state);
        cooGroup.processSignalMessage({
            id: processedMessage.cooGroupId,
            dateTime: processedMessage.updatedAt,
            status: message[4],
            controlMode: message[3],
            activeCycleId: message[2] ?? 0,
            type: message[6],
            data: "",
        });

        const { eventType } = processedMessage;

        if (
            eventType === EventType.ReleaseGovernanceEvent ||
            eventType === EventType.TakeGovernanceEvent ||
            eventType === EventType.UpdateGovernanceEvent
        ) {
            console.group("coo-group-event");
            console.log("coo-group", cooGroup);
            console.log("processed message", processedMessage);
            console.groupEnd();
        }

        if (eventType === EventType.ReleaseGovernanceEvent) {
            this.remoteControlStore.removeControlByCooGroupId(cooGroup.id);
            cooGroup.governanceInfo = null;
        }

        if (eventType === EventType.EditTrackingSetEvent) {
            this.cooGroupEditor.cooGroup?.id === cooGroup.id && this.cooGroupStore.getEditTracking(cooGroup.id);
        }

        if (eventType === EventType.TakeGovernanceEvent) {
            const governance = await this.service.getCooGroupGovernance(cooGroup.id);
            if (!governance) return;
            this.remoteControlStore.updateControlGovernanceByCooGroupId(cooGroup.id, governance);
        }

        if (eventType === EventType.UpdateGovernanceEvent || eventType === EventType.ChangeModeEvent) {
            if (!cooGroup.governanceInfo) return;
            const governance = await this.service.getGovernanceById(cooGroup.governanceInfo.id);
            if (!governance) return;
            this.remoteControlStore.updateControlGovernanceByCooGroupId(cooGroup.id, governance);
        }

        if (eventType === EventType.SetEnabledEvent) {
            this.cooGroupStore.loadDetailsById(cooGroup.id);
        }
    }

    public onReconnect() {
        this.cooGroupStore.updateStates();
    }

    private processMessage(msg: TrafficLightSockets.CooGroupStreamMessage): CooGroupDomain.CooGroupMessage {
        const governanceId = msg[5];
        return {
            cooGroupId: msg[0],
            updatedAt: msg[1],
            eventType: msg[6],
            state: {
                id: msg[0],
                activeCycleId: msg[2],
                controlMode: msg[3],
                status: msg[4],
                governanceInfo: governanceId ? new CooGroupDomain.GovernanceInfo(governanceId, msg[7], msg[8]) : null,
            },
        };
    }
}
