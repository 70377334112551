import { BooleanStorageManager } from "lib";
import { BaseLayerController } from "./layer-controller";
import { ICustomLayer } from "./layer";
import { LayerControllerHelper } from "./layer-controller-helper";
import { BaseEvents } from "./layer-emitter";

export class CustomLayerController<Events extends BaseEvents = BaseEvents> extends BaseLayerController<Events> {
    protected layer!: ICustomLayer;
    protected _visibilityStore: BooleanStorageManager;

    constructor(id: string) {
        super(id);
        this._visibilityStore = new BooleanStorageManager(this._visibilityKey);
    }

    public get isVisible(): boolean {
        return this.layer.isVisible;
    }

    public get isInteractive() {
        return this.layer.interactive;
    }

    protected get _visibilityKey() {
        return `${this.id}_visibility`;
    }

    protected _onMapThemeChanged() {
        if (this.map.getLayer(this.layer.id)) return;
        this._addLayer();
    }

    protected _setVisibility(value: boolean): void {
        this._visibilityStore.value = value;
        this._setLayerVisibility(value);
    }

    protected async _onBeforeDestroy() {
        this._removeLayer();
    }

    protected _setInteractivity(value: boolean) {
        this.layer.interactive = value;
    }

    protected _onMapSet() {
        this._onBeforeAddLayer();
        this._addLayer();
        const visibility = this._readLayerVisibilityFromStore();
        this._setLayerVisibility(visibility);
    }

    protected _addLayer() {
        this.map?.addLayer(this.layer);
    }

    protected _removeLayer() {
        this.map?.removeLayer(this.layer.id);
    }

    /** @override */
    protected _onBeforeAddLayer() {}

    private _setLayerVisibility(value: boolean) {
        this.layer.isVisible = value;
        this.map.setLayoutProperty(this.layer.id, "visibility", LayerControllerHelper.getBoolAsVisibility(value));
    }

    private _readLayerVisibilityFromStore() {
        return this._visibilityStore.value ?? false;
    }
}
