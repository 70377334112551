import mapboxgl, { LngLatLike, MapMouseEvent, MercatorCoordinate } from "mapbox-gl";
import { mat4, mat2 } from "gl-matrix";
import { TrafficLightDomain } from "app-domain";
import TrafficLightVisual from "./traffic-light-visual";
import StaticSprite from "./static-sprite";
import DynamicSprite from "./dynamic-sprite";
import Bounds from "./bounds";
import { ICustomLayer } from "../../layer";

const fragmentShader = `
    varying highp vec2 vTextureCoord;
    varying highp float vOpacity;
    uniform sampler2D u_sampler;
    void main() {
        gl_FragColor = texture2D(u_sampler, vec2(vTextureCoord.s, vTextureCoord.t))*vOpacity;
    }
`;

const vertexShader = `
    uniform mat4 u_matrix;
    uniform mat2 u_rotate;
    uniform float u_size;
    attribute vec2 a_pos;
    attribute vec2 a_center;
    attribute vec2 a_texture_coord;
    attribute float a_opacity;
    varying highp float vOpacity;
    varying highp vec2 vTextureCoord;
    void main() {
        gl_Position = u_matrix * vec4(a_center + (u_rotate * a_pos * u_size), 0.0, 1.0);
        vTextureCoord = a_texture_coord;
        vOpacity = a_opacity;
    }
`;

const center = { lat: 55.758934, lng: 37.558131 };

export const MapScale = 5.41843220338983e-8;
export const MapCenter = MercatorCoordinate.fromLngLat(center);

export const BBoxSize = 120;

export const Utils = {
    project: function (latLng: LngLatLike) {
        const p = MercatorCoordinate.fromLngLat(latLng);
        return {
            x: (p.x - MapCenter.x) / MapScale,
            y: -(p.y - MapCenter.y) / MapScale,
        };
    },
};

export interface TextureCoord {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}

const l: mat4 = mat4.create();
const m: mat4 = mat4.create();

mat4.translate(l, mat4.create(), [MapCenter.x, MapCenter.y, MapCenter.z ?? 0]);
mat4.scale(l, l, [MapScale, -MapScale, MapScale]);

function getScale(zoom: number) {
    const zoomLevels = [
        [1, 0.04],
        [8, 0.03],
        [12, 0.2],
        [15, 0.5],
    ];
    const len = zoomLevels.length;
    for (let i = 0; i < len; i++) {
        if (zoom < zoomLevels[i][0]) {
            if (i === 0) return zoomLevels[i][1];
            const z1 = zoomLevels[i - 1][0];
            const s1 = zoomLevels[i - 1][1];
            const z2 = zoomLevels[i][0];
            const s2 = zoomLevels[i][1];
            return ((zoom - z2) * (s2 - s1)) / (z2 - z1) + s2;
        }
    }
    return zoomLevels[len - 1][1];
}

function createTexture(gl: WebGLRenderingContext, image: TexImageSource) {
    const texture = gl.createTexture();
    gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
    gl.bindTexture(gl.TEXTURE_2D, texture);
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR_MIPMAP_NEAREST);
    gl.generateMipmap(gl.TEXTURE_2D);
    return texture;
}

function drawFocus(ctx: CanvasRenderingContext2D, size: number) {
    const scale = size / 1024;
    const size2 = size / 2;
    ctx.strokeStyle = "rgba(3, 102, 252,.38)";
    ctx.lineWidth = 50 * scale;
    ctx.beginPath();
    ctx.arc(size2, size2, size2 / 4, 0, Math.PI * 2);
    ctx.closePath();
    ctx.stroke();
}

function createFocusTexture(gl: WebGLRenderingContext, canvasSize: number) {
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d")!;
    canvas.width = canvasSize * 2;
    canvas.height = canvasSize * 2;
    ctx.scale(2, 2);
    drawFocus(ctx, canvasSize);
    return createTexture(gl, canvas);
}

type TrafficLightVisualRenderHandler = (trafficLight: TrafficLightDomain.TrafficLight) => void;

export interface TrafficLightLayerProps {
    id: string;
    onTrafficLightVisualRender: TrafficLightVisualRenderHandler;
    visibility?: mapboxgl.Visibility;
}

export class TrafficLightLayer implements ICustomLayer {
    // props
    public id: string;

    // interface
    public isVisible: boolean = true;
    public interactive: boolean = true;
    public type: "custom" = "custom";
    public renderingMode: "2d" | "3d" | undefined = "2d";
    public time: number = 0;
    public bounds?: Bounds;
    public zoomScale?: number;
    public angle!: number;
    public aTextCoord: number = 0;
    public aOpacity: number = 0;
    public aPos: number = 0;
    public aCenter: any;
    public uSize!: WebGLUniformLocation;
    public uSampler!: WebGLUniformLocation;
    public uMatrix!: WebGLUniformLocation;
    public uRotation!: WebGLUniformLocation;
    public program!: WebGLProgram;
    public map?: mapboxgl.Map;
    private _isBlink: boolean = false;
    private _visuals: Map<number, TrafficLightVisual> = new Map();
    private _dynamicSprite1!: DynamicSprite;
    private _dynamicSprite2!: DynamicSprite;
    private _dynamicSprite!: DynamicSprite;
    private _staticSprite!: StaticSprite;
    private _canvasSize!: number;
    private _indexBufferSingle: any;
    private _textCoordBufferSingle: any;
    private _staticSpriteTexture: any;
    private _dynamicSpriteTexture: any;
    private _focusTexture: any;
    private _attrBuffer: any;
    private _indexBuffer: any;
    private _textCoordBuffer: any;
    private _activeVisual: TrafficLightVisual | null = null;
    private _currentZoomStaticTexture!: number;
    private _forceRender = false;
    private readonly onTrafficLightVisualRender: TrafficLightVisualRenderHandler;
    private _raf?: number;

    constructor(id: string, onTrafficLightVisualRender: TrafficLightVisualRenderHandler) {
        this.id = id;
        this.onTrafficLightVisualRender = onTrafficLightVisualRender;
        this._updateBounds();
    }

    public get visuals() {
        return this._visuals;
    }

    public get isBlink() {
        return this._isBlink;
    }

    public get activeItem() {
        return this._activeVisual;
    }

    public get activeItemId() {
        return this.activeItem?.id ?? null;
    }

    public setItems(trafficLights: TrafficLightDomain.TrafficLight[]) {
        this.visuals.forEach((visual) => visual.destroy());
        this._visuals.clear();
        for (let trafficLight of trafficLights) {
            const key = trafficLight.id;
            const value = new TrafficLightVisual({
                trafficLight,
                isActive: this._activeVisual?.id === trafficLight.id,
                owner: this,
                onFirstDraw: this.handleVisualFirstDraw,
            });
            this._visuals.set(key, value);
        }

        if (this.activeItemId && !this._visuals.get(this.activeItemId)) {
            this._activeVisual = null;
        }

        this.bringToFrontActiveVisual();
    }

    private handleVisualFirstDraw = (visual: TrafficLightVisual) => {
        this.onTrafficLightVisualRender(visual.trafficLight);
    };

    private _clearAnimationFrame = () => {
        if (typeof this._raf === "number") cancelAnimationFrame(this._raf);
    };

    render(gl: WebGLRenderingContext, matrix: any): void {
        if (!this.map) return;
        mat4.multiply(m, matrix, l);
        gl.useProgram(this.program);

        gl.disable(gl.DEPTH_TEST);
        gl.clear(gl.DEPTH_BUFFER_BIT);
        gl.disable(gl.STENCIL_TEST);

        gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA);
        gl.enable(gl.BLEND);

        let size = 120 * this.zoomScale!;

        const detailMode = this.map.getZoom() >= 15;

        if (!detailMode) size = size / 2;

        gl.uniform1f(this.uSize, size);
        gl.uniformMatrix4fv(this.uMatrix, false, m);

        const rotation = mat2.create();
        mat2.rotate(rotation, rotation, detailMode ? 0 : (-Math.PI * this.map.getBearing()) / 180);

        gl.uniformMatrix2fv(this.uRotation, false, rotation);

        this._render(gl);
        if (this._forceRender) this.map.triggerRepaint();
        this._forceRender = false;
    }

    _updateBounds() {
        if (!this.map) return;
        const mapBounds = this.map.getBounds();
        const sw = MercatorCoordinate.fromLngLat(mapBounds.getSouthWest());
        const ne = MercatorCoordinate.fromLngLat(mapBounds.getNorthEast());
        const x1 = (sw.x - MapCenter.x) / MapScale;
        const y1 = -(sw.y - MapCenter.y) / MapScale;
        const x2 = (ne.x - MapCenter.x) / MapScale;
        const y2 = -(ne.y - MapCenter.y) / MapScale;
        if (!this.bounds) {
            this.bounds = new Bounds(x1, y1, x2, y2);
        } else {
            this.bounds.set(x1, y1, x2, y2);
        }
        const zoom = this.map.getZoom();
        this.zoomScale = zoom < 15 ? getScale(zoom) / Math.pow(2, zoom - 15) : 1;
        const visuals = this._visuals;
        visuals.forEach((visual) => {
            visual._updateBounds();
        });
    }

    /**
     * Инициализация шейдеров, програмы
     * @param {WebGLRenderingContext} gl контекст webgl
     */
    _initGl(gl: WebGLRenderingContext) {
        const vertexShaderWEBGL = gl.createShader(gl.VERTEX_SHADER)!;
        gl.shaderSource(vertexShaderWEBGL, vertexShader);
        gl.compileShader(vertexShaderWEBGL);

        const fragmentShaderWEBGL = gl.createShader(gl.FRAGMENT_SHADER)!;
        gl.shaderSource(fragmentShaderWEBGL, fragmentShader);
        gl.compileShader(fragmentShaderWEBGL);

        this.program = gl.createProgram()!;
        gl.attachShader(this.program, vertexShaderWEBGL);
        gl.attachShader(this.program, fragmentShaderWEBGL);
        gl.linkProgram(this.program);

        this.aPos = gl.getAttribLocation(this.program, "a_pos");
        this.aCenter = gl.getAttribLocation(this.program, "a_center");
        this.aTextCoord = gl.getAttribLocation(this.program, "a_texture_coord");
        this.aOpacity = gl.getAttribLocation(this.program, "a_opacity");
        this.uSize = gl.getUniformLocation(this.program, "u_size")!;
        this.uSampler = gl.getUniformLocation(this.program, "u_sampler")!;
        this.uMatrix = gl.getUniformLocation(this.program, "u_matrix")!;
        this.uRotation = gl.getUniformLocation(this.program, "u_rotate")!;

        this._canvasSize = Math.min(gl.getParameter(gl.MAX_TEXTURE_SIZE) / 2, 2048);
    }

    /**
     * Событие при добавлении слоя на карту
     * @param {mapboxgl.Map} map карта
     * @param {WebGLRenderingContext} gl контекст webgl
     */
    onAdd(map: mapboxgl.Map, gl: WebGLRenderingContext) {
        this._initGl(gl);

        this.map = map;
        const zoom = Math.floor(this.map.getZoom());
        this._currentZoomStaticTexture = zoom;
        this._dynamicSprite1 = new DynamicSprite(this._canvasSize);
        this._dynamicSprite2 = new DynamicSprite(this._canvasSize);
        this._dynamicSprite = this._dynamicSprite1;
        this._staticSprite = new StaticSprite(zoom);

        this.angle = 0;

        this._indexBufferSingle = gl.createBuffer();
        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this._indexBufferSingle);
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array([0, 2, 1, 2, 3, 0]), gl.STATIC_DRAW);

        const textCoordArray = [0, 1, 1, 1, 1, 0, 0, 0];
        this._textCoordBufferSingle = gl.createBuffer();
        gl.bindBuffer(gl.ARRAY_BUFFER, this._textCoordBufferSingle);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(textCoordArray), gl.STATIC_DRAW);

        this._staticSpriteTexture = createTexture(gl, this._staticSprite.canvas);
        this._dynamicSpriteTexture = createTexture(gl, this._dynamicSprite.canvas);
        this._focusTexture = createFocusTexture(gl, this._canvasSize);

        this._attrBuffer = gl.createBuffer();
        this._indexBuffer = gl.createBuffer();
        this._textCoordBuffer = gl.createBuffer();

        let startTime = Math.round(new Date().getTime() / 250);
        const update = () => {
            const time = Math.round(new Date().getTime() / 250);
            if (time > startTime) {
                startTime = time;
                if (this.map) this.map.triggerRepaint();
            }
            this._raf = requestAnimationFrame(update);
        };

        update();

        map.on("zoom", () => this._updateBounds());
        map.on("move", () => this._updateBounds());
        map.on("resize", () => this._updateBounds());
        map.on("drag", () => this._mapDrag());
        map.on("rotate", () => this._updateBounds());
        map.on("mousemove", (e) => this._mouseHandler(e));
        map.on("mouseup", (e) => this._mouseHandler(e));

        this._updateBounds();
    }

    onRemove(map: mapboxgl.Map): void {
        console.log("REMOVED");
        this._clearAnimationFrame();
        map.off("zoom", () => this._updateBounds());
        map.off("move", () => this._updateBounds());
        map.off("resize", () => this._updateBounds());
        map.off("drag", () => this._mapDrag());
        map.off("rotate", () => this._updateBounds());
        map.off("mousemove", (e) => this._mouseHandler(e));
        map.off("mouseup", (e) => this._mouseHandler(e));
    }

    /** Событие мыши для смены курсора
     *  Может использоваться с разными событиями
     */
    _mouseHandler(e: MapMouseEvent) {
        if (!this.map || !this.interactive || !this.isVisible) return;
        if (this.hitTest(e.lngLat) !== null) {
            this.map.getCanvas().style.cursor = "pointer";
            return;
        }
        this.map.getCanvas().style.cursor = "default";
    }

    _mapDrag() {
        this._updateBounds();
    }

    /** Хиттест для поиска выбранного элемента */
    hitTest(lngLat: LngLatLike) {
        for (const [, visual] of this._visuals) {
            if (visual.isVisible() && visual.hitTest(lngLat)) {
                return visual;
            }
        }
        return null;
    }

    /**
     * Метод для активации выбранного элемента
     * @param {number} id номер выбранного элемента
     */
    public setActive(id: NullableNumber) {
        if (!this.map) return;

        if (this._activeVisual) {
            this._activeVisual.isActive = false;
        }

        const item = id ? this._visuals.get(id) : null;

        if (item) {
            item.isActive = true;
        }

        this._activeVisual = item ?? null;
        this.bringToFrontActiveVisual();
    }

    public setSelectedEvent(value: Nullable<TrafficLightDomain.HistoryEvent>) {
        if (this._activeVisual) {
            this._activeVisual.isHistoricalEventViewMode = !!value;
            this._activeVisual.eventData = value;
        }
    }

    public resetActive() {
        if (this._activeVisual) {
            this._activeVisual.isActive = false;
            this._activeVisual = null;
        }
    }

    private bringToFrontActiveVisual() {
        if (!this._activeVisual) return;
        this._activeVisual.bringToFront();
        this._forceRender = true;
        this.map?.triggerRepaint();
    }

    /**
     * Метод отрисовки
     * @param {WebGLRenderingContext} gl контекст webgl
     */
    private _render(gl: WebGLRenderingContext) {
        if (!this.map) return;
        const now = new Date().getTime();
        const zoom = Math.floor(this.map.getZoom());
        const detailMode = zoom >= 15;
        this._isBlink = Math.round(now / 500) % 2 === 0;

        let bufferSprite;

        if (this._dynamicSprite === this._dynamicSprite1) {
            this._dynamicSprite = this._dynamicSprite2;
            bufferSprite = this._dynamicSprite1;
        } else {
            this._dynamicSprite = this._dynamicSprite1;
            bufferSprite = this._dynamicSprite2;
        }

        const visuals: TrafficLightVisual[] = [];

        this._visuals.forEach((visual) => {
            if (visual.isVisible()) {
                visuals.push(visual);
            }
        });

        const buffers = this._fillBuffers(visuals, detailMode);
        if (!buffers) return;

        if (detailMode) {
            bufferSprite.update(visuals, now);
        } else if (zoom !== this._currentZoomStaticTexture) {
            this._staticSprite.update(zoom);
            this._staticSpriteTexture = createTexture(gl, this._staticSprite.canvas);
            this._currentZoomStaticTexture = zoom;
        }

        gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this._indexBuffer);
        gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array(buffers.index), gl.DYNAMIC_DRAW);

        gl.bindBuffer(gl.ARRAY_BUFFER, this._textCoordBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(buffers.texture), gl.DYNAMIC_DRAW);

        gl.enableVertexAttribArray(this.aTextCoord);
        gl.vertexAttribPointer(this.aTextCoord, 2, gl.FLOAT, false, 0, 0);

        gl.bindBuffer(gl.ARRAY_BUFFER, this._attrBuffer);
        gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(buffers.pos), gl.DYNAMIC_DRAW);

        gl.enableVertexAttribArray(this.aCenter);
        gl.vertexAttribPointer(this.aCenter, 2, gl.FLOAT, false, 20, 0);
        gl.enableVertexAttribArray(this.aPos);
        gl.vertexAttribPointer(this.aPos, 2, gl.FLOAT, false, 20, 8);
        gl.enableVertexAttribArray(this.aOpacity);
        gl.vertexAttribPointer(this.aOpacity, 1, gl.FLOAT, false, 20, 16);

        gl.activeTexture(gl.TEXTURE0);
        gl.uniform1i(this.uSampler, 0);

        if (detailMode) {
            gl.bindTexture(gl.TEXTURE_2D, this._dynamicSpriteTexture);
            gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
            gl.texSubImage2D(gl.TEXTURE_2D, 0, 0, 0, gl.RGBA, gl.UNSIGNED_BYTE, this._dynamicSprite.canvas);
            gl.generateMipmap(gl.TEXTURE_2D);
        } else {
            gl.bindTexture(gl.TEXTURE_2D, this._staticSpriteTexture);
        }

        gl.drawElements(gl.TRIANGLES, buffers.index.length, gl.UNSIGNED_SHORT, 0);

        if (detailMode && this._activeVisual !== null) {
            gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, this._indexBufferSingle);

            gl.bindBuffer(gl.ARRAY_BUFFER, this._textCoordBufferSingle);

            gl.enableVertexAttribArray(this.aTextCoord);
            gl.vertexAttribPointer(this.aTextCoord, 2, gl.FLOAT, false, 0, 0);

            gl.bindBuffer(gl.ARRAY_BUFFER, this._attrBuffer);

            gl.enableVertexAttribArray(this.aCenter);
            gl.vertexAttribPointer(this.aCenter, 2, gl.FLOAT, false, 20, 0);
            gl.enableVertexAttribArray(this.aPos);
            gl.vertexAttribPointer(this.aPos, 2, gl.FLOAT, false, 20, 8);
            gl.enableVertexAttribArray(this.aOpacity);
            gl.vertexAttribPointer(this.aOpacity, 1, gl.FLOAT, false, 20, 16);

            gl.pixelStorei(gl.UNPACK_PREMULTIPLY_ALPHA_WEBGL, true);
            gl.bufferData(gl.ARRAY_BUFFER, new Float32Array(this._activeVisual.getFocusArray(0.5)), gl.DYNAMIC_DRAW);
            gl.bindTexture(gl.TEXTURE_2D, this._focusTexture);

            gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
        }
    }

    private enableVertexAttribArray(gl: WebGLRenderingContext) {}

    /**
     * Метод заполнения буферов и отрисовки
     * @param {TrafficLightVisual[]} visuals список элементов
     * @param {boolean} detailMode
     */
    _fillBuffers(visuals: TrafficLightVisual[], detailMode: boolean) {
        if (!this.map) return;
        const zoom = this.map.getZoom();
        const indexArray = [];
        const attrArray = [];
        const textCoordArray = [];
        let opacity = 0.6;
        let offset = 0;
        let indexCount = 0;

        for (let i = 0, len = visuals.length; i < len; i++) {
            const visual = visuals[i];
            const tl = visual.trafficLight;

            let phase = zoom > 13 && tl.currentPhase ? tl.currentPhase.phase.num : 0;
            if (phase < 0) phase = 0;

            if (detailMode) {
                const textCoord = this._dynamicSprite.items[visual.spriteKey];
                if (textCoord) {
                    attrArray.push(...(zoom > 18 ? visual.getPoaArray(opacity) : visual.posArray));

                    indexArray[indexCount++] = offset;
                    indexArray[indexCount++] = offset + 2;
                    indexArray[indexCount++] = offset + 1;

                    indexArray[indexCount++] = offset + 2;
                    indexArray[indexCount++] = offset + 3;
                    indexArray[indexCount++] = offset;

                    offset += 4;

                    textCoordArray.push(
                        textCoord.x1,
                        textCoord.y1,
                        textCoord.x2,
                        textCoord.y1,
                        textCoord.x2,
                        textCoord.y2,
                        textCoord.x1,
                        textCoord.y2
                    );
                }
            } else {
                //control mode
                let textCoord = this._staticSprite.items[`controlmode_${tl.controlMode.code}`];
                if (textCoord) {
                    attrArray.push(...visual.posArray);

                    indexArray[indexCount++] = offset;
                    indexArray[indexCount++] = offset + 2;
                    indexArray[indexCount++] = offset + 1;

                    indexArray[indexCount++] = offset + 2;
                    indexArray[indexCount++] = offset + 3;
                    indexArray[indexCount++] = offset;
                    offset += 4;
                    textCoordArray.push(
                        textCoord.x1,
                        textCoord.y1,
                        textCoord.x2,
                        textCoord.y1,
                        textCoord.x2,
                        textCoord.y2,
                        textCoord.x1,
                        textCoord.y2
                    );
                }
                //status
                textCoord = this._staticSprite.items[`status_${tl.status.code}`];
                if (textCoord) {
                    attrArray.push(...visual.posArray);

                    indexArray[indexCount++] = offset;
                    indexArray[indexCount++] = offset + 2;
                    indexArray[indexCount++] = offset + 1;

                    indexArray[indexCount++] = offset + 2;
                    indexArray[indexCount++] = offset + 3;
                    indexArray[indexCount++] = offset;
                    offset += 4;

                    textCoordArray.push(
                        textCoord.x1,
                        textCoord.y1,
                        textCoord.x2,
                        textCoord.y1,
                        textCoord.x2,
                        textCoord.y2,
                        textCoord.x1,
                        textCoord.y2
                    );
                }

                /** Номер фазы */
                if (phase > 0) {
                    textCoord = this._staticSprite.items[`phase_${phase}`];
                    if (textCoord) {
                        attrArray.push(...visual.posArray);

                        indexArray[indexCount++] = offset;
                        indexArray[indexCount++] = offset + 2;
                        indexArray[indexCount++] = offset + 1;

                        indexArray[indexCount++] = offset + 2;
                        indexArray[indexCount++] = offset + 3;
                        indexArray[indexCount++] = offset;
                        offset += 4;
                        textCoordArray.push(
                            textCoord.x1,
                            textCoord.y1,
                            textCoord.x2,
                            textCoord.y1,
                            textCoord.x2,
                            textCoord.y2,
                            textCoord.x1,
                            textCoord.y2
                        );
                    }
                }
            }

            if (visual.isActive && !detailMode) {
                attrArray.push(...visual.getFocusArray(0.5));

                indexArray[indexCount++] = offset;
                indexArray[indexCount++] = offset + 2;
                indexArray[indexCount++] = offset + 1;

                indexArray[indexCount++] = offset + 2;
                indexArray[indexCount++] = offset + 3;
                indexArray[indexCount++] = offset;
                offset += 4;

                const textCoord = this._staticSprite.items.focus;

                if (!textCoord) console.warn("sprite not found focus");

                textCoordArray.push(
                    textCoord.x1,
                    textCoord.y1,
                    textCoord.x2,
                    textCoord.y1,
                    textCoord.x2,
                    textCoord.y2,
                    textCoord.x1,
                    textCoord.y2
                );
            }
        }

        return {
            index: indexArray,
            texture: textCoordArray,
            pos: attrArray,
        };
    }
}
