import { VMModule } from "../types";
import { TVCamerasListViewModel } from "./tv-cameras-list-view-model";
import { useGetDeps } from "./use-get-deps";

export { TVCamerasListViewModel };

export default {
    model: TVCamerasListViewModel,
    useGetDeps,
} as VMModule<typeof TVCamerasListViewModel>;
