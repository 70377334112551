import { CodeDictionary } from "../types";
import { WarehouseMonitoringStatus } from "../warehouse-entity/warehouse-monitoring-status";

type CodeDictionaryMap = { [key in WarehouseMonitoringStatus]: CodeDictionary<WarehouseMonitoringStatus> };

export const monitoringStatusCodeDictionaryMap: CodeDictionaryMap = {
    [WarehouseMonitoringStatus.Works]: {
        name: "Работает",
        color: "#289F46",
        code: WarehouseMonitoringStatus.Works,
    },
    [WarehouseMonitoringStatus.NoData]: {
        name: "Нет данных",
        color: "#83909A",
        code: WarehouseMonitoringStatus.NoData,
    },
    [WarehouseMonitoringStatus.WorksWithRemarks]: {
        name: "Работает с замечаниями",
        color: "#FECB00",
        code: WarehouseMonitoringStatus.WorksWithRemarks,
    },
    [WarehouseMonitoringStatus.NoWorks]: {
        name: "Не работает",
        color: "#FF3A2F",
        code: WarehouseMonitoringStatus.NoWorks,
    },
};

export const monitoringStatusCodeDictionaryList = Object.values(monitoringStatusCodeDictionaryMap);
