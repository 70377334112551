import { useAppState } from "app/store";
import { createContext, memo, ReactNode, useContext, useState } from "react";
import { WindowInstance } from "shared/window-manager";
import { SpeedCameraCardViewModel } from "./speed-camera-card-view-model";

const Context = createContext<SpeedCameraCardViewModel>({} as SpeedCameraCardViewModel);

type Props = {
    window: WindowInstance;
    children: ReactNode;
};

export const useSpeedCameraCardVM = () => useContext(Context);

export const SpeedCameraCardVMProvider = memo((props: Props) => {
    const { speedCameras, mapController } = useAppState();
    const [vm] = useState(
        () =>
            new SpeedCameraCardViewModel({
                store: speedCameras,
                window: props.window,
                mapController,
            })
    );

    return <Context.Provider value={vm}>{props.children}</Context.Provider>;
});
