import { makeAutoObservable } from "mobx";
import { ScenariosStore } from "app/store/scenarios";
import { LaunchData, Scenario, StateStreamData, Status } from "app-domain/scenario";
import { ScenarioStream, ScenarioStreamSubscriber } from "api";

export type ScenarioDetailsViewModelDeps = {
    errors: ScenariosStore["errors"];
    scenarioSelected: Scenario | null;
    scenariosStore: ScenariosStore;
};

export class ScenarioDetailsViewModel {
    public id?: number;
    public actions: Scenario["actions"] = [];
    public state: Scenario["state"] | undefined;
    private _errors: ScenarioDetailsViewModelDeps["errors"];
    private _subscriber: ScenarioStreamSubscriber;
    private _scenarioStream = new ScenarioStream();
    private _actionsDescriptionOpen: Map<number, boolean> = new Map();
    private _confirmForm: "delete" | "launch" | "finish" | undefined;
    private _deleteScenario: ScenariosStore["deleteScenario"];
    private _launchScenario: ScenariosStore["launchScenario"];
    private _finishScenario: ScenariosStore["finishScenario"];
    private _enabledScenario: ScenariosStore["enabledScenario"];
    private _extendScenario: ScenariosStore["extendScenario"];

    constructor(deps: ScenarioDetailsViewModelDeps) {
        const { scenariosStore } = deps;
        this.id = deps.scenarioSelected?.id || 0;
        this.actions = deps.scenarioSelected?.actions || [];
        this.state = deps.scenarioSelected?.state;
        this._errors = deps.errors;
        this._subscriber = { onMessage: this.onMessage };
        this._deleteScenario = scenariosStore.deleteScenario;
        this._launchScenario = scenariosStore.launchScenario;
        this._finishScenario = scenariosStore.finishScenario;
        this._enabledScenario = scenariosStore.enabledScenario;
        this._extendScenario = scenariosStore.extendScenario;
        this.initSubscription(this.id!);
        makeAutoObservable(this);
    }

    public get errors() {
        return this._errors;
    }

    public get isVisibleDeleteConfirm() {
        return this._confirmForm === "delete";
    }

    public get isVisibleLaunchConfirm() {
        return this._confirmForm === "launch";
    }

    public get isVisibleFinishConfirm() {
        return this._confirmForm === "finish";
    }

    public get isActiveScenario() {
        return this.state?.status === Status.Active;
    }

    public setConfirmForm = (form: "delete" | "launch" | "finish") => {
        this._confirmForm = form;
    };

    public clearConfirmForm = () => {
        this._confirmForm = undefined;
    };

    public confirmDeleteScenario = () => {
        this._deleteScenario(this.id!);
    };

    public confirmFinishScenario = () => {
        this._finishScenario(this.id!);
        this.clearConfirmForm();
    };

    public get actionsDescriptionOpen() {
        return this._actionsDescriptionOpen;
    }

    public getIsOpenDescription = (index: number) => {
        return !!this._actionsDescriptionOpen.get(index);
    };

    public setOpenDescription = (index: number) => {
        const prevActiveDescription = !!this._actionsDescriptionOpen.get(index);
        this._actionsDescriptionOpen.set(index, !prevActiveDescription);
    };

    public confirmLaunchScenario = (data: LaunchData) => {
        const requestData: LaunchData = {};

        if (data.stopAt) {
            requestData.stopAt = data.stopAt;
        } else if (data.duration) {
            requestData.duration = data.duration;
        }

        if (!this.id) return;
        this._launchScenario(this.id, data);
        this._confirmForm = undefined;
    };

    public finishScenario = () => {
        this._finishScenario(this.id!);
        this._confirmForm = undefined;
    };

    public onLaunchClick = () => {
        this.setConfirmForm("launch");
    };

    public onFinishClick = () => {
        this.setConfirmForm("finish");
    };

    public enabledScenario = (value: boolean) => {
        this._enabledScenario(this.id!, value);
    };

    public extendScenario = () => {
        this._extendScenario(this.id!, 18000);
    };

    public destroy() {
        if (!this._scenarioStream || !this.id) return;
        this._scenarioStream.unsubscribeFromScenario([this.id], this._subscriber);
        this._scenarioStream.destroy();
    }

    private initSubscription = async (id: number) => {
        await this._scenarioStream.init();
        this._scenarioStream.subscribeToScenario([id], this._subscriber);
    };

    private onMessage = (newState: StateStreamData) => {
        if (!this.state) return;

        this.state = {
            ...this.state,
            id: newState.id,
            status: newState.status,
            type: newState.eventType,
            controlMode: newState.controlMode,
            stateDate: new Date(newState.date * 1000).toString(),
            governance: {
                ...this.state.governance,
                id: newState.governanceId,
                userId: newState.governanceUserId,
                username: newState.governanceUsername,
            },
        };
    };
}
