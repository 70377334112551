export class SVGImage {
    public url: string;
    private blob: Blob;
    /**
     * @param content - Контент SVG в виде строки
     * */
    constructor(content: string) {
        this.blob = new Blob([content], { type: "image/svg+xml" });
        this.url = global.URL.createObjectURL(this.blob);
    }
}
