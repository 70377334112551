import { APITypes } from "api";
import { SpeedCamera } from "app-domain/speed-camera";

export class SpeedCamerasListMapper {
    public fromAPI(camera: APITypes.SpeedCamera): SpeedCamera {
        return new SpeedCamera(camera.id, {
            issueStatus: camera.assetStatusId,
            location: { lat: camera.lat, lng: camera.lng },
            addresses: camera.addresses?.map((address) => address.address),
            monitoringStatus: camera.monitoringStatus,
            num: camera.num,
        });
    }
}
