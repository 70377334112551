// import { useCallback } from "react";
// import { Routing } from "routing";
// import { useAppState } from "./use-app-state";

export const useSelectDetector = () => {
    // const { detectorListStore } = useAppState();
    // const navigate = Routing.Detectors.Hooks.useDetectorListNavigator();

    // return useCallback(
    //     (id: number) => {
    //         const detector = detectorListStore.getById(id);

    //         if (!detector) return;

    //         detectorListStore.chooseItem(id);
    //         navigate();
    //     },
    //     [detectorListStore, navigate]
    // );
    return () => {};
};
