import { LayerKey } from "map-controller";
import { AppRouter, Pages } from "app/routing";
import {
    WarehouseEntityLayerBinding,
    IWarehouseEntitiesService,
    WarehouseEntityLayerBindingDeps,
} from "./warehouse-entity-layer-binding";
import { IAppWindowManager } from "../../store/app-windows";

export type DetectorsLayerBindingDeps = Omit<WarehouseEntityLayerBindingDeps, "service"> & {
    detectors: IWarehouseEntitiesService;
    windowManager: IAppWindowManager;
};

export class DetectorLayerBinding extends WarehouseEntityLayerBinding {
    private windowManager: IAppWindowManager;

    constructor(deps: DetectorsLayerBindingDeps) {
        super(LayerKey.Detectors, {
            service: deps.detectors,
            mapController: deps.mapController,
            pageNamesToTrack: [Pages.DetectorList, Pages.DetectorDetails],
            navigationPage: Pages.DetectorList,
        });
        this.windowManager = deps.windowManager;
    }

    protected handleClick = (id: number) => {
        if (AppRouter.getCurrentRoute().name === Pages.DetectorList) {
            AppRouter.navigate(Pages.DetectorList, { id: id.toString() });
        }
        this.mapController.setActive(id, this.layerKey);
        this.windowManager.spawnDetectorCard(id);
    };
}
