import { action, computed, makeObservable } from "mobx";
import { BooleanProperty } from "lib";

interface FilterState {
    search: string;
    setSearch(value: string): void;
    clearSearch(): void;
}

export type ListViewModelDeps<Entity> = {
    store: ListService<Entity>;
    filter?: FilterState;
    onFilterOpen: VoidFunction;
};

export interface ListService<Entity> {
    filteredList: Entity[];
    isListLoading: boolean;
    isListFetched: boolean;
    loadItems(): Promise<void>;
    getById(id: number): Entity | void | null;
}

export class ListViewModel<Entity> {
    private store: ListService<Entity>;
    private filter?: FilterState;
    private _isSearchOpen: BooleanProperty;
    private _onFilterOpen: VoidFunction;

    constructor(deps: ListViewModelDeps<Entity>) {
        makeObservable(this, {
            isListLoading: computed,
            list: computed,
            itemsCount: computed,
            searchValue: computed,
            onClick: action.bound,
            onDoubleClick: action.bound,
            onArrowClick: action.bound,
            onSearchChange: action.bound,
            onSearchOpen: action.bound,
            onSearchClose: action.bound,
            onSearchClear: action.bound,
            onFilterOpen: action.bound,
        });
        this.store = deps.store;
        this.filter = deps.filter;
        this._onFilterOpen = deps.onFilterOpen;
        this._isSearchOpen = new BooleanProperty(this.filter ? this.filter.search.length > 0 : false);
        if (!this.store.isListFetched) this.store.loadItems();
    }

    public get searchValue() {
        return this.filter?.search ?? "";
    }

    public get isSearchOpen() {
        return this._isSearchOpen.value;
    }

    public get isListLoading() {
        return this.store.isListLoading;
    }

    public get list() {
        return this.store.filteredList;
    }

    public get itemsCount() {
        return this.store.filteredList.length;
    }

    public onFilterOpen() {
        this._onFilterOpen();
    }

    public onSearchClear() {
        this.filter?.clearSearch();
    }
    public onSearchOpen() {
        this._isSearchOpen.setTruly();
    }
    public onSearchClose() {
        this._isSearchOpen.setFalsy();
        this.filter?.clearSearch();
    }
    public onSearchChange(value: string) {
        this.filter?.setSearch(value);
    }
    public onClick(...params: any) {}
    public onDoubleClick(...params: any) {}
    public onArrowClick(...params: any) {}

    protected getById(id?: number) {
        if (!id) return null;
        return this.store.getById(id);
    }
}
