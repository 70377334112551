import { CooGroupDomain } from "app-domain";
import { makeAutoObservable, runInAction } from "mobx";
import { CooGroupRemoteControl } from "./coo-group-remote-control";
import { CooGroupRemoteControlAdapter } from "./coo-group-remote-control";
import { ICooGroupRemoteControlsService, IRemoteControlsCooGroupStore } from "./coo-group-remote-controls.types";
import { WindowInstance } from "shared/window-manager";

type StopControlsResult = {
    promises: Promise<void>[];
    ids: number[];
};

interface WindowService {
    spawnCooGroupRemoteControl(id: number, options: { isCollapsed: boolean }): WindowInstance;
}

export class CooGroupRemoteControls {
    private controlsMap: Map<number, CooGroupRemoteControl> = new Map();

    constructor(
        private store: IRemoteControlsCooGroupStore,
        private service: ICooGroupRemoteControlsService,
        private currentUserId: string,
        private windowService: WindowService
    ) {
        makeAutoObservable(this);
    }

    public get remoteControlsList() {
        return Array.from(this.controlsMap.values());
    }

    public get visibleRemoteControls() {
        return this.remoteControlsList.filter((remoteControl) => !remoteControl.isCollapsed);
    }

    public get currentUserNotVisibleRemoteControls() {
        return this.remoteControlsList.filter(
            (remoteControl) => remoteControl.isCollapsed && remoteControl.governance.profileId === this.currentUserId
        );
    }

    public updateControlGovernanceByCooGroupId(cooGroupId: number, governance: CooGroupDomain.Governance) {
        const control = this.getByCooGroupId(cooGroupId);

        if (!control) {
            this.createRemoteControl(governance);
        } else {
            control.governance = governance;
        }
    }

    public removeControlByCooGroupId(cooGroupId: number) {
        this.controlsMap.delete(cooGroupId);
    }

    public async loadItems() {
        const governanceList = await this.service.getCurrentUserGovernanceList();
        runInAction(() => {
            for (const governance of governanceList) {
                this.controlsMap.set(governance.cooGroupId, this.createRemoteControl(governance));
            }
        });
    }

    public async startRemoteControl(cooGroupId: number): Promise<CooGroupRemoteControl | null> {
        const cooGroup = this.store.getById(cooGroupId);
        if (!cooGroup) return null;
        const governance = await this.service.createControlGovernance(cooGroupId);
        cooGroup.governanceInfo = new CooGroupDomain.GovernanceInfo(
            governance.id,
            governance.userId,
            governance.username
        );
        const control = this.createRemoteControl(governance, false);
        return control;
    }

    public async stopRemoteControl(cooGroupId: number) {
        const cooGroup = this.store.getById(cooGroupId);
        if (!cooGroup) return;
        await this.service.removeControlGovernance(cooGroup.id);
        const control = this.controlsMap.get(cooGroupId);
        control?.destroy();
        runInAction(() => {
            this.controlsMap.delete(cooGroupId);
            cooGroup.governanceInfo = null;
        });
    }

    public async stopAllRemoteControls() {
        try {
            const { promises, ids } = this.remoteControlsList.reduce<StopControlsResult>(
                (result, control) => {
                    if (!control.isRunningCycle) {
                        result.ids.push(control.id);
                        result.promises.push(this.service.removeControlGovernance(control.id));
                    }
                    return result;
                },
                { promises: [], ids: [] }
            );
            await Promise.all(promises);
            runInAction(() => {
                for (const id of ids) {
                    const control = this.controlsMap.get(id);
                    control?.destroy();
                    this.controlsMap.delete(id);
                }
            });
        } catch (e) {}
    }

    public getByCooGroupId(cooGroupId: number): Nullable<CooGroupRemoteControl> {
        return this.controlsMap.get(cooGroupId) ?? null;
    }

    public async loadByCooGroupId(cooGroupId: number): Promise<Nullable<CooGroupRemoteControl>> {
        const control = this.getByCooGroupId(cooGroupId);
        if (control) return control;
        const governance = await this.service.getCooGroupGovernance(cooGroupId);
        if (!governance) return null;
        return this.createRemoteControl(governance);
    }

    private createRemoteControl(governance: CooGroupDomain.Governance, isCollapsed = true) {
        const window = this.windowService.spawnCooGroupRemoteControl(governance.cooGroupId, { isCollapsed });
        const control = new CooGroupRemoteControl(
            governance.cooGroupId,
            governance,
            new CooGroupRemoteControlAdapter(),
            window
        );
        this.controlsMap.set(control.id, control);
        return control;
    }
}
