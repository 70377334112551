import { createContext, ReactNode, useContext, useState } from "react";
import { WindowInstance } from "shared/window-manager";
import { useAppState } from "../../store";
import { DetectorCardViewModel } from "./detector-card-view-model";
import { DetectorCardService } from "./detector-card-service";

const Context = createContext<DetectorCardViewModel>({} as DetectorCardViewModel);

type Props = {
    window: WindowInstance;
    children: ReactNode;
};

export const DetectorCardVMProvider = (props: Props) => {
    const { detectors, mapController } = useAppState();
    const [vm] = useState(
        () =>
            new DetectorCardViewModel({
                detectorStore: detectors,
                service: new DetectorCardService(),
                mapController,
                window: props.window,
            })
    );

    return <Context.Provider value={vm}>{props.children}</Context.Provider>;
};

export const useDetectorCardVM = () => useContext(Context);
