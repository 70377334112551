import { makeAutoObservable } from "mobx";
import { Phase, CustomCycle, INewCustomCycle, CyclePhase } from "app-domain/traffic-light";

export enum PopupType {
    SaveCycleAs,
    CommandLaunch,
    AddPhase,
    RemovePhase,
    CycleChangeConfirmation,
    UnderControlNotification,
}

type PopupParam<T, OwnParams = {}> = Readonly<
    {
        type: T;
    } & OwnParams
>;

interface RunCommandParams {
    startAt?: DateTimeString;
    stopAt?: DateTimeString;
    duration?: number;
    cycleId?: number;
    cycleSource?: number;
}

export type PopupParams =
    | PopupParam<
          PopupType.AddPhase,
          {
              data: CyclePhase[];
              onSubmit: (phase: Phase) => void;
          }
      >
    | PopupParam<PopupType.RemovePhase, { onClose: VoidFunction; onSubmit: VoidFunction }>
    | PopupParam<PopupType.SaveCycleAs, { data: INewCustomCycle; onSubmit: (newCycle: CustomCycle) => void }>
    | PopupParam<PopupType.CommandLaunch, { onSubmit: (data: RunCommandParams) => void }>
    | PopupParam<PopupType.CycleChangeConfirmation, { onSubmit: VoidFunction }>
    | PopupParam<PopupType.UnderControlNotification, { onClose: VoidFunction }>;

export class Popup {
    private _params: PopupParams | null = null;
    private _isOpen: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    public get isOpen() {
        return this._isOpen;
    }

    public get type() {
        return this._params?.type ?? null;
    }

    public get params() {
        return this._params;
    }

    public setParams(params: PopupParams) {
        this._params = params;
    }

    public clearParams() {
        this._params = null;
    }

    public open = (params?: PopupParams) => {
        if (params) this._params = params;
        this._isOpen = true;
    };

    public close = () => {
        this._isOpen = false;
    };
}
