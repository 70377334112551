export enum EntityType {
    /** Светофорный объект */
    TrafficLight = "StoryplayActionTargetFaclity",
    /** Лента координации */
    CooGroup = "StoryplayActionTargetCooGroup",
    /** Табло отображения информации */
    // Board = "StoryplayActionTargetVMSBoard",
}

export const EntityName = {
    [EntityType.TrafficLight]: "Светофорный объект",
    [EntityType.CooGroup]: "Лента координации",
    // [EntityType.Board]: "Табло отображения информации",
};

export const eventObjectList = [
    {
        uid: EntityType.TrafficLight,
        objName: "Светофорный объект",
    },
    {
        uid: EntityType.CooGroup,
        objName: "Лента координации",
    },
    // {
    //     uid: EntityType.Board,
    //     objName: "Табло отображения информации",
    // },
];
