import { APITypes } from "api";
import { TVCamera } from "app-domain/tv-camera";

export class TVCameraListMapper {
    public fromAPI(camera: APITypes.TVCameraDetails): TVCamera {
        const addresses = camera.addresses?.map(({ address }) => address) ?? [];
        return new TVCamera(camera.id, {
            num: camera.num,
            location: { lat: camera.lat, lng: camera.lng },
            addresses,
            issueStatus: camera.assetStatusId,
            monitoringStatus: camera.monitoringStatusId,
            streamNums: camera.streamNums,
        });
    }
}
