import { createContext, useState, ReactNode, useContext } from "react";
import { useAppState } from "app/store";
import { CooGroupListViewModel } from "./coo-group-list-view-model";

const Context = createContext({} as CooGroupListViewModel);

type Props = {
    children: ReactNode;
};

export const useCooGroupListVM = () => useContext(Context);

export const CooGroupListVMProvider = (props: Props) => {
    const { cooGroupStore, openFilter } = useAppState();
    const [vm] = useState(
        () =>
            new CooGroupListViewModel({
                store: cooGroupStore,
                onFilterOpen: () => openFilter("coo-group-list"),
            })
    );
    return <Context.Provider value={vm}>{props.children}</Context.Provider>;
};
