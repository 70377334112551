import { FC, memo, useContext, MouseEvent, useCallback } from "react";
import { MenuContext } from "../menu.context";
import { UID, ClickHandler } from "../menu.types";
import * as Markup from "./menu-item.styles";

interface Props {
    uid?: UID;
    padding?: string;
    isActive?: boolean;
    isDisabled?: boolean;
    onClick?: ClickHandler;
}

export const MenuItem: FC<Props> = memo((props) => {
    const { children, uid, isDisabled, padding, isActive, onClick } = props;

    const context = useContext(MenuContext);

    const handleClick = useCallback(
        (event: MouseEvent<HTMLDivElement>) => {
            if (isDisabled) return;
            if (onClick) {
                return onClick({ event, uid });
            }
            context.onClick?.({ event, uid });
        },
        [onClick, isDisabled, uid, context]
    );

    return (
        <Markup.Container isActive={isActive} isDisabled={isDisabled} padding={padding} onClick={handleClick}>
            {children}
        </Markup.Container>
    );
});
