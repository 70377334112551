import { WindowInstance, WindowInstanceEvents } from "shared/window-manager";
import { getCenteredXPosition, getCenteredYPosition } from "./app-windows.utils";

type WindowParams = {
    width: number;
    height: number;
    x: number;
    y: number;
};

type StorageShape = {
    [userId: string]: {
        [key: string]: WindowParams;
    };
};
/**
 *  NOTE: Временное хранилище, использующее local storage
 *  TODO: Вынести в общую абстракцию
 */
class AppRemoteControlsStorage {
    private storage = global.localStorage;
    private key = "remoteControlParams";

    constructor(private userId: string) {}

    public writeWindowParams(id: number, type: string, params: WindowParams) {
        const storageShape = this.readStorageShape() ?? {};
        const userWindows = storageShape[this.userId] ?? {};
        const windowKey = this.getWindowKey(id, type);
        userWindows[windowKey] = params;
        const serialized = JSON.stringify({ ...storageShape, [this.userId]: userWindows });
        this.storage.setItem(this.key, serialized);
    }

    public readWindowParams(id: number, type: string): WindowParams | null {
        const windows = this.readUserWindows();
        return windows?.[this.getWindowKey(id, type)] ?? null;
    }

    private getWindowKey(id: number, type: string) {
        return `${type}-${id}`;
    }

    private readStorageShape() {
        try {
            const shape = this.storage.getItem(this.key);
            if (!shape) return null;
            const parsed: StorageShape = JSON.parse(shape);
            return parsed;
        } catch (e) {
            return null;
        }
    }

    private readUserWindows() {
        const shape = this.readStorageShape();
        return shape?.[this.userId] ?? null;
    }
}

export class AppRemoteControlsHelper {
    private storage: AppRemoteControlsStorage;

    constructor(currentUserId: string) {
        this.storage = new AppRemoteControlsStorage(currentUserId);
    }

    public getRemoteControlBounds(id: number, type: string) {
        const params = this.storage.readWindowParams(id, type);
        const width = params?.width ?? global.innerWidth * 0.5;
        const height = params?.height ?? global.innerHeight * 0.8;
        const x = params?.x ?? getCenteredXPosition(width);
        const y = params?.y ?? getCenteredYPosition(height);
        return {
            minHeight: 350,
            minWidth: 440,
            width,
            height,
            x,
            y,
        };
    }

    public handleRemoteControlSizeChange: WindowInstanceEvents["resize"] = (window) => {
        this.storage.writeWindowParams(window.contentId, window.content as string, this.getWindowParams(window));
    };

    public handleRemoteControlPositionChange: WindowInstanceEvents["positionChange"] = (window) => {
        this.storage.writeWindowParams(window.contentId, window.content as string, this.getWindowParams(window));
    };

    private getWindowParams(window: WindowInstance) {
        return {
            x: window.x,
            y: window.y,
            width: window.width,
            height: window.height,
        };
    }
}
