import { reaction } from "mobx";
import { LayerKey } from "map-controller";
import { AppRouter, Pages } from "app/routing";
import { TrafficLight } from "app-domain/traffic-light";
import { TrafficLightStore } from "../../store/traffic-light-store";
import { LayerBinding, MapBindingDeps } from "./layer-binding";

export type TrafficLightsBindingDeps = MapBindingDeps & {
    trafficLights: TrafficLightStore;
};

export class TrafficLightsLayerBinding extends LayerBinding {
    private trafficLights: TrafficLightStore;

    constructor(deps: TrafficLightsBindingDeps) {
        super(deps);
        this.trafficLights = deps.trafficLights;
    }

    public bind(): void {
        const layer = this.mapController.getLayer(LayerKey.TrafficLights);
        this.addReaction(
            reaction(
                () => this.trafficLights.filteredList,
                (list) => layer.setData(list),
                { fireImmediately: true }
            )
        );
        this.addReaction(
            reaction(
                () => AppRouter.getCurrentRoute(),
                (route) => {
                    if (
                        (route.name === Pages.TrafficLightList || route.name === Pages.TrafficLightDetails) &&
                        route.params?.id
                    ) {
                        const id = Number(route.params.id);
                        this.mapController.setActive(id, LayerKey.TrafficLights);
                        this.mapController.flyToEntity(id, LayerKey.TrafficLights);
                    }
                },
                { fireImmediately: true }
            )
        );
        layer.on(layer.events.click, this.handleClick);
        layer.on(layer.events.visualRender, this.handleVisualRender);
    }

    public destroy(): void {
        super.destroy();
        const layer = this.mapController.getLayer(LayerKey.TrafficLights);
        layer.off(layer.events.click, this.handleClick);
        layer.off(layer.events.visualRender, this.handleVisualRender);
    }

    private handleClick = (id: number) => {
        const route = AppRouter.getCurrentRoute();
        const page =
            route.name === Pages.TrafficLightList || route.name === Pages.TrafficLightDetails
                ? route.name
                : Pages.TrafficLightDetails;
        AppRouter.navigate(page, { ...route.params, id: id.toString() });
    };

    private handleVisualRender = (trafficLight: TrafficLight) => {
        this.trafficLights.loadById(trafficLight.id);
    };
}
