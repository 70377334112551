import { BackActionType } from "api/endpoints/types";
import { ActionType, EntityType } from "app-domain/scenario";

export const getPropAction = (action: BackActionType) => {
    let propAction: ActionType = {
        type: action.$type,
        id: action.targetId,
    };

    if (action.$type === EntityType.TrafficLight) {
        if (typeof action.desiredCycleId === "number") propAction.cycle = action.desiredCycleId;
        if (typeof action.desiredPhase === "number") propAction.phase = action.desiredPhase;
        if (typeof action.desiredStatus === "number") propAction.status = action.desiredStatus;
    } else if (action.$type === EntityType.CooGroup) {
        if (typeof action.desiredCycleId === "number") propAction.cycle = action.desiredCycleId;
    }
    return propAction;
};
