import { Drawer } from "diagrams/drawers";
import { HeadData } from "./head-drawer.types";

/** @deprecated */
export class HeadDrawer extends Drawer<HeadData> {
    protected draw = () => {
        if (!this.data) return;
        this.ctx.fillStyle = "#080808";
        this.ctx.font = this.data.font;
        this.ctx.textBaseline = "bottom";
        this.ctx.textAlign = "left";
        this.ctx.fillText(this.data.text, this.data.x, this.data.y);
    };
}
