import { makeAutoObservable, observable, runInAction } from "mobx";
import { BooleanProperty, FormTextField } from "lib";
import { SearchEntityType } from "app-domain/dashboard";
import { Utils } from "shared";
import { IAppWindowManager } from "../../store/app-windows";
import { AppSearchResult } from "./app-search-result";
import { AppSearchController } from "./app-search-controller";

type ResultsMap = Map<SearchEntityType, AppSearchResult<SearchEntityType>[]>;

export type AppSearchViewModelDeps = {
    windowManager: IAppWindowManager;
};

export class AppSearchViewModel {
    private results: ResultsMap = new Map();
    private controller: AppSearchController;
    private _search = new FormTextField();
    private _isExpanded = new BooleanProperty(false);
    private _isInFocus = new BooleanProperty(false);
    private _isLoading = new BooleanProperty(false);

    constructor(deps: AppSearchViewModelDeps) {
        this.controller = new AppSearchController(deps.windowManager);
        makeAutoObservable<AppSearchViewModel, "results">(this, {
            results: observable.ref,
        });
    }

    public get value() {
        return this._search.value;
    }

    public get areResultsVisible() {
        return this.isFocused && this.hasResults && !this.isLoading;
    }

    public get isFocused() {
        return this._isInFocus.value;
    }

    public get isLoading() {
        return this._isLoading.value;
    }

    public get isExpanded() {
        return this._isExpanded.value;
    }

    private get hasResults() {
        if (this.results.size === 0) return false;
        for (let list of this.results.values()) {
            if (list.length > 0) return true;
        }
        return false;
    }

    public onChange = (value: string) => {
        this._search.value = value;
        if (value.length === 0) return (this.results = new Map());
        this.querySearch();
    };

    public onClear = () => {
        this._search.value = "";
        this.results = new Map();
    };

    public onFocusIn = () => {
        this._isInFocus.setTruly();
    };

    public onFocusOut = () => {
        this._isInFocus.setFalsy();
    };

    public onExpand = () => {
        this._isExpanded.setTruly();
    };

    public onCancel = () => {
        this._isExpanded.setFalsy();
        this.onClear();
    };

    public getResultsByType(type: SearchEntityType) {
        return this.results.get(type) ?? [];
    }

    private querySearch = Utils.delayToLast(async () => {
        if (this.value.length < 3) return;
        this._isLoading.setTruly();
        try {
            const value = this.value.trim();
            const searchResults = await this.controller.search(value);
            runInAction(() => {
                const map: ResultsMap = new Map();
                for (let resultGroup of searchResults) {
                    const results = resultGroup.results.map(
                        (res) =>
                            new AppSearchResult({
                                id: res.id,
                                type: resultGroup.type as SearchEntityType,
                                data: res,
                                onClick: () => this.controller.handleSearchResultClick(resultGroup.type, res.id),
                            })
                    );
                    map.set(resultGroup.type, results);
                }
                this.results = map;
            });
        } catch (e) {}
        this._isLoading.setFalsy();
    }, 800);
}
