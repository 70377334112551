import { makeAutoObservable, observable } from "mobx";
import { CooGroupDomain } from "app-domain";
import { CustomCycleFactory } from "./cycle-factory";
import { GreenStreetForm } from "./green-street-form";
import { CoordinationForm } from "./coordination-form";
import { ICooGroupCycleService, ICycleResult } from "./cycle-editing-state.types";

export class CycleEditingState {
    /** Форма редактирования цикла */
    public form: Nullable<CoordinationForm | GreenStreetForm> = null;
    /** Id цикла помеченного для удаления */
    public deletableCycleId: NullableNumber = null;
    public isConfirmationVisible = false;
    public isSubmitting = false;
    public validationErrors: string[] | null = null;
    private factory = new CustomCycleFactory();
    private cooGroup: Nullable<CooGroupDomain.CooGroup> = null;

    constructor(private service: ICooGroupCycleService) {
        makeAutoObservable(this, {
            form: observable.ref,
        });
    }

    public setCooGroup(cooGroup: CooGroupDomain.CooGroup) {
        this.cooGroup = cooGroup;
    }

    public setNewEditableCycle(type: CooGroupDomain.CycleType) {
        if (!this.cooGroup) return;
        const cycle = this.factory.create(this.cooGroup?.id, type);
        this.createForm(cycle);
    }

    public setCycleForEdit(id: number) {
        if (!this.cooGroup) return;
        const cycle = this.cooGroup.getCycleById(id);
        if (!cycle) return;
        this.createForm(cycle);
    }

    public setCycleForCopy(id: number) {
        if (!this.cooGroup) return;
        const cycle = this.cooGroup.getCycleById(id);
        if (!cycle) return;
        const newCycle = this.factory.create(this.cooGroup.id, cycle.type);
        newCycle.schedules = cycle.schedules?.slice();
        newCycle.cycles = cycle.cycles?.slice();
        newCycle.cooGroupId = cycle.cooGroupId;
        newCycle.greenLinePhases = cycle.greenLinePhases;

        this.createForm(newCycle);
    }

    public setCycleForDelete = (id: number) => {
        this.deletableCycleId = id;
    };

    public applyCycleDeletion = async () => {
        if (!this.cooGroup || !this.deletableCycleId) return;
        this.setIsSubmitting(true);
        await this.service.deleteCycle(this.cooGroup.id, this.deletableCycleId);
        this.cooGroup.deleteCycle(this.deletableCycleId);
        this.setIsSubmitting(false);
        this.cancelCycleDeletion();
        this.cancelEditing();
    };

    public readonly handleSubmit = async () => {
        this.clearValidationErrors();
        if (!this.form) return;
        if (!this.form.isNew && !this.isConfirmationVisible) {
            return this.setConfirmationVisibility(true);
        }
        const result = await this.submitCycleChanges();
        if (!result) return;
        this.setConfirmationVisibility(false);
        this.cancelEditing();
    };

    public readonly handleSubmissionCancel = () => {
        this.setConfirmationVisibility(false);
    };

    public readonly cancelEditing = () => {
        this.form = null;
        this.deletableCycleId = null;
        this.clearValidationErrors();
    };

    public readonly clearValidationErrors = () => {
        this.validationErrors = null;
    };

    private readonly submitCycleChanges = async () => {
        if (!this.cooGroup || !this.form) return false;
        this.setIsSubmitting(true);
        const method = this.service[this.form.isNew ? "createCycle" : "updateCycle"].bind(this.service);
        const buildedCycle = this.form.build();
        if (!buildedCycle) return;
        const result = await method(this.cooGroup.id, buildedCycle);
        this.setIsSubmitting(false);
        return this.processSubmissionResult(result);
    };

    private async processSubmissionResult(result: ICycleResult): Promise<boolean> {
        if (result.type === "cycle") {
            this.cooGroup![this.form?.isNew ? "addCycle" : "updateCycle"](result.data);
            return true;
        }
        this.validationErrors = result.data;
        return false;
    }

    private createForm(cycle: CooGroupDomain.CustomCycle) {
        if (!this.cooGroup) return;
        if (cycle.type === CooGroupDomain.CycleType.Coordination) {
            return (this.form = new CoordinationForm(this.cooGroup.id, cycle));
        }
        return (this.form = new GreenStreetForm(this.cooGroup, cycle));
    }

    private setIsSubmitting(value: boolean) {
        this.isSubmitting = value;
    }

    private setConfirmationVisibility(value: boolean) {
        this.isConfirmationVisible = value;
    }

    private cancelCycleDeletion() {
        this.deletableCycleId = null;
    }
}
