import { makeAutoObservable } from "mobx";
import { Scenario, StateStreamData, StateType } from "app-domain/scenario";
import { ScenarioStream, ScenarioStreamSubscriber } from "api";
import { AppRouter, Pages } from "app/routing";
import { ScenariosStore } from "app/store";
import { BooleanProperty } from "lib";

export type ScenariosListViewModelDeps = { store: ScenariosStore; onFilterOpen: VoidFunction };

export class ScenariosListViewModel {
    private _store: ScenariosStore;
    private _list: Scenario[] = [];
    private _scenarioStream = new ScenarioStream();
    private _subscriber: ScenarioStreamSubscriber;
    private _onFilterOpen: VoidFunction;
    private _isSearchOpen;

    constructor(deps: ScenariosListViewModelDeps) {
        this._list = deps.store.filteredList;
        this._store = deps.store;
        this._isSearchOpen = new BooleanProperty(this._store.filter.search.length > 0);
        this._onFilterOpen = deps.onFilterOpen;

        this._subscriber = { onMessage: this.onMessage };
        this._initSubscription();

        makeAutoObservable<ScenariosListViewModel>(this);
    }

    public get list() {
        return this._list;
    }

    public get foundItemsCount() {
        return this._list.length;
    }

    public get isSearchOpen() {
        return this._isSearchOpen.value;
    }

    public get searchValue() {
        return this._store.filter?.search ?? "";
    }

    public destroy() {
        if (!this._scenarioStream) return;
        const ids = this.list.map((item) => item.id);
        this._scenarioStream.unsubscribeFromScenario(ids, this._subscriber);
        this._scenarioStream.destroy();
    }

    public onClick() {}

    public onDoubleClick(id: number) {
        AppRouter.navigate(Pages.ScenarioDetails, { id: id.toString() });
    }

    public onArrowClick(id: number) {
        this.onDoubleClick(id);
    }

    public onFilterOpen = () => {
        this._onFilterOpen();
    };
    public onSearchClear = () => {
        this._store.filter?.clearSearch();
    };
    public onSearchOpen = () => {
        this._isSearchOpen.setTruly();
    };
    public onSearchClose = () => {
        this._isSearchOpen.setFalsy();
        this._store.filter?.clearSearch();
    };
    public onSearchChange = (value: string) => {
        this._store.filter?.setSearch(value);
    };
    public onAddScenario = () => {
        AppRouter.navigate(Pages.ScenarioEdit, { id: "new" });
    };

    private _changeState = (id: number, state: StateType) => {
        for (let i = 0; this._list?.length > i; i++) {
            const item = this._list[i];
            if (item.id === id) {
                this._list[i] = { ...item, state };
                return;
            }
        }
    };

    private _initSubscription = async () => {
        await this._scenarioStream.init();
        this._scenarioStream.subscribeToScenario(
            this.list.map((item) => item.id),
            this._subscriber
        );
    };

    private onMessage = ({ id, ...streamState }: { id?: number } & StateStreamData) => {
        const newState = {
            id: id,
            status: streamState.status,
            type: streamState.eventType,
            controlMode: streamState.controlMode,
            stateDate: new Date(streamState.date * 1000).toString(),
            governance: {
                id: streamState.governanceId,
                userId: streamState.governanceUserId,
                username: streamState.governanceUsername,
            },
        };

        id && this._changeState(id, newState as StateType);
    };
}
