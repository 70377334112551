import { WarehouseIssueStatus } from "../../warehouse-entity/warehouse-issue-status";
import { CodeDictionary } from "../../types";

type CodeDictionaryMap = { [key in WarehouseIssueStatus]: CodeDictionary<WarehouseIssueStatus> };

export const issueStatusCodeDictionaryMap: CodeDictionaryMap = {
    [WarehouseIssueStatus.Dismantling]: {
        name: "Демонтаж",
        color: "#83909A",
        code: WarehouseIssueStatus.Dismantling,
    },
    [WarehouseIssueStatus.Works]: {
        name: "Работает",
        color: "#289F46",
        code: WarehouseIssueStatus.Works,
    },
    [WarehouseIssueStatus.WorksWithRemarks]: {
        name: "Работает с замечаниями",
        color: "#FECB00",
        code: WarehouseIssueStatus.WorksWithRemarks,
    },
    [WarehouseIssueStatus.NoWorks]: {
        name: "Не работает",
        color: "#FF3A2F",
        code: WarehouseIssueStatus.NoWorks,
    },
    [WarehouseIssueStatus.RoutineWork]: {
        name: "Регламентные работы",
        color: "#5ACAFD",
        code: WarehouseIssueStatus.RoutineWork,
    },
};

export const issueStatusCodeDictionaryList = Object.values(issueStatusCodeDictionaryMap);
