import mapboxgl from "mapbox-gl";
import { TrafficScoreDomain } from "app-domain";
import { APIRegistry } from "api";
import { DtmTrafficLayer } from "./dtm-traffic-layer";
import { getDataTypeByRange, getTileUrl } from "./dtm-traffic-controller.utils";
import { MapBoxLikeLayerController } from "../mapbox-like-layer-controller";

const minuteTimestamp = 60000;

export class DtmTrafficController extends MapBoxLikeLayerController {
    /** url для подгрузки тайлов */
    private _tileUrl: string = "";
    /** режим отображения слоя */
    private _viewMode: TrafficScoreDomain.ViewMode = TrafficScoreDomain.ViewMode.LastPeriod;
    /** дата начала */
    private _from: NullableDate = null;
    /** дата окончния */
    private _to: NullableDate = null;

    constructor(id: string, private _beforeLayerId: string) {
        super(id);
        this.layer = new DtmTrafficLayer(this.id);
    }

    public setViewMode(viewMode: TrafficScoreDomain.ViewMode) {
        this._viewMode = viewMode;
        this._updateSource();
    }

    public setRange(timestamp: NullableNumber, split: TrafficScoreDomain.Split) {
        this._from = timestamp ? new Date(timestamp) : null;
        this._to = timestamp ? new Date(timestamp + split * minuteTimestamp) : null;

        this._updateSource();
    }

    protected _onMapThemeChanged = async () => {
        this._addSource();
        if (this.map.getLayer(this.layer.id)) this._removeLayer();
        this._addLayer();
    };

    protected async _onMapSet() {
        const visibility = this._readLayerVisibilityFromStore();
        await this._onBeforeAddLayer();
        this._addLayer();
        this._setLayerVisibility(visibility);
    }

    protected async _onBeforeAddLayer() {
        const range = await this._loadLastRange();
        this._initBindings();
        if (!range) return;
        this._setTileUrl(range.from, range.to);
        this._addSource();
    }

    protected async _onBeforeDestroy() {
        this.map.removeSource(this.id);
    }

    protected _addLayer(): void {
        this.map.addLayer(this.layer as DtmTrafficLayer, this._beforeLayerId);
    }

    private async _updateSource() {
        if (this._viewMode === TrafficScoreDomain.ViewMode.LastPeriod) {
            const range = await this._loadLastRange();
            if (!range) return;
            this._setTileUrl(range.from, range.to);
        }

        if (this._viewMode === TrafficScoreDomain.ViewMode.Range && this._from && this._to) {
            this._setTileUrl(this._from, this._to);
        }

        const source = this.map.getSource(this.id) as mapboxgl.VectorSourceImpl;

        if (!source) return;

        source.setTiles([this._tileUrl]);
    }

    private _setTileUrl(from: Date, to: Date) {
        const dataType = getDataTypeByRange(from, to);
        this._tileUrl = getTileUrl({ from: from.toISOString(), to: to.toISOString(), dataType });
    }

    private _addSource() {
        if (this.map.getSource(this.id) || !this._tileUrl) return;

        this.map.addSource(this.id, {
            type: "vector",
            tiles: [this._tileUrl],
        });
    }

    private _initBindings() {
        this.setRange.bind(this);
        this.onDestroy.bind(this);
        this.setViewMode.bind(this);
        this.setVisibility.bind(this);
    }

    private async _loadLastRange() {
        try {
            const range = await APIRegistry.dtmTrafficAPI.getLastPeriod();
            return { from: new Date(range.start), to: new Date(range.finish) };
        } catch (e) {
            console.warn("Can not load DTM range");
            return null;
        }
    }
}
