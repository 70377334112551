import { useAppState } from "app/store";
import { createContext, ReactNode, useContext, useState } from "react";
import { WindowInstance } from "shared/window-manager";
import { TVCameraCardViewModel } from "./tv-camera-card-view-model";

const Context = createContext<TVCameraCardViewModel>({} as TVCameraCardViewModel);

type Props = {
    window: WindowInstance;
    children: ReactNode;
};

export const useTVCameraCardVM = () => useContext(Context);

export const TVCameraCardVMProvider = (props: Props) => {
    const { mapController, tvCameras } = useAppState();
    const [vm] = useState(
        () =>
            new TVCameraCardViewModel({
                store: tvCameras,
                mapController,
                window: props.window,
            })
    );
    return <Context.Provider value={vm}>{props.children}</Context.Provider>;
};
