import { Slider } from "@megapolis/uikit/slider/slider";

export enum PositionScale {
    top = "top",
    bottom = "bottom",
}

export type SliderType = React.ComponentProps<typeof Slider> & {
    positionScale?: PositionScale;
    minScaleElements?: number;
    isInputVisible?: boolean;
    lastScaleCaption?: string | React.ReactNode;

    scaleCaption?: (index: number) => React.ReactNode;
};
