import { APITypes } from "api";
import { Detector } from "app-domain/detector";

export class DetectorListMapper {
    public fromAPI(apiDetector: APITypes.DetectorDetails): Detector {
        const detector = new Detector(apiDetector.id, {
            issueStatus: apiDetector.assetStatusId,
            monitoringStatus: apiDetector.monitoringStatusId,
            location: { lat: apiDetector.lat, lng: apiDetector.lng },
            num: apiDetector.num,
            addresses:
                apiDetector.addresses?.reduce<string[]>((result, shape) => {
                    if (typeof shape.address === "string") result.push(shape.address);
                    return result;
                }, []) ?? [],
        });
        return detector;
    }
}
