import { BaseApi } from "api/utils";
import { QueryParamsBuilder } from "lib";
import { TVCamera, TVCameraList, GetTvCameraFilter } from "./tv-camera-api.types";

export class TVCameraAPI extends BaseApi {
    public getCameras(filter?: GetTvCameraFilter): Promise<TVCameraList> {
        const params = new QueryParamsBuilder()
            .addIterableParam("assetstatusid", filter?.issueStatuses)
            .addIterableParam("monitoringstatusid", filter?.monitoringStatuses)
            .addParam("search", filter?.search)
            .toString(true);

        const url = this.buildUrl("" + params);

        return this.request({ url });
    }

    public getCamera(id: number): Promise<TVCamera> {
        return this.request({ url: this.buildUrl(`/${id}`) });
    }

    protected buildUrl(url: string) {
        return `${this.baseUrl}/warehouse/camera${url}`;
    }
}
