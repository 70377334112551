import { makeAutoObservable, observable, runInAction } from "mobx";
import { Detector, SummaryMetric } from "app-domain/detector";
import { LayerKey, MapController } from "map-controller";
import { AppRouter, Pages } from "app/routing";
import { WindowInstance } from "shared/window-manager";
import { BooleanProperty } from "lib";
import { Detectors } from "../../store";
import { DetectorCardService } from "./detector-card-view-model.types";

export type DetectorCardViewModelDeps = {
    detectorStore: Detectors;
    service: DetectorCardService;
    mapController: MapController;
    window: WindowInstance;
};

export class DetectorCardViewModel {
    private detectorId: number;
    private detectorStore: Detectors;
    private mapController: MapController;
    private window: WindowInstance;
    private _detector?: Detector;
    private _service: DetectorCardService;
    private _summaryMetric?: SummaryMetric;
    private _historicalSummaryMetric: SummaryMetric[] = [];
    private _isLoading = new BooleanProperty(false);

    constructor(deps: DetectorCardViewModelDeps) {
        this.detectorId = deps.window.id;
        this.detectorStore = deps.detectorStore;
        this._service = deps.service;
        this.mapController = deps.mapController;
        this.window = deps.window;

        makeAutoObservable<
            DetectorCardViewModel,
            "detectorStore" | "_service" | "_summaryMetric" | "_historicalSummaryMetric"
        >(this, {
            detectorStore: false,
            _service: false,
            _summaryMetric: observable.ref,
            _historicalSummaryMetric: observable.ref,
        });
        this.load();
    }

    public get isLoading() {
        return this._isLoading.value;
    }

    public get detector() {
        return this._detector;
    }

    public get summaryMetric() {
        return this._summaryMetric;
    }

    public get historicalSummaryMetric() {
        return this._historicalSummaryMetric;
    }

    public onLocateClick = () => {
        this.mapController.flyToEntity(this.detectorId, LayerKey.Detectors);
    };

    public onExpandClick = () => {
        AppRouter.navigate(Pages.DetectorDetails, { id: this.detectorId.toString() });
        this.window.close();
    };

    public onCloseClick = () => {
        this.window.close();
    };

    private load = async () => {
        this._isLoading.setTruly();
        const results = await Promise.all([
            this.detectorStore.loadItem(this.detectorId, { includeDirections: true }),
            this._service.getSummaryMetric(this.detectorId),
            this._service.getHistoricalSummaryMetric(this.detectorId),
        ]);
        runInAction(() => {
            this._detector = results[0];
            this._summaryMetric = results[1];
            this._historicalSummaryMetric = results[2];
        });
        this._isLoading.setFalsy();
    };
}
