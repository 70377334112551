import { EventEmitter } from "shared";

export type BaseEvents = {
    load: VoidFunction;
    destroy: VoidFunction;
    visibilityChange: (visibility: boolean) => void;
    interactivityChange: (interactivityChange: boolean) => void;
};

export const BaseEventKeys = {
    load: "load",
    destroy: "destroy",
    visibilityChange: "visibilityChange",
    interactivityChange: "interactivityChange",
} as const;

export class LayerEmitter<Events extends BaseEvents = BaseEvents> extends EventEmitter<Events> {}
