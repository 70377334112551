import { BaseApi } from "api/utils";
import { QueryParamsBuilder } from "lib";
import { DetectorList, GetDetectorFilter } from "./detector-api.types";

export class DetectorAPI extends BaseApi {
    public async getDetectors(filter?: GetDetectorFilter): Promise<DetectorList> {
        const params = new QueryParamsBuilder()
            .addIterableParam("assetstatusid", filter?.issueStatuses)
            .addIterableParam("monitoringstatusid", filter?.monitoringStatuses)
            .addParam("search", filter?.search)
            .toString(true);

        const url = this.buildUrl("" + params);

        const result = await this.request({ url });
        return result;
    }

    public async getDetector(id: number) {
        const url = this.buildUrl(`/${id}`);
        return this.request({ url });
    }

    protected buildUrl(url: string) {
        return `${this.baseUrl}/warehouse/detector${url}`;
    }
}
