import mapboxgl from "mapbox-gl";
import { LayerEmitter, BaseEventKeys, BaseEvents } from "./layer-emitter";

export abstract class BaseLayerController<Events extends BaseEvents = BaseEvents> {
    public readonly id: string;
    // карта появится после init хука, который вызовет контроллер карты при инициализации
    // @ts-expect-error
    protected map: mapboxgl.Map;
    protected emitter: LayerEmitter = new LayerEmitter<Events>();

    constructor(id: string) {
        this.id = id;
    }

    public abstract get isVisible(): boolean;
    public abstract get isInteractive(): boolean;

    // @ts-ignore
    public on: LayerEmitter<Events>["on"] = (...params) => this.emitter.on(...params);
    // @ts-ignore
    public off: LayerEmitter<Events>["off"] = (...params) => this.emitter.off(...params);
    // @ts-ignore
    public once: LayerEmitter<Events>["once"] = (...params) => this.emitter.once(...params);

    public get events() {
        return BaseEventKeys;
    }

    /** @internal */
    public onInit(_map: mapboxgl.Map) {
        this.map = _map;
        this._onMapSet?.(_map);
        this.emitter.emit("load");
    }

    /** @internal */
    public onDestroy() {
        this._onBeforeDestroy();
        this.emitter.emit("destroy");
    }

    /** @internal */
    public onMapThemeChanged() {
        this._onMapThemeChanged?.();
    }

    /** @override */
    public getEntityPosition?(id: number): LatLng | GeoJSON.Position[] | void;

    /** @override */
    public setActive?(id: NullableNumber): void;

    /** @override */
    public setData?(data: any): void;

    public setInteractivity(value: boolean) {
        if (!this._setInteractivity) return;
        this._setInteractivity?.(value);
        this.emitter.emit(this.events.interactivityChange, value);
    }

    public setVisibility(value: boolean) {
        this._setVisibility(value);
        this.emitter.emit(this.events.visibilityChange, value);
    }

    /** @override */
    protected abstract _setVisibility(value: boolean): void;

    /** @override */
    protected _setInteractivity?(value: boolean): void;

    /** @override */
    protected _onMapSet?(map: mapboxgl.Map): void;

    /** @override */
    protected _onMapThemeChanged?(): void;

    /** @override */
    protected _onBeforeDestroy() {}
}
