import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useAppState } from "../../store";
import { DetectorDetailsViewModel } from "./detector-details-view-model";

const Context = createContext({} as DetectorDetailsViewModel);

type Props = {
    id?: number;
    children: ReactNode;
};

export const useDetectorDetailsVM = () => useContext(Context);

export const DetectorDetailsVMProvider = observer(({ id, children }: Props) => {
    const { detectors, appWindows, mapController } = useAppState();
    const [vm] = useState(
        () =>
            new DetectorDetailsViewModel({
                detectorStore: detectors,
                mapController,
                windowManager: appWindows,
            })
    );

    useEffect(() => {
        id && vm.setDetectorId(id);
    }, [vm, id]);

    return <Context.Provider value={vm}>{children}</Context.Provider>;
});
