import { APIRegistry } from "api";
import { SummaryMetric } from "app-domain/detector";
import { DetectorCardService as IDetectorCardService } from "./detector-card-view-model.types";

export class DetectorCardService implements IDetectorCardService {
    private detectorServiceAPI = APIRegistry.detectorServiceAPI;

    public async getSummaryMetric(detectorId: number): Promise<SummaryMetric> {
        const data = await this.detectorServiceAPI.getSummaryById(detectorId);
        return {
            laneId: data.laneId,
            occ: data.occ,
            speed: data.speed,
            timestamp: new Date(data.timestamp),
            utilization: data.utilization,
            volume: data.volume,
        };
    }

    public async getHistoricalSummaryMetric(detectorId: number): Promise<SummaryMetric[]> {
        const data = await this.detectorServiceAPI.getSummaryHistoryById(detectorId);
        return data.map((item) => ({
            laneId: item.laneId,
            occ: item.occ,
            speed: item.speed,
            timestamp: new Date(item.timestamp),
            utilization: item.utilization,
            volume: item.volume,
        }));
    }
}
