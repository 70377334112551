import {
    SearchEntityType,
    SearchEntityTrafficLight,
    SearchEntityCooGroup,
    SearchEntityWithMonitoring,
} from "app-domain/dashboard";

export type SearchResultData<Type extends SearchEntityType = SearchEntityType> =
    Type extends SearchEntityType.TrafficLight
        ? SearchEntityTrafficLight
        : Type extends SearchEntityType.CooGroup
        ? SearchEntityCooGroup
        : SearchEntityWithMonitoring;

export class AppSearchResult<Type extends SearchEntityType = SearchEntityType> {
    public id: number;
    public type: Type;
    public data: SearchResultData<Type>;
    private _onClick: (data: SearchResultData<Type>) => void;

    constructor(options: {
        id: number;
        type: Type;
        data: SearchResultData<Type>;
        onClick: (data: SearchResultData<Type>) => void;
    }) {
        this.id = options.id;
        this.type = options.type;
        this.data = options.data;
        this._onClick = options.onClick;
    }

    onClick = () => this._onClick(this.data);
}
