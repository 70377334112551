import { drawCyclePhase, drawCycleDirection, Common } from "lib/canvas/drawing";
import { getPhaseColor } from "app-domain/traffic-light/utils";
import { Utils } from "shared";
import { BoundingBox } from "lib";
import { Visualizer, VisualizerProps } from "./visualizer";
import { VisualizationData, DirectionVisData, PhaseVisData } from "./visualizers.types";

type Times = {
    [key: number]: unknown;
};

export type RenderedPhaseData = PhaseVisData & {
    box: BoundingBox;
};

type OnPhaseHoverCb = (phaseData: RenderedPhaseData) => void;

type DefaultCycleVisualizerProps = VisualizerProps & {
    onPhaseHover?: OnPhaseHoverCb;
};

export class DefaultCycleVisualizer extends Visualizer {
    private onPhaseHover?: OnPhaseHoverCb;
    private pxPerSecond = 1;
    private isRenderedCycleTime: boolean = false;
    private renderedPhases: RenderedPhaseData[] = [];

    constructor(props: DefaultCycleVisualizerProps) {
        super(props);
        this.onPhaseHover = props.onPhaseHover;
        this.container.view.addEventListener("mouseenter", this.handleMouseEnter);
    }

    public destroy() {
        this.container.view.removeEventListener("mouseenter", this.handleMouseEnter);
        super.destroy();
    }

    protected _visualize(data: VisualizationData): void {
        this.renderedPhases = [];
        this.ctx.font = this.textFont;
        const times: Times = {};
        let x = this.leftOffset;
        let prevPhaseTime = 0;
        const totalGap = this.barGapHorizontal * (data.phases.length - 1);
        const containerWidth = this.getContainerWidth();
        const scale = (containerWidth - totalGap) / containerWidth;
        const cycleTime = data.cycleTime || 1;
        this.pxPerSecond = containerWidth / cycleTime;

        for (let i = 0; i < data.phases.length; i++) {
            const phase = data.phases[i];
            const tPhase = phase.tPhase || 1;
            const nextPhase = data.phases[i + 1] || data.phases[0];
            let width = tPhase * this.pxPerSecond * scale;

            this.drawPhase(x, width, phase);

            this.renderedPhases.push({
                ...phase,
                box: new BoundingBox(x, width, this.topOffset, this.barHeight),
            });

            let isAllowedTo = false;
            let directionY = data.cycleTime ? this.directionsStartY : this.zeroDirectionsStartY;
            for (let di = 0; di < data.directions.length; di++) {
                const direction = data.directions[di];
                const isAllowed = !!phase.directionByNum[direction.number];

                if (nextPhase) {
                    const nextIsAllowed = nextPhase?.directionByNum?.[direction.number];
                    isAllowedTo = nextIsAllowed === isAllowed;
                } else {
                    isAllowedTo = false;
                }

                const drawDirection = isAllowedTo
                    ? { ...direction, tGreenBlink: 0, tYellow: 0, tRed: 0, tRedYellow: 0 }
                    : direction;

                this.drawDirection(x, directionY, width, isAllowed, drawDirection, i === 0);
                directionY += this.barGapHorizontal + this.barHeight;
            }
            times[phase.tPhase + prevPhaseTime] = true;
            prevPhaseTime += phase.tPhase;
            x += width + this.barGapHorizontal;
        }

        if (data.cycleTime) {
            this.isRenderedCycleTime = true;
            this.drawTimescale(data, times);
        } else {
            this.isRenderedCycleTime = false;
        }
    }

    protected getViewHeight(): number {
        if (!this.data) return 0;
        let height = this.topOffset + this.barHeight + this.timescaleSegmentLineHeight + this.globalFontSize;
        if (this.areDirectionsVisible) {
            const length = this.isRenderedCycleTime ? this.data.directions.length : this.data.directions.length - 1;
            height += this.directionsTopOffset + length * (this.barHeight + this.directionsGap);
        }
        return height;
    }

    private get directionsStartY() {
        return (
            this.topOffset +
            this.barHeight +
            this.timescaleSegmentLineHeight +
            this.globalFontSize +
            this.directionsTopOffset
        );
    }

    private get zeroDirectionsStartY() {
        return this.topOffset + this.timescaleSegmentLineHeight + this.globalFontSize + this.directionsTopOffset;
    }

    private drawPhase(x: number, width: number, phase: PhaseVisData) {
        return drawCyclePhase(this.ctx, {
            x,
            width,
            y: this.topOffset,
            height: this.barHeight,
            pxPerSecond: this.pxPerSecond,
            color: getPhaseColor(phase.phaseNumber),
            grid: this.gridProps,
            phaseNumber: phase.phaseNumber,
            seconds: phase.tPhase,
        });
    }

    private drawDirection(
        x: number,
        y: number,
        width: number,
        isAllowed: boolean,
        direction: DirectionVisData,
        isIconVisible: boolean
    ) {
        drawCycleDirection(this.ctx, {
            x,
            y,
            width,
            height: this.barHeight,
            data: {
                isAllowed,
                tDelay: direction.tDelay,
                tGreenBlink: direction.tGreenBlink,
                tRed: direction.tRed,
                tRedYellow: direction.tRedYellow,
                tYellow: direction.tYellow,
            },
            img: isIconVisible ? direction.icon?.img : void 0,
            text: isIconVisible ? `${direction.number}Н` : void 0,
            pxPerSecond: this.pxPerSecond,
            grid: this.gridProps,
        });
    }

    private drawTimescale(data: VisualizationData, times: Times) {
        Common.drawTimescale(this.ctx, {
            x: this.leftOffset,
            y: this.topOffset + this.barHeight,
            lineWidth: 1,
            lineColor: this.colors.content.tertiary,
            segmentLineHeight: this.timescaleSegmentLineHeight,
            timeSeconds: data.cycleTime,
            pxPerSecond: this.pxPerSecond,
            drawText: (_, props) => this.drawTimescaleNumbers(props, times, data),
        });
    }

    private drawTimescaleNumbers = (
        { second, lineX, lineStartY }: Common.DrawTimescaleTextProps,
        times: Times,
        data: VisualizationData
    ) => {
        const ctx = this.ctx;
        const containerWidth = this.getContainerWidth();
        if (second in times === false && second !== 0 && second !== data.cycleTime) return;
        const text = second.toString();
        const measure = ctx.measureText(text);
        let offset = 0;
        ctx.fillStyle = this.colors.content.tertiary;
        ctx.textBaseline = "top";
        ctx.textAlign = "center";
        if (lineX - measure.width < this.leftOffset) offset += measure.width * 0.5;
        if (lineX + measure.width > containerWidth) offset -= measure.width * 0.5;
        ctx.fillText(second.toString(), lineX + offset, lineStartY + this.timescaleSegmentLineHeight);
    };

    private handleMouseEnter = () => {
        this.container.view.addEventListener("mousemove", this.handleMouseMove);
    };

    private handleMouseMove = Utils.delayToLast((e: MouseEvent) => {
        const phase = this.renderedPhases.find((phase) => phase.box.isPointIn(e.offsetX, e.offsetY));
        if (phase) this.onPhaseHover?.(phase);
    }, 200);
}
