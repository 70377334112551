import { makeAutoObservable } from "mobx";
import { WindowManager, WindowInstance } from "shared/window-manager";
import { WindowContentType } from "./window-content-type";
import { AppRemoteControlsHelper } from "./app-remote-controls-helper";
import { getCardDefaultBounds } from "./app-windows.utils";

export type AppWindow = WindowInstance<WindowContentType>;

type SpawnCardOptions = {
    point?: Nullable<Point>;
};

type SpawnRemoteControlOptions = {
    isCollapsed?: boolean;
};

export interface IAppWindowManager {
    spawnDetectorCard(id: number, options?: SpawnCardOptions): AppWindow;
    spawnSpeedCameraCard(id: number, options?: SpawnCardOptions): AppWindow;
    spawnTVCameraCard(id: number, options?: SpawnCardOptions): AppWindow;
    spawnTrafficLightRemoteControl(id: number, options?: SpawnRemoteControlOptions): AppWindow;
    spawnCooGroupRemoteControl(id: number, options: SpawnRemoteControlOptions): AppWindow;
}

type Point = { x: number; y: number };

export class AppWindows implements IAppWindowManager {
    private manager = new WindowManager();
    private remoteControlsHelper: AppRemoteControlsHelper;

    constructor(currentUserId: string) {
        this.remoteControlsHelper = new AppRemoteControlsHelper(currentUserId);
        makeAutoObservable<AppWindows, "remoteControlsHelper">(this, { remoteControlsHelper: false });
    }

    public get expandedWindows() {
        return this.manager.expandedWindows as AppWindow[];
    }

    public spawnTrafficLightRemoteControl(id: number, options?: SpawnRemoteControlOptions): AppWindow {
        return this.spawnRemoteControl(id, WindowContentType.TrafficLightRemoteControl, options);
    }

    public spawnCooGroupRemoteControl(id: number, options?: SpawnRemoteControlOptions): AppWindow {
        return this.spawnRemoteControl(id, WindowContentType.CooGroupRemoteControl, options);
    }

    public spawnDetectorCard(id: number, options?: SpawnCardOptions) {
        return this.spawnCard(id, WindowContentType.DetectorCard, options);
    }

    public spawnSpeedCameraCard(id: number, options?: SpawnCardOptions) {
        return this.spawnCard(id, WindowContentType.SpeedCameraCard, options);
    }

    public spawnTVCameraCard(id: number, options?: SpawnCardOptions) {
        return this.spawnCard(id, WindowContentType.TVCameraCard, options);
    }

    private spawnRemoteControl(id: number, content: WindowContentType, options?: SpawnRemoteControlOptions) {
        const bounds = this.remoteControlsHelper.getRemoteControlBounds(id, content);
        const window = this.manager.spawn({
            id,
            content,
            isCollapsed: options?.isCollapsed,
            minHeight: bounds.minHeight,
            minWidth: bounds.minWidth,
            width: bounds.width,
            height: bounds.height,
            isResizable: true,
            x: bounds.x,
            y: bounds.y,
        });
        window.on(window.events.resize, this.remoteControlsHelper.handleRemoteControlSizeChange);
        window.on(window.events.positionChange, this.remoteControlsHelper.handleRemoteControlPositionChange);
        return window;
    }

    private spawnCard(id: number, content: WindowContentType, options?: SpawnCardOptions) {
        const bounds = getCardDefaultBounds();
        const point = options?.point;
        return this.manager.spawn({
            id,
            content,
            x: point?.x ?? bounds.x,
            y: point?.y ?? bounds.y,
            minHeight: bounds.minHeight,
            isResizable: false,
        });
    }
}
