import { createViewModelProviderAPI } from "./view-model.utils";
import { ViewModelType } from "./use-view-model";

const { useTVCamerasListVM, TVCamerasListVMProvider } = createViewModelProviderAPI(
    ViewModelType.TVCamerasList,
    "TVCamerasListVM"
);

const { useMapControls, MapControlsProvider } = createViewModelProviderAPI(
    ViewModelType.MapControls,
    "MapControls",
    (vm, props) => (vm.isReady ? props.children : null)
);

const { useScenariosListVM, ScenariosListVMProvider } = createViewModelProviderAPI(
    ViewModelType.ScenariosList,
    "ScenariosListVM"
);
const { useAppSearchVM, AppSearchVMProvider } = createViewModelProviderAPI(ViewModelType.AppSearch, "AppSearchVM");

const { useScenarioDetailsVM, ScenarioDetailsVMProvider } = createViewModelProviderAPI(
    ViewModelType.ScenarioDetails,
    "ScenarioDetailsVM"
);

const { useScenarioEditVM, ScenarioEditVMProvider } = createViewModelProviderAPI(
    ViewModelType.ScenarioEdit,
    "ScenarioEditVM"
);

const { useScenarioVM, ScenarioVMProvider } = createViewModelProviderAPI(ViewModelType.Scenario, "ScenarioVM");

const { useDetectorListVM, DetectorListVMProvider } = createViewModelProviderAPI(
    ViewModelType.DetectorList,
    "DetectorListVM"
);

export {
    useMapControls,
    MapControlsProvider,
    useTVCamerasListVM,
    TVCamerasListVMProvider,
    useScenariosListVM,
    ScenariosListVMProvider,
    useScenarioDetailsVM,
    ScenarioDetailsVMProvider,
    useScenarioEditVM,
    ScenarioEditVMProvider,
    useScenarioVM,
    ScenarioVMProvider,
    useAppSearchVM,
    AppSearchVMProvider,
    useDetectorListVM,
    DetectorListVMProvider,
};
