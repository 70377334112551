import { BaseApi, ValidatedResult } from "api/utils";
import {
    CreateFacilityGovernanceResult,
    StatusParams,
    PhaseParams,
    EnableCooGroupCycleArgs,
    EnableTemporaryCycleArgs,
    ActivateCooGroupCustomCycleArgs,
    ActivateCooGroupCycleArgs,
    LaunchScenariosArgs,
} from "./manage-api.types";
import {
    FacilityGovernance,
    CooGroupGovernance,
    PlannedGovernanceInfo,
    PlannedGovernanceError,
    StateType,
} from "../types";

export class ManageAPI extends BaseApi {
    /** Создает объект для управления СО */
    public createFacilityGovernance(id: string | number): Promise<CreateFacilityGovernanceResult> {
        const url = this.buildFacilityURL(`/${id}/governance`);
        return this.request({ url, method: "POST" });
    }

    /** Создает объект управления ЛК */
    public createCooGroupGovernance(id: string | number): Promise<CooGroupGovernance> {
        const url = this.buildCooGroupURL(`/${id}/governance`);
        return this.request({ url, method: "POST" });
    }

    /** Удаляет объект управления ЛК */
    public removeCooGroupGovernance(id: string | number): Promise<void> {
        const url = this.buildCooGroupURL(`/${id}/governance`);
        return this.request({ url, method: "DELETE", as: "raw" });
    }

    /** Возвращает управление СО */
    public deleteFacilityGovernance(id: string | number): Promise<void> {
        const url = this.buildFacilityURL(`/${id}/governance`);
        return this.request({ url, method: "DELETE", as: "raw" });
    }

    /** Переключение режима светофорного объекта */
    public async postStatus(id: number, body: StatusParams): Promise<FacilityGovernance | void> {
        const url = this.buildUrl(`/facility/${id}/status`);
        const response = await this.request({ url, method: "POST", body: JSON.stringify(body), as: "raw" });
        if (response.status === 200) return response.json();
    }

    /** Переключение фазы светофорного объекта */
    public async postPhase(id: number, body: PhaseParams): Promise<FacilityGovernance | void> {
        const url = this.buildUrl(`/facility/${id}/phase`);
        const response = await this.request({ url, method: "POST", body: JSON.stringify(body), as: "raw" });
        if (response.status === 200) return response.json();
    }

    /** Завершает команду управления СО */
    public releaseFacilityCommand(id: number | string): Promise<FacilityGovernance> {
        const url = this.buildFacilityURL(`/${id}/clean`);
        return this.request({ url, method: "POST" });
    }

    /** Переключение программы СО */
    public async enableFacilityTemporaryCycle(args: EnableTemporaryCycleArgs): Promise<FacilityGovernance | void> {
        const { id, ...prop } = args;
        const url = this.buildFacilityURL(`/${id}/cycle/custom`);
        const response = await this.request({ url, method: "POST", body: JSON.stringify(prop), as: "raw" });
        if (response.status === 200) return response.json();
    }

    public async activateCooGroupCycle(cooGroupId: number, args: ActivateCooGroupCycleArgs) {
        const url = this.buildCooGroupURL(`/${cooGroupId}/cycle`);
        const body = JSON.stringify(args);
        const response = await this.request({ url, body, method: "PUT" });
        return new ValidatedResult<CooGroupGovernance>(response);
    }

    public enableCooGroup(cooGroupId: number, value: boolean) {
        const url = this.buildCooGroupURL(`/${cooGroupId}/enabled`);
        const body = value.toString();
        return this.request({ url, body, method: "PUT", as: "raw" });
    }

    public enableCooGroupCycle(cooGroupId: number, args: EnableCooGroupCycleArgs): Promise<void> {
        const url = this.buildCooGroupURL(`/${cooGroupId}/cycle/${args.cycleId}/enabled`);
        const body = JSON.stringify(args.value);
        return this.request({ url, body, method: "PUT", as: "raw" });
    }

    public async activateCooGroupCustomCycle(cooGroupId: number, args: ActivateCooGroupCustomCycleArgs) {
        const url = this.buildCooGroupURL(`/${cooGroupId}/cycle/custom`);
        const body = JSON.stringify(args);
        const response = await this.request({ url, body, method: "PUT" });
        return new ValidatedResult<CooGroupGovernance>(response);
    }

    public cleanCooGroupGovernance(cooGroupId: number): Promise<CooGroupGovernance> {
        const url = this.buildCooGroupURL(`/${cooGroupId}/clean`);
        return this.request({ url, method: "POST" });
    }

    public prolongCooGroupCycle(cooGroupId: number, duration: number): Promise<CooGroupGovernance> {
        const url = this.buildCooGroupURL(`/${cooGroupId}/governance/prolong`);
        return this.request({ url, method: "PUT", body: String(duration) });
    }

    /** Разрешает для СО локальный адаптив вместо локальнго плана
     * @param facilityId id светафора,
     * @param value true - работа в локальном адаптиве, false - работать в локальном режиме */
    public setLocalAdaptiveModuleEnabled(facilityId: number, value: boolean): Promise<void> {
        const url = this.buildFacilityURL(`/${facilityId}/crt/localadaptive`);
        return this.request({ url, method: "PUT", as: "raw", body: String(value) });
    }

    public async getGovernancePlanned(facilityId: number): Promise<PlannedGovernanceInfo | PlannedGovernanceError> {
        const url = this.buildUrl(`/${facilityId}/governance/planned`);
        const response = await this.request({ url, method: "GET", as: "raw" });
        if (response.status === 200) return response.json();
        else return { error: "Ошибка" };
    }

    public async removePlannedGovernance(facilityId: number, facilityPlannedGovernanceId: number): Promise<void> {
        const url = this.buildUrl(`/${facilityId}/governance/planned/${facilityPlannedGovernanceId}`);
        return await this.request({ url, method: "DELETE", as: "raw" });
    }

    /** Запускает сценарий */
    public launchScenarios(id: number, data: LaunchScenariosArgs): Promise<StateType> {
        const url = this.buildScenarioURL(`/${id}`);
        return this.request({ url, method: "PUT", as: "raw", body: JSON.stringify(data) });
    }

    /** Включает/выключает сценарий */
    public enabledScenario(id: number, enabled: boolean): Promise<void> {
        const url = this.buildScenarioURL(`/${id}/enabled`);
        return this.request({ url, method: "PUT", as: "raw", body: enabled.toString() });
    }

    /** Увеличивает время работы сценария */
    public prolongScenario(id: number, time: number): Promise<void> {
        const url = this.buildScenarioURL(`/${id}/prolong`);
        return this.request({ url, method: "PUT", as: "raw", body: time.toString() });
    }

    /** Завершает выполнение сценария */
    public finishScenario(id: number): Promise<void> {
        const url = this.buildScenarioURL(`/${id}`);
        return this.request({ url, method: "DELETE", as: "raw" });
    }

    public downloadFirmware(id: number): Promise<Blob> {
        const url = this.buildFacilityURL(`/${id}/firmware`);
        return this.request({ url, as: "blob" });
    }

    public updateFirmware(id: number, file: File) {
        const url = this.buildFacilityURL(`/${id}/firmware`);
        return this.sendFile(url, file);
    }

    protected buildUrl(url: string, params?: string) {
        return super.buildUrl(`/manage${url}${params ? `/${params}` : ""}`);
    }

    protected buildCooGroupURL(url: string, params?: string) {
        return this.buildUrl(`/coogroup${url}`, params);
    }

    private buildFacilityURL(url: string, params?: string) {
        return this.buildUrl(`/facility${url}`, params);
    }

    private buildScenarioURL(url: string, params?: string) {
        return this.buildUrl(`/storyplay${url}`, params);
    }
}
