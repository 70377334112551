import { VMModule } from "../types";
import { ScenarioViewModel } from "./scenario-view-model";
import { useGetDeps } from "./use-get-deps";

export { ScenarioViewModel };

export default {
    model: ScenarioViewModel,
    useGetDeps,
} as VMModule<typeof ScenarioViewModel>;
