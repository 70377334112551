import { makeAutoObservable, observable } from "mobx";
import { BooleanProperty } from "lib";
import { MapController, LayerKey } from "map-controller";
import { LayerControl } from "./layer-control";

export enum LayerControlKey {
    TrafficLights,
    CooGroups,
    DtmTraffic,
    KSODD,
    Detectors,
    TVCameras,
    SpeedCameras,
}

const ControlKeyByLayerKey = {
    [LayerKey.TrafficLights]: LayerControlKey.TrafficLights,
    [LayerKey.CooGroups]: LayerControlKey.CooGroups,
    [LayerKey.DtmTraffic]: LayerControlKey.DtmTraffic,
    [LayerKey.KSODD]: LayerControlKey.KSODD,
    [LayerKey.Detectors]: LayerControlKey.Detectors,
    [LayerKey.TVCameras]: LayerControlKey.TVCameras,
    [LayerKey.SpeedCameras]: LayerControlKey.SpeedCameras,
} as Record<LayerKey, LayerControlKey>;

export class MapControlsViewModel {
    private _isReady = new BooleanProperty(false);
    private _layerControls: Record<LayerControlKey, LayerControl> = {} as Record<LayerControlKey, LayerControl>;

    constructor(private _mapController: MapController) {
        makeAutoObservable<MapControlsViewModel, "_mapController">(this, { _mapController: observable.ref });
        this._init();
    }

    public get isReady() {
        return this._isReady.value;
    }

    public getLayerControl(key: LayerControlKey) {
        return this._layerControls[key];
    }

    public onZoomIn = () => {
        this._mapController.zoomIn();
    };

    public onZoomOut = () => {
        this._mapController.zoomOut();
    };

    private _init() {
        this._mapController.on(this._mapController.events.load, this._createControls);
    }

    private _createControls = () => {
        for (const key of this._mapController.getLayerKeys()) {
            const layer = this._mapController.getLayer(key);
            const controlKey = ControlKeyByLayerKey[key];
            this._layerControls[controlKey] = new LayerControl(layer);
        }
        this._isReady.setTruly();
    };
}
