import { CycleType } from "app-domain/traffic-light";
import { CanvasContainer } from "lib/canvas/canvas-container";
import { ThemeProvider } from "shared/globals";
import { VisualizationData } from "./visualizers.types";

export type VisualizerProps = {
    containerEl: HTMLElement;
    areDirectionsVisible?: boolean;
    cycleType?: CycleType;
};

export abstract class Visualizer {
    public data?: VisualizationData;
    protected container: CanvasContainer;
    protected cycleType?: CycleType;
    protected themeProvider = ThemeProvider.getInstance();
    protected leftOffset = 2;
    protected rightOffset = 2;
    protected topOffset = 10;
    protected barHeight = 20;
    protected barGapHorizontal = 2;
    protected timescaleSegmentLineHeight = 10;
    protected directionsGap = 4;
    protected directionsTopOffset = 8;
    protected globalFontSize = 12;
    private _areDirectionVisible: boolean;
    private resizeObserver: ResizeObserver | null = null;

    constructor(props: VisualizerProps) {
        this._areDirectionVisible = props.areDirectionsVisible ?? false;
        this.container = new CanvasContainer(props.containerEl, this._visualizeData.bind(this));
        this.resizeObserver = new ResizeObserver(this.handleResize);
        this.resizeObserver.observe(props.containerEl);
        this.container.init();
        this.cycleType = props.cycleType;
    }

    public get areDirectionsVisible() {
        return this._areDirectionVisible;
    }

    public set areDirectionsVisible(value: boolean) {
        this._areDirectionVisible = value;
        this.visualize();
    }

    public visualize(): void {
        this.updateHeight();
        this._visualizeData();
    }

    public destroy() {
        this.container.destroy();
        // @ts-ignore
        this.container = null;
        this.resizeObserver?.disconnect();
        this.resizeObserver = null;
    }

    protected get ctx() {
        return this.container.ctx;
    }

    protected get theme() {
        return this.themeProvider.theme;
    }

    protected get typography() {
        return this.theme.typography;
    }

    protected get colors() {
        return this.theme.colors;
    }

    protected get textFont() {
        return this.typography.h6;
    }

    protected get gridProps() {
        return {
            lineWidth: 0.5,
        };
    }

    protected getContainerWidth() {
        return this.container.view.offsetWidth - (this.leftOffset + this.rightOffset);
    }

    protected abstract getViewHeight(): number;

    protected abstract _visualize(data: VisualizationData): void;

    private _visualizeData = () => {
        if (!this.data) return;
        this.ctx.clearRect(0, 0, this.container.view.width, this.container.view.height);
        this.ctx.save();
        this._visualize(this.data);
        this.ctx.restore();
    };

    private updateHeight() {
        const height = this.getViewHeight();
        this.container.updateViewOffsetSizes({ height });
        this._visualizeData();
    }

    private handleResize = ([entry]: ResizeObserverEntry[]) => {
        const { contentRect } = entry;
        this.container.updateViewOffsetSizes({ width: contentRect.width });
        this._visualizeData();
    };
}
