import { VMModule } from "../types";
import { ScenarioDetailsViewModel } from "./scenario-details-view-model";
import { useGetDeps } from "./use-get-deps";

export { ScenarioDetailsViewModel };

export default {
    model: ScenarioDetailsViewModel,
    useGetDeps,
} as VMModule<typeof ScenarioDetailsViewModel>;
